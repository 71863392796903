import React, { useEffect } from "react";
import { Form, Input, Select, Skeleton } from "antd";
import {
  useGetAreaByIdQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaByIdQuery,
  useGetZoneDropdownQuery,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";

const { Option } = Select;

interface ViewSubAreaProps {
  onClose?: () => void;
  id: string;
}

const ViewSubArea: React.FC<ViewSubAreaProps> = ({ onClose, id }) => {
  const { data: zoneList, isLoading: zoneListIsLoading } =
    useGetZoneDropdownQuery();
  const {
    data: area,
    error: areaError,
    isLoading: areaIsLoading,
    refetch: refetchAreas,
  } = useGetAreaDropdownQuery({});
  const {
    data: subAreasById,
    error: subAreasByIdError,
    isLoading: subAreasByIdIsLoading,
    refetch: refetchSubAreas,
  } = useGetSubAreaByIdQuery(id);
  const [form] = Form.useForm();

  useEffect(() => {
    if (subAreasById) {
      form.setFieldsValue({
        name: subAreasById?.data.name,
        zone: subAreasById?.data.areaId,
      });
    }

    return () => form.resetFields();
  }, [subAreasById, form]);

  return (
    <>
      {areaIsLoading || subAreasByIdIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            error={areaError || subAreasByIdError}
            isLoading={areaIsLoading || subAreasByIdIsLoading}
          >
            <h1>Name</h1>
            <Input
              value={subAreasById?.data.name}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Area</h1>
            <Select
              showSearch
              optionFilterProp="children"
              value={subAreasById?.data.areaId}
              placeholder="Select zone "
              loading={zoneListIsLoading}
              disabled={true}
              className="rounded-md width-100"
            >
              {area?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </AppFormWrapper>
        </>
      )}

    </>
  );
};

export default ViewSubArea;
