import React, { useMemo } from "react";
import { Alert, Flex, Input, Skeleton } from "antd";
import { Tag } from "antd";
import { useGetChallanByIdQuery } from "@services/api/";
interface ViewChallanProps {
  onClose?: () => void;
  id: string;
}

const ViewChallan: React.FC<ViewChallanProps> = ({ id }) => {
  const {
    data: challan,
    error: challanByIdError,
    isLoading: challanByIdIsLoading,
  } = useGetChallanByIdQuery(parseInt(id));

  return (
    <>
      {challanByIdIsLoading ? (
        <Skeleton active />
      ) : (challanByIdError ?
        <Alert message={(challanByIdError as any)?.data.message} type="error" className="mb-4" /> : <>
          {
            challan && Object.keys(challan?.data).map((key) =>
            (<Flex>
              <p style={{fontWeight:'bold'}}>{`${key}: `}</p>
              <p>{(challan as any).data[key]?(challan as any).data[key]:''}</p>
            </Flex>)
            )
          }
        </>
      )}
    </>
  );
};

export default ViewChallan;
