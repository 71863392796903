import { Rule } from "antd/es/form";

interface UserValidator {
    name: Rule[];
    cnic:Rule[];
    phone:Rule[];
    email: Rule[];
    picture: Rule[];
    designationId: Rule[];
    departmentId: Rule[];
    divisionId: Rule[];
    zoneId: Rule[];
    subZoneId:Rule[];
    supervisorId: Rule[];
    roleId: Rule[];
    isInspector: Rule[];
    areasIds:Rule[];
    societyIds:Rule[];
    subAreasIds:Rule[];
    password: Rule[];
    confirmPassword: Rule[];
}

export const userValidator: UserValidator = {
    name: [
    { required: true, message: "Please input your name" },
    {pattern:/^[A-Za-z ]*$/,message:"Please input alphabetic characters only"}
  ],
  cnic: [
    { required: true, message: "Please input your CNIC" },
    {pattern:/^\d+$/,message:"Please input only numeric characters without dashes"}
  ],
  phone: [
    { required: true, message: "Please input your phone number" },
    {pattern:/^\d{11}$/,message:"Please input 11 digit phone number"}
  ],
  email: [
    { required: true, message: "Please input email address" },
    { type: "email", message: "Please input valid email address" },

  ],
  picture: [
    { required: false, message: "Please select a picture" },
  ],
  designationId: [
    { required: true, message: "Please select designation" },
  ],
  departmentId: [
    { required: true, message: "Please select department" },
  ],
  divisionId: [
    { required: true, message: "Please select division" },
  ],
  zoneId: [
    { required: false, message: "Please select zone" },
  ],
  subZoneId: [
    { required: false, message: "Please select sub zone" },
  ],
  areasIds: [
    { required: false, message: "Please select areas" },
  ],
  societyIds: [
    { required: false, message: "Please select areas" },
  ],
  subAreasIds: [
    { required: false, message: "Please select sub areas" },
  ],
  supervisorId: [
    { required: false, message: "Please select supervisor" },
  ],
  roleId: [
    { required: true, message: "Please select role" },
  ],
  isInspector: [
    { required: false, message: "Please select the person to assign the task" },
  ],
  password: [
    { required: true, message: "Please input your password"  },
  ],
  confirmPassword: [
    { required: true, message: "Please confirm your password"  },
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (value || getFieldValue("password") === value) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error("The two passwords that you entered do not match")
            );
        },
    }),
  ],
};
