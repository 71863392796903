import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  // Divider,
  // Skeleton,
  notification,
  // CheckboxProps,
} from "antd";
import {
  useEditPropertyMutation,
  useGetPermissionsQuery,
  useGetPropertyTypeByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { propertyValidator } from "@validators/propertyValidator";
import NotificationHandler from "@components/notificationHandler";

// const CheckboxGroup = Checkbox.Group;

interface EditPropertyTypeProps {
  onClose?: () => void;
  id: string;
}

const EditPropertyType: React.FC<EditPropertyTypeProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editProperty, { isLoading, error }] = useEditPropertyMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: property,
    error: propertyTypeError,
    isLoading: propertyTypeIsLoading,
  } = useGetPropertyTypeByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      // let permissionIds: string[] = [];

      // console.log("Role Created successful:", values);
      // for (const value of Object.values(values)) {
      //   if (value && Array.isArray(value)) {
      //     permissionIds = permissionIds.concat(value);
      //   }
      // }
      const payload = {
        name: values.name,
        // permissionIds,
        id,
      }
      const result = await editProperty({id,body:payload}).unwrap();
      openNotificationWithIcon("success", result?.message||"Property Type updated successfully!");
      onClose && onClose();
    } catch (err: any) {
      console.error("Property Type update failed:", err);
      
        // openNotificationWithIcon("error", err?.data?.message);
      
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const [checkedList, setCheckedList] = useState<any[]>([
  //   permissions?.data[0].permissions[0].id,
  // ]);

  // const onChange = (list: string[]) => {
  //   setCheckedList(list);
  // };

  // const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
  //   setCheckedList(
  //     e.target.checked
  //       ? (permissions as any)?.data.map((val: any) => val.id)
  //       : []
  //   );
  // };

  const [form] = Form.useForm();

  useEffect(() => {
    // if (permissions?.data && role?.data.permissionSlugs) {
    //   const moduleWisePermission: any = {};
    //   permissions?.data.forEach((module) => {
    //     const selectedPermission: any = [];
    //     role?.data?.permissionSlugs?.forEach((slug) => {
    //       const foundP = module.permissions.find(
    //         (val: any) => val.slug == slug
    //       );
    //       if (foundP) selectedPermission.push(foundP.id);
    //     });
    //     moduleWisePermission[module.module] = selectedPermission;
    //   });

    //   if (role) {
    //     form.setFieldsValue({
    //       name: role?.data.name,
    //       ...moduleWisePermission,
    //     });
    //   }
    // }

    if (property) {
      form.setFieldsValue({
        name: property?.data.name,
      });
    }

    return () => form.resetFields();
  }, [property, form, permissions]);

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || propertyTypeError}
      isLoading={isLoading || permissionsIsLoading || propertyTypeIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={propertyValidator.name}
      >
        <Input maxLength={30} className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" disabled={isLoading}/>
      </Form.Item>

      {/* {permissionsIsLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Divider />
                    <div className="flex flex-col space-y-2">
                        {
                            permissions?.data.map((val) => (

                                <Form.Item name={val.module} label={val.module}>
                                    <CheckboxGroup
                                        // options={permissions?.data.map((val) => val.name)}
                                        options={(val.permissions as any)?.map((val: any) => ({
                                            label: val.name,
                                            value: val.id,
                                        }))}
                                        value={(val?.permissions as any)?.map((val: any) => val.id)}
                                        onChange={onChange}
                                    />
                                </Form.Item>

                            ))
                        }

                    </div>
                </>
            )} */}
    </AppFormWrapper>
  );
};

export default EditPropertyType;
