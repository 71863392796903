import React, { useState, ReactNode, useEffect } from "react";
import {
  //   useDeleteViolationMutation,
  //   useGetViolationQuery,
  //   useChangeStatusViolationMutation,
  useGetSocietyViolationQuery,
} from "@services/api/index";
import styles from "./violationList.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
// import { Switch, Modal, Alert, notification } from "antd";
// import { DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface Violation {
  id: string;
  name: string;
  status: string;
}

interface SocietyViolationListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
}

const SocietyViolationList: React.FC<SocietyViolationListProps> = ({
  editComp,
  viewComp,
}) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const {
    data: societyViolation,
    error,
    isLoading,
    // societyViolationError,
    // societyViolationIsLoading,
  } = useGetSocietyViolationQuery(pagination);
  //   const {
  //     data: violation,
  //     error,
  //     isLoading,
  //   } = useGetViolationQuery(pagination);
  //   const [
  //     deleteViolation,
  //     { isLoading: deleteViolationIsloading, error: deleteViolationError },
  //   ] = useDeleteViolationMutation();

  //   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  //   const [recordToDelete, setRecordToDelete] = useState<Violation | null>(null);

  //   const [
  //     changeStatusViolation,
  //     {
  //       isLoading: changeViolationStatusIsloading,
  //       error: changeViolationStatusError,
  //     },
  //   ] = useChangeStatusViolationMutation();

  //   const handleToggleChange = async (checked: boolean, record: Violation) => {
  //     // Implement toggle functionality
  //     const result = await changeStatusViolation(record.id).unwrap();
  //   };

  //   const showDeleteConfirm = (record: Violation) => {
  //     setRecordToDelete(record);
  //     setIsDeleteModalOpen(true); // Open the delete confirmation modal
  //   };

  //   const handleDelete = async () => {
  //     if (recordToDelete) {
  //       const result = await deleteViolation(recordToDelete.id).unwrap();
  //       notification.success({
  //         message: "Violation Deleted successful:",
  //         placement: "topRight",
  //         duration: 3, // Duration in seconds
  //       });
  //       // Implement actual delete functionality here
  //     }
  //     setIsDeleteModalOpen(false);
  //     setRecordToDelete(null);
  //   };

  //   const handleCancel = () => {
  //     setIsDeleteModalOpen(false);
  //     setRecordToDelete(null);
  //   };

  //   useEffect(() => {
  //     if (changeViolationStatusError) {
  //       notification.error({
  //         message: "violation Updated failed:",
  //         placement: "topRight",
  //         duration: 3, // Duration in seconds
  //       });
  //     }
  //   }, [changeViolationStatusError]);

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    // {
    //   title: "Fine",
    //   dataIndex: "fine",
    //   key: "fine",
    //   className: styles.tableCell,
    // },
    // {
    //   title: "Status",
    //   key: "active",
    //   render: (text: any, record: Violation) => (
    //     <Switch
    //       disabled={changeViolationStatusIsloading}
    //       checked={record.status == "active"}
    //       onChange={(checked) => handleToggleChange(checked, record)}
    //     />
    //   ),
    //   className: styles.tableCell,
    // },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text: any, record: Violation) => (
    //     <div className="flex gap-4">
    //       {/* {hasPermission(permissions.VIEW_VIOLATION_TYPE) &&
    //         viewComp &&
    //         viewComp(record.id)} */}
    //       {/* {hasPermission(permissions.EDIT_VIOLATION_MANAGEMENT) &&
    //         editComp &&
    //         editComp(record.id)} */}
    //       {/*  {hasPermission(permissions.DELETE_VIOLATION_MANAGEMENT) && (
    //         <DeleteOutlined
    //           className={styles.icon}
    //           onClick={() => showDeleteConfirm(record)}
    //         />
    //       )} */}
    //     </div>
    //   ),
    //   className: styles.tableCell,
    // },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={societyViolation}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
      {/* Delete Confirmation Modal */}
      {/* <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        loading={deleteViolationIsloading}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
        {error && (
          <Alert
            message={(deleteViolationError as any)?.data?.message}
            type="error"
            className="mb-4"
          />
        )}
      </Modal> */}
    </div>
  );
};

export default SocietyViolationList;
