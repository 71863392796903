import React from "react";
import { RouteObject } from "react-router-dom";
import societyManagementPaths from "@paths/societyManagementPaths";
import SocietyManagement from "@pages/societyManagement";
import AddSociety from "@components/Society/addSociety";

const societyManagementMappings: RouteObject[] = [
  {
    path: societyManagementPaths.root,
    element: <SocietyManagement />,
  },
  {
    path: societyManagementPaths.addSociety,
    element: <AddSociety />,
  },
];

export default societyManagementMappings;
