import React from "react";
import { RouteObject } from "react-router-dom";
// import violationTypeManagementPaths from "@paths/violationType";
// import AddViolation from "@components/Violation/addViolation";
// import ViolationType from "@pages/violationTypeManagement";
import SocietyViolationType from "@pages/societyViolationManagement";
import societyViolationManagementPaths from "@paths/societyViolationPath";

const societyViolationiolationMappings: RouteObject[] = [
  {
    path: societyViolationManagementPaths.root,
    element: <SocietyViolationType />,
  },
  //   {
  //     path: violationTypeManagementPaths.AddViolation,
  //     element: <AddViolation />,
  //   },
];

export default societyViolationiolationMappings;
