import React from "react";
import { Form, Input, Button, Alert, Select, Upload, notification } from "antd";
import {
  useGetDivisionQuery,
  useGetZoneDropdownQuery,
  useGetSupervisorQuery,
  useCreateZoneMutation,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import { zoneValidator } from "@validators/zoneValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface AddZoneProps {
  onClose?: () => void;
}

const AddZone: React.FC<AddZoneProps> = ({ onClose }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [createZone, { isLoading, error }] = useCreateZoneMutation();
  const {
    data: division,
    error: divisionError,
    isLoading: divisionIsLoading,
  } = useGetDivisionQuery();
  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
  } = useGetZoneDropdownQuery();
  const {
    data: supervisor,
    error: supervisorError,
    isLoading: supervisorIsLoading,
  } = useGetSupervisorQuery({ hierarchy: "" });

  const onFinish = async (values: any) => {
    try {
      console.log("values: ", values);
      delete values.confirmPassword;

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("zoneID", values.zoneID);
      formData.append("area", values.area);
      values?.supervisorId &&
        formData.append("supervisorId", values.supervisorId);

      const payload =
      {
        name: values.name,
        supervisorId: values.supervisorId,
        zoneID: values.zoneID,
        area: values.area,
      }
      const result = await createZone(payload).unwrap();
      console.log("Zone Created successful:", result);
      openNotificationWithIcon("success", result?.message || "Zone created successfully!");
      onClose && onClose();
    } catch (err: any) {
      console.error("Zone creation failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AppFormWrapper
      error={error}
      initialValues={{ remember: true }}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item
        label="Zone Name"
        name="name"
        rules={zoneValidator.name}
      >
        <Input className="rounded-md p-1"        placeholder="Enter name"
 disabled={isLoading} />
      </Form.Item>

      <Form.Item
        label="Zone Supervisor"
        name="supervisorId"
        rules={zoneValidator.supervisorId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select Zone Supervisor"
          loading={supervisorIsLoading}
          disabled={supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {supervisor?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Zone ID"
        name="zoneID"
        rules={zoneValidator.zoneID}
      >
        <Input className="rounded-md p-1"        placeholder="Enter name"
 disabled={isLoading} />
      </Form.Item>

      <Form.Item
        label="Zone Area/Sector"
        name="area"
        rules={zoneValidator.area}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select division"
          loading={supervisorIsLoading}
          disabled={supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {division?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default AddZone;
