import React from "react";
import { RouteObject } from "react-router-dom";
import AddZone from "@components/Zones/addZone";
import ZoneManagement from "@pages/zoneManagement";
import zoneManagementPaths from "@paths/zoneManagement";

const zoneManagementMappings: RouteObject[] = [
  {
    path: zoneManagementPaths.root,
    element: <ZoneManagement />,
  },
  {
    path: zoneManagementPaths.addRole,
    element: <AddZone />,
  },
];

export default zoneManagementMappings;
