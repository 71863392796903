import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  Society,
  SocietySurvey,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const societySurveyApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getSocietySurvey: builder.query<
    ResponseListPaginate<SocietySurvey>,
    PaginationPayload
  >({
    query: ({ page, limit, searchText }: any) => {
      const params: PaginationPayload = { page, limit };

      if (searchText) {
        params.searchText = searchText;
      }
      return {
        url: apiRoutes.getSocietySurvey,
        params,
      };

      //   url: apiRoutes.getSocietySurvey,
      //   params: { page, limit },
    },
    providesTags: [apiTags.societySurvey],
  }),
  //   createSociety: builder.mutation<Response<Society>, Society>({
  //     query: (body) => ({
  //       url: apiRoutes.createSociety,
  //       method: "POST",
  //       body,
  //     }),
  //     invalidatesTags: [apiTags.society],
  //   }),
  //   getSocietyDropdown: builder.query<ResponseList<Society>, void>({
  //     query: () => apiRoutes.getSocietyDropdown,
  //   }),

  //   editSociety: builder.mutation<
  //     Response<Society>,
  //     { id: string; body: Society }
  //   >({
  //     query: ({ id, body }) => ({
  //       url: apiRoutes.editSociety(id),
  //       method: "PATCH",
  //       body,
  //     }),
  //     invalidatesTags: [apiTags.society],
  //   }),
  //   deleteSociety: builder.mutation({
  //     query: (id: string) => ({
  //       url: apiRoutes.deleteSociety(id),
  //       method: "DELETE",
  //     }),
  //     invalidatesTags: [apiTags.society],
  //   }),

  getSocietySurveyById: builder.query<Response<SocietySurvey>, string>({
    query: (id: string) => apiRoutes.getSocietySurveyById(id),
    providesTags: [apiTags.societySurvey],
  }),
});
