import React, { useState, ReactNode } from "react";
import { Image, Switch, Modal } from "antd";
import styles from "./UserList.module.css";
import List from "@components/list";
import { PaginationPayload } from "types";
import { DEFAULT_PAGE, IMG_BASE_URL, PAGE_LIMIT } from "@constants/index";
import { useChangeStatusUserMutation, useGetUsersQuery } from "@services/api/index";
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface User {
  id: string;
  name: string;
  CNIC: string;
  phone: string;
  status: string;
  isInspector: boolean;
  picture: string;
  email: string;
  designation: string;
  department: string;
  division: string;
  zone: string;
  supervisor: string;
  role: string;
  areasIds: string[];
}

interface UserListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  searchValue?: string
}

const UserList: React.FC<UserListProps> = ({ editComp, viewComp, searchValue }) => {
  const [pagination, setPagination] = useState<PaginationPayload>({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const { data: users, error, isLoading } = useGetUsersQuery({
    ...pagination, searchText: searchValue
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<User | null>(null);
  const [
    changeStatusUser,
    {
      isLoading: changeStatusUserIsloading,
      error: changeStatusUserError,
    },
  ] = useChangeStatusUserMutation();

  const handleToggleChange = async (checked: boolean, record: User) => {
    // Implement toggle functionality
    console.log(`Toggled ${record.name} to ${checked ? "Active" : "Inactive"}`);
    const result = await changeStatusUser(record.id).unwrap();
  };

  const showDeleteConfirm = (record: User) => {
    setRecordToDelete(record);
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const handleDelete = () => {
    if (recordToDelete) {
      console.log("Delete record:", recordToDelete);
      // Implement actual delete functionality here
    }
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const { hasPermission } = usePermissions();

  const columns = [
    // {
    //   title: "Picture",
    //   dataIndex: "picture",
    //   key: "picture",
    //   render: (picture: string) => (
    //     <Image
    //       src={`${IMG_BASE_URL}${picture}`}
    //       width={50}
    //       height={50}
    //       className="rounded-[50%]"
    //     />
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: styles.tableCell,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      className: styles.tableCell,
    },
    {
      title: "Directorate",
      dataIndex: "department",
      key: "department",
      className: styles.tableCell,
    },
    // {
    //   title: "Division",
    //   dataIndex: "division",
    //   key: "division",
    //   className: styles.tableCell,
    // },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      className: styles.tableCell,
    },
    {
      title: "Assistant Director/Deputy Director",
      dataIndex: "supervisor",
      key: "supervisor",
      className: styles.tableCell,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: styles.tableCell,
    },
    // {
    //   title: "Is Inspector",
    //   key: "isInspector",
    //   render: (record: User) => (
    //     record.isInspector ? (
    //       <CheckCircleOutlined style={{ display:"flex" , justifyContent: "center" , color: 'green', fontSize: '20px' }} />
    //     ) : (
    //       <CloseCircleOutlined style={{ display:"flex" , justifyContent: "center" ,  color: 'red', fontSize: '20px' }} />
    //     )
    //   ),
    //   className: styles.tableCell,
    // },
    {
      title: "Status",
      key: "active",
      render: (record: User) => (
        <Switch
          disabled={changeStatusUserIsloading}
          checked={record?.status == "active"}
          onChange={(checked) => handleToggleChange(checked, record)}
        />
      ),
      className: styles.tableCell,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: User) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_USER) && viewComp && viewComp(record.id)}
          {hasPermission(permissions.EDIT_USER) && editComp && editComp(record.id)}
          {/* {hasPermission(permissions.DELETE_USER) && <DeleteOutlined
            className={styles.icon}
            onClick={() => showDeleteConfirm(record)}
          />} */}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={users}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
    </div>
  );
};

export default UserList;
