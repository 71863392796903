import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { permissions, PermissionKeys, PermissionSlugs } from "permissions"; // Adjust the import path to where your permissions file is located

const usePermissions = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const hasPermission = (permission: string) => {
    return user?.role?.permissionSlugs?.includes(permission) || false;
  };

  return {
    hasPermission,
  };
};

export default usePermissions;
