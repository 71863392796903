import React, { useMemo } from "react";
import { Input, Skeleton } from "antd";
import { Tag } from "antd";
import { useGetSubZoneByIdQuery } from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
interface ViewSubZoneProps {
  onClose?: () => void;
  id: string;
}

const ViewSubZone: React.FC<ViewSubZoneProps> = ({ onClose, id }) => {
  const {
    data: subzoneById,
    error: subzoneByIdError,
    isLoading: subzoneByIdIsLoading,
  } = useGetSubZoneByIdQuery(id);

  const tags = useMemo(() => {
    let subzoneAreas = subzoneById?.data?.area;
    if (subzoneAreas) return subzoneAreas.split(",");
  }, [subzoneById]);

  return (
    <>
      {subzoneByIdIsLoading ? (
        <Skeleton active />
      ) : (

        <>
          <AppFormWrapper
            onClose={onClose}
            error={subzoneByIdError}
            isLoading={subzoneByIdIsLoading}
          >
            <h1>Zone Name</h1>
            <Input
              value={subzoneById?.data.zone}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Sub Zone Name</h1>
            <Input
              value={subzoneById?.data.name}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Area/Sector</h1>
            {tags && tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
          </AppFormWrapper>
        </>)}

    </>
  );
};

export default ViewSubZone;
