import React from "react";
import AddTask from "@components/Tasks/addTask";
import AppPageWrapper from "@components/appPageWrapper";
import TaskList from "@components/Tasks/taskList/index";
import EditTask from "@components/Tasks/editTask/index";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import ViewTask from "@components/Tasks/viewTask";

const TaskManagement: React.FC = () => {
    const { hasPermission } = usePermissions();
    return hasPermission(permissions.VIEW_TASK_MANAGEMENT) ? (
        <AppPageWrapper
            // addComp={(isLoaded: boolean) => hasPermission(permissions.ADD_TASK) ? <AddTask isLoaded={isLoaded} /> : null}
            listComp={<TaskList />}
            editComp={(id: string, isLoaded: boolean) => <EditTask id={id} isLoaded={isLoaded} />}
            viewComp={(id: string, isLoaded: boolean) => <ViewTask id={id} isLoaded={isLoaded}/>}
            moduleName="Survey"
            pagetTitle="Survey Management"
            width={"1600px"}
        />
    ) : null;
};

export default TaskManagement;
