import React, { ReactNode, useState } from 'react';
import type { DrawerProps, } from 'antd';
import { Drawer } from 'antd';
interface AppDrawerProps {
  children: ReactNode
  title: string
  toggler: (fun: () => void) => ReactNode
  width?: number | string; 
}

interface ChildWithOnClose {
  onClose?: () => void;
}

const AppDrawer: React.FC<AppDrawerProps> = ({ children, title, toggler, width }: AppDrawerProps) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const renderChildrenWithProps = () => {
    return React.Children.map(children, child => {
      if (React.isValidElement<ChildWithOnClose>(child)) {
        return React.cloneElement(child, { onClose });
      }
      return child;
    });
  };

  return (
    <>
      {toggler(showDrawer)}
      <Drawer
        title={title}
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
        width={width || 600}
        style={{ padding: 0 }}
      >
        {renderChildrenWithProps()}
      </Drawer>

    </>
  );
};

export default AppDrawer;