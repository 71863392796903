import React from "react";
import { RouteObject } from "react-router-dom";
import AddRole from "@components/Roles/addRole";
import RoleManagement from "@pages/rolesManagement";
import roleManagementPaths from "@paths/roleManagementPaths";

const roleManagementMappings: RouteObject[] = [
  {
    path: roleManagementPaths.root,
    element: <RoleManagement />,
  },
  {
    path: roleManagementPaths.addRole,
    element: <AddRole />,
  },
];

export default roleManagementMappings;
