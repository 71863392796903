import React from "react";
import { RouteObject } from "react-router-dom";
import violationTypeManagementPaths from "@paths/violationType";
import AddViolation from "@components/Violation/addViolation";
import ViolationType from "@pages/violationTypeManagement";

const violationTypeManagementMappings: RouteObject[] = [
  {
    path: violationTypeManagementPaths.root,
    element: <ViolationType />,
  },
  {
    path: violationTypeManagementPaths.AddViolation,
    element: <AddViolation />,
  },
];

export default violationTypeManagementMappings;
