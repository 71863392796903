import React from "react";
import { RouteObject } from "react-router-dom";
import surveyManagementPaths from "@paths/surveyPaths";
import SurveyManagement from "@pages/surveyManagement";

const surveyManagementMapping: RouteObject[] = [
    {
        path: surveyManagementPaths.root,
        element: <SurveyManagement />,
    },
];

export default surveyManagementMapping;
