import React from "react";
import { RouteObject } from "react-router-dom";
import subZoneManagementPaths from "@paths/subZonemanagementPaths";
import SubZoneManagement from "@pages/subZoneManagement";

const subZoneManagementMapping: RouteObject[] = [
  {
    path: subZoneManagementPaths.root,
    element: <SubZoneManagement />,
  },
];

export default subZoneManagementMapping;
