import { Rule } from "antd/es/form";

interface ZoneValidator {
    name: Rule[];
    supervisorId: Rule[];
    zoneID: Rule[];
    area: Rule[];
}

export const zoneValidator: ZoneValidator = {
    name: [
    { required: true, message: "Please input zone name" },
  ],
  supervisorId: [
    { required: true, message: "Please select supervisor" },
  ],
  zoneID: [
    { required: true, message: "Please add zone" },
  ],
  area: [
    { required: true, message: "Please select area" },
  ],
};
