import { Rule } from "antd/es/form";

interface PropertyValidator {
    name: Rule[];
}

export const propertyValidator: PropertyValidator = {
    name: [
    { required: true, message: "Please input property name" },
  ],
};
