import React from 'react';
import { RouteObject } from 'react-router-dom';
import dashboardPaths from '@paths/dashboardPaths';
import Dashboard from '@pages/dashboard';

const dashboardMappings: RouteObject[] = [
  {
    path: dashboardPaths.root,
    element: <Dashboard />,
  }
];

export default dashboardMappings;
