import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Divider,
  Skeleton,
  notification,
  CheckboxProps,
  InputNumber,
} from "antd";
import {
  useEditViolationMutation,
  useGetPermissionsQuery,
  useGetRoleByIdQuery,
  useGetViolationByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { violationValidator } from "@validators/violationValidator";
import NotificationHandler from "@components/notificationHandler";

const CheckboxGroup = Checkbox.Group;

interface EditViolationTypeProps {
  onClose?: () => void;
  id: string;
}

const EditViolationType: React.FC<EditViolationTypeProps> = ({
  onClose,
  id,
}) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editViolation, { isLoading, error }] = useEditViolationMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  //   const {
  //     data: role,
  //     error: roleError,
  //     isLoading: roleIsLoading,
  //   } = useGetRoleByIdQuery(id);
  const {
    data: violation,
    error: violationError,
    isLoading: violationIsLoading,
  } = useGetViolationByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      //   console.log("values: ", values);
      //   delete values.confirmPassword;

      const result = await editViolation({
        name: values.name,
        fine: values.fine,
        id,
      }).unwrap();
      //   console.log("Violation Updated successful:", result);
      openNotificationWithIcon("success", "Violation updated successfully!");
      onClose && onClose();
    } catch (err: any) {
      console.error("Violation update failed:", err);
     
        // openNotificationWithIcon("error", err?.data?.message);
      
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  //   const [checkedList, setCheckedList] = useState<any[]>([
  //     permissions?.data[0].permissions[0].id,
  //   ]);

  //   const onChange = (list: string[]) => {
  //     setCheckedList(list);
  //   };

  //   const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
  //     setCheckedList(
  //       e.target.checked
  //         ? (permissions as any)?.data.map((val: any) => val.id)
  //         : []
  //     );
  //   };

  const [form] = Form.useForm();

  useEffect(() => {
    if (violation) {
      form.setFieldsValue({
        name: violation?.data.name,
        fine: violation?.data.fine,
      });
    }

    return () => form.resetFields();
  }, [violation, form, permissions]);

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || violationError}
      isLoading={isLoading || permissionsIsLoading || violationIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={violationValidator.name}
      >
        <Input className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" disabled={isLoading}/>
      </Form.Item>{" "}
      {/* <Form.Item
        label="Fine"
        name="fine"
        rules={violationValidator.fine}
      >
        <InputNumber className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" />
      </Form.Item> */}
    </AppFormWrapper>
  );
};

export default EditViolationType;
