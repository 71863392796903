import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import appPaths from "@paths/appPaths";
import AppLayout from "@components/appLayout/index";
import PrivateRoute from "@components/privateRouter.tsx";
import userManagementMappings from "./userManagementMappings";
import roleManagementMappings from "./roleManagementMapping";
import dashboardMappings from "./dashboardMappings";
import zoneManagementMappings from "./zoneManagementMapping";
import taskManagementMappings from "./taskManagementMapping";
import propertyTypeManagementMappings from "./propertyTypeMapping";
import violationTypeManagementMappings from "./violationTypeMapping";
import areaTypeManagementMappings from "./areaManagementMapping";
import challanTypeManagementMappings from "./challanManagementMapping";
import surveyManagementMapping from "./surveyManagementMapping";
import subAreaManagementMapping from "./subAreaManagementMapping";
import subZoneManagementMapping from "./subZoneManagementMapping";
import societySurveyMapping from "./societySurveyMapping";
import societyManagementMappings from "./societyManagementMapping";
import societyViolationiolationMappings from "./societyViolationMapping";

const appMappings: RouteObject[] = [
  {
    element: <PrivateRoute component={AppLayout} />,
    children: [
      {
        path: appPaths.root,
        element: <Navigate to={appPaths.dashboard} replace />,
      },
      ...dashboardMappings,
      ...userManagementMappings,
      ...roleManagementMappings,
      ...zoneManagementMappings,
      ...taskManagementMappings,
      ...propertyTypeManagementMappings,
      ...violationTypeManagementMappings,
      ...areaTypeManagementMappings,
      ...challanTypeManagementMappings,
      ...surveyManagementMapping,
      ...subAreaManagementMapping,
      ...subZoneManagementMapping,
      ...societySurveyMapping,
      ...societyManagementMappings,
      ...societyViolationiolationMappings,
    ],
  },
];

export default appMappings;
