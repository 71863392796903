import { Rule } from "antd/es/form";

interface loginValidationRules {
  email: Rule[];
  password: Rule[];
  recaptcha: Rule[];
  otpCode: Rule[];
}

export const loginValidationRules: loginValidationRules = {
  email: [
    { required: true, message: "Please input your email or phone or CNIC." },
  ],
  password: [
    { required: true, message: "Please input your password." },
  ],
  otpCode: [
    { required: true, message: "Please enter OTP sent to your email address." },
  ],
  recaptcha: [
    { validator: (_: any, value: string | null) => {
        if (!value) {
          return Promise.reject(new Error("Please complete the verification step to continue."));
        }
        return Promise.resolve();
      }
    },
  ],
};
