import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ResponseList, Inspector, InspectorLocations } from "types";
import apiRoutes from "../apiRoutes";
import { useGetInspectorsQuery } from ".";
import apiTags from "@services/apiTags";

export const inspectorApi = (builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, string, "api">) => ({
    getInspectors: builder.query<ResponseList<Inspector>, void>({
        query: () => apiRoutes.getInspectors,
    }),
    GetInspectorsLocation: builder.query<ResponseList<InspectorLocations>, void>({
        query: () => apiRoutes.getInspectorLocation,
    }),
});