import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Skeleton, Alert } from "antd";
import {
  useGetPermissionsQuery,
  useGetViolationByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";

interface ViewPropertyProps {
  onClose?: () => void;
  id: string;
}

const ViewViolationType: React.FC<ViewPropertyProps> = ({ onClose,id }) => {
  const {
    data: violation,
    error: violationError,
    isLoading: violationIsLoading,
  } = useGetViolationByIdQuery(id);

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  return (
    <div className="max-w-3xl -mx-4 px-4  bg-white rounded-lg overflow-y-auto lg:max-h-[calc(100vh-180px)]">
      {permissionsIsLoading || violationIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
          onClose={onClose}
          error={violationError||permissionsError}
          isLoading={violationIsLoading || permissionsIsLoading}
          >
            <h1>Name</h1>
            <Input
              disabled
              value={violation?.data.name}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </AppFormWrapper>
        </>
      )}
    </div>
  );
};

export default ViewViolationType;
