import React from "react";
import { Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "@services/api";
import ReCAPTCHA from "react-google-recaptcha";
import dashboardPaths from "@paths/dashboardPaths";
import logo from "../../assets/img/logo.svg";
import { loginValidationRules } from "@validators/loginValidator";
import { SITE_KEY } from "@constants/index";
import { useMediaQuery } from "react-responsive";
import NotificationHandler from "@components/notificationHandler";

const ChangePassword: React.FC = () => {
  const [login, { isLoading, error }] = useLoginMutation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(
    null
  );
  const { openNotificationWithIcon } = NotificationHandler();
  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    form.setFieldsValue({ recaptcha: token });
    form.validateFields(["recaptcha"]);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const onFinish = async (values: any) => {
    try {
      if (!recaptchaToken) {
        console.error("Please complete the reCAPTCHA!");
        return;
      }
      const { recaptcha, ...payload } = values;
      const result = await login({ ...payload }).unwrap();
      openNotificationWithIcon("success", "Logged in successfully!");
      // const result = await login({ ...values }).unwrap();
      console.log("Login successful:", result);
      navigate(dashboardPaths.root); // Navigate to the dashboard upon successful login
    } catch (err: any) {
      openNotificationWithIcon("error", err?.data?.message);
      console.error("Login failed:", err);
    }
  };
  console.log(loginValidationRules, "ll");
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="max-w-[100%] h-screen flex justify-center mx-auto bg-no-repeat bg-center bg-cover bg-[url('/src/assets/img/background.jpg')]">
      <div
        className="md:w-[640px] w-[300px] sm:w-[550px] h-[500px] sm:h-[500px] md:h-[600px] p-8 relative bg-white container border rounded-3xl m-auto text-center flex flex-col justify-center"
        style={{
          boxShadow: "0 4px 44px rgba(0, 0, 0, 0.55)",
        }}
      >
        <img
          src={logo}
          className="w-[100px] sm:w-[150px] md:w-[200px] mb-3 sm:mb-6 mx-auto"
          alt="CDA Logo"
        />
        <h2 className="w-full text-[#2D796D] font-bold hidden md:block md:text-4xl mb-6">
          Enter your
          <br />
          New Password
        </h2>
        <h2 className="w-full text-[#2D796D] font-bold text-base md:hidden mb-2">
        Enter your new Password
        </h2>
        {/* <p className="text-[#A0AEC0] w-full mb-6 hidden md:block text-base">
          Enter your Email to get the Password
        </p> */}
        <Form
          form={form}
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-full max-w-[550px] mx-auto"
        >
          {/* <Form.Item
            label="Email"
            name="id"
            className="mb-5"
            rules={loginValidationRules.email}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            label="New Password"
            name="password"
            className="mb-5"
            rules={loginValidationRules.password}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            className="mb-5"
            rules={loginValidationRules.password}
          >
            <Input.Password />
          </Form.Item>
          {/* Secret Key =  6LcHuigqAAAAAALU-6-75739pIS8YnR50mb8oYhs */}
          {/* <Form.Item
            name="recaptcha"
            className="mb-2 w-full sm:mb-5 flex justify-center"
            rules={loginValidationRules.recaptcha}
          >
            <div className="recaptcha-container">
              <ReCAPTCHA
                size={isTabletOrMobile ? "compact" : "normal"}
                sitekey={SITE_KEY}
                onChange={onRecaptchaChange}
              />
            </div>
          </Form.Item> */}
          {error && (
            <Alert
              className="mb-4 text-sm sm:text-base"
              message="Login failed"
              type="error"
            />
          )}

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              className="bg-[#2D796D] mt-0 sm:mt-4 py-5 hover:!bg-[#2D796D] hover:border-[#2D796D] border-[#2D796D] rounded-lg w-full p-3 text-base sm:text-lg font-semibold sm:font-bold"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>

          {/* <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Link
              to="/forgot-password"
              className="text-[#1890ff] text-sm sm:text-base hover:text-[#40a9ff]"
            >
              Forgot Password?
            </Link>
          </Form.Item> */}

          {/* <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
          <Link
            to="/signup"
            className="block text-center mt-5 text-[#1890ff] hover:text-[#40a9ff]"
          >
            Don't have an account? Signup
          </Link>
        </Form.Item> */}
        </Form>

        <div className="absolute bottom-5 left-5 right-10">
          <p className="text-center mx-auto text-[#A0AEC0] text-sm sm:text-base font-sans">
            @ 2024, All rights reserved by{" "}
            <span className="text-[#38B2AC] cursor-pointer">CDA</span>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
