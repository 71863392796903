import React, { useState, ReactNode } from "react";
import styles from "./areaList.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { useGetSubAreaQuery } from "@services/api/index";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";
import Search from "antd/es/transfer/search";

interface Area {
  id: string;
  name: string;
  zone: string;
  active: boolean;
}

interface SubAreaListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  searchValue?: string;
}

const SubAreaList: React.FC<SubAreaListProps> = ({
  editComp,
  viewComp,
  searchValue,
}) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const {
    data: area,
    error,
    isLoading,
  } = useGetSubAreaQuery({ ...pagination, searchText: searchValue });

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Sub Area",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      className: styles.tableCell,
    },

    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Area) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_AREA) &&
            viewComp &&
            viewComp(record.id)}
          {hasPermission(permissions.EDIT_AREA) &&
            editComp &&
            editComp(record.id)}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={area}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
    </div>
  );
};

export default SubAreaList;
