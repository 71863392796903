import React, { useMemo } from "react";
import { Input, Skeleton } from "antd";
import { Tag } from "antd";
import { useGetZoneByIdQuery } from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
interface ViewZoneProps {
  onClose?: () => void;
  id: string;
}

const ViewRole: React.FC<ViewZoneProps> = ({ onClose, id }) => {
  const {
    data: zoneById,
    error: zoneByIdError,
    isLoading: zoneByIdIsLoading,
  } = useGetZoneByIdQuery(id);

  const tags = useMemo(() => {
    let zoneAreas = zoneById?.data?.subZones;
    if (zoneAreas) return zoneAreas.split(",");
  }, [zoneById]);

  return (
    <>
      {zoneByIdIsLoading ? (<Skeleton active />) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            isLoading={zoneByIdIsLoading}
            error={zoneByIdError}
          >
            <h1>Zone Id</h1>
            <Input
              value={zoneById?.data.zoneId}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Name</h1>
            <Input
              value={zoneById?.data.name}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Sub Zones</h1>
            {tags && tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
          </AppFormWrapper>
        </>
      )}

    </>
  );
};

export default ViewRole;
