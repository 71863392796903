import { Rule } from "antd/es/form";

interface RoleValidator {
    name: Rule[];
}

export const roleValidator: RoleValidator = {
    name: [
    { required: true, message: "Please input role name" },
  ],
};
