export const permissions = {
  //View Components
  VIEW_DASHBOARD: "view-dashboard",
  VIEW_USER_MANAGEMENT: "view-user-management",
  VIEW_ROLE_MANAGEMENT: "view-role-management",
  VIEW_PERMISSION_MANAGEMENT: "view-permission-management",
  VIEW_ZONE_MANAGEMENT: "view-zone-management",
  VIEW_TASK_MANAGEMENT: "view-task-management",
  VIEW_PROPERTY_MANAGEMENT: "property-type-management",
  VIEW_VIOLATION_MANAGEMENT: "violation-type-management",
  VIEW_VIOLATION_TYPE: "view-violation-type",
  VIEW_PROPERTY_TYPE: "view-property-type",
  VIEW_USER: "view-user",
  VIEW_ROLE: "view-role",
  VIEW_PERMISSION: "view-permission",
  VIEW_ZONE: "view-zone",
  VIEW_TASK: "view-task",
  VIEW_AREA_MANAGEMENT: "view-area-management",
  VIEW_CHALLAN_MANAGEMENT: "view-challan-management",
  VIEW_AREA: "view-area",
  VIEW_SURVEY_MANAGEMENT: "view-survey",
  VIEW_SOCIETY_SURVEY: "view-society-survey",
  VIEW_SOCIETY_SURVEY_MANAGEMENT: "view-society-survey-management",
  VIEW_SOCIETY: "view-society",
  VIEW_SOCIETY_MANAGEMENT: "view-society-management",
  VIEW_SOCIETY_VIOLATION_MANAGEMENT: "view-violation-type-society-management",
  VIEW_SOCIETY_USER_MANAGEMENT: "view-society-user-management",
  VIEW_SOCIETY_USER: "view-society-user",
  

  //ADD components
  ADD_PROPERTY_MANAGEMENT: "add-property-type",
  ADD_PROPERTY_TYPE: "add-property-type",
  ADD_VIOLATION_MANAGEMENT: "add-violation-type",
  ADD_USER: "add-user",
  ADD_ROLE: "add-role",
  ADD_PERMISSION: "add-permission",
  ADD_ZONE: "add-zone",
  ADD_TASK: "add-task",
  ADD_AREA: "add-area",
  ADD_SOCIETY: "add-society",
  ADD_SOCIETY_USER: "add-society-user",

  //Edit Components
  EDIT_PROPERTY_MANAGEMENT: "edit-property-type",
  EDIT_VIOLATION_MANAGEMENT: "edit-violation-type",
  EDIT_TASK: "edit-task",
  EDIT_ROLE: "edit-role",
  EDIT_PERMISSION: "edit-permission",
  EDIT_ZONE: "edit-zone",
  EDIT_USER: "edit-user",
  EDIT_AREA: "edit-area",
  EDIT_SOCIETY: "edit-society",
  EDIT_SOCIETY_USER: "edit-society-user",

  //Delete Components
  DELETE_PROPERTY_MANAGEMENT: "delete-property-type",
  DELETE_VIOLATION_MANAGEMENT: "delete-violation-type",
  DELETE_TASK: "delete-task",
  DELETE_ROLE: "delete-role",
  DELETE_PERMISSIONE: "delete-permission",
  DELETE_ZONE: "delete-zone",
  DELETE_USER: "delete-user",
  DELETE_AREA: "delete-area",
  DELETE_SOCIETY: "delete-society",
  DELETE_SOCIETY_USER: "delete-society-user",
} as const;

export type PermissionSlugs = (typeof permissions)[keyof typeof permissions];
export type PermissionKeys = keyof typeof permissions;
