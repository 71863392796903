import React, { Suspense, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import useUser from "@hooks/userHook";
import AppSidebar from "@components/appSidebar"
import { socket } from "socket";
import { notification } from "antd";

export default function AppLayout() {

  const user = useUser();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message: string) => {
    api.info({
      message: message,
      description: `Hello, ${user?.name}!`,
    })
  };

  useEffect(() => {

    //  if (user && user.areaIds && user.areasIds[0])
    socket.emit('joinAreas', user?.areasIds); // Assume areaIds is an array of area IDs

    // User joins multiple areas

    // Listen for task creation notifications in any of the user's areas
    socket.once('surveyCreated', (data: any) => {
      console.log('New task created:', data);
      setTimeout(() => openNotification('New Survey created'), 1000)
      // Implement UI notification logic here
    });
  }, [])

  return (
    <>
      {contextHolder}
      <AppSidebar user={user}>
        <Suspense fallback={<div>Loading...</div>}>

          <Outlet />

        </Suspense>
      </AppSidebar>
    </>
  )
}