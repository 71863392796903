import RolesList from "@components/Roles/rolesList";
import React from "react";
// import AddRole from "@components/Roles/addRole";
import AppPageWrapper from "@components/appPageWrapper";
import EditRole from "@components/Roles/editRole";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import ViewRole from "@components/Roles/viewRole";

const RoleManagement: React.FC = () => {
    const { hasPermission } = usePermissions();

    return hasPermission(permissions.VIEW_ROLE_MANAGEMENT) ? (
        <AppPageWrapper
            // addComp={() => hasPermission(permissions.ADD_ROLE) ? <AddRole /> : null}
            listComp={<RolesList />}
            viewComp={(id: string) => <ViewRole id={id} />}
            editComp={(id: string) => <EditRole id={id} />}
            moduleName="Role"
            pagetTitle="Role Management"
        />
    ) : null;
};

export default RoleManagement;
