const apiTags = {
  user: "User",
  role: "Role",
  zone: "Zone",
  subZone: "subZone",
  area: "Area",
  task: "Task",
  violation: "Violation",
  societyViolation: "SocietyViolation",
  property: "Property",
  challan: "challan",
  subArea: "subArea",
  society: "Society",
  societySurvey: "SocietySurvey",
};

export default apiTags;
