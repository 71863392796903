import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  Violation,
  Zone,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const violationApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getViolation: builder.query<
    ResponseListPaginate<Violation>,
    PaginationPayload
  >({
    query: ({ page, limit }: any) => ({
      url: apiRoutes.getViolation,
      params: { page, limit },
    }),
    providesTags: [apiTags.violation],
  }),
  getSocietyViolation: builder.query<
    ResponseListPaginate<Violation>,
    PaginationPayload
  >({
    query: ({ page, limit }: any) => ({
      url: apiRoutes.getSocietyViolation,
      params: { page, limit },
    }),
    providesTags: [apiTags.violation],
  }),
  getViolationDropdown: builder.query<ResponseList<Violation>, void>({
    query: () => apiRoutes.getViolationDropdown,
  }),
  createViolation: builder.mutation({
    query: (body) => ({
      url: apiRoutes.createViolation,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.violation],
  }),
  editViolation: builder.mutation({
    query: (body: Violation) => ({
      url: apiRoutes.editViolation(body.id),
      method: "PATCH",
      body,
    }),
    invalidatesTags: [apiTags.violation],
  }),
  deleteViolation: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.deleteViolation(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.violation],
  }),
  changeStatusViolation: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.changeStatusViolation(id),
      method: "PATCH",
    }),
    invalidatesTags: [apiTags.violation],
  }),
  getViolationById: builder.query<Response<Violation>, string>({
    query: (id: string) => apiRoutes.getViolationById(id),
  }),
});
