const surveyFormField = [
  {
    section: 'Urban Planning Data',
    fieldName: 'File No. (EM)',
    key: 'fileNoEM',
    type: 'number'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Plot No.',
    key: 'plotNo',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Street/Road',
    key: 'streetRoad',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Sub-Sector',
    key: 'subAreaId',
    type: 'enum'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Sector/Scheme',
    key: 'areaId',
    type: 'enum'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Corner Status',
    key: 'cornerStatus',
    type: 'enum',
    values: ["Non-Corner", "Corner"]
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Classification of Plot',
    key: 'classificationOfPlot',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Size (Sq. yd.)',
    key: 'size',
    type: 'number'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Dimension',
    key: 'dimension',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Sub-Division',
    key: 'subDivision',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Amalgamation',
    key: 'amalgamation',
    type: 'string'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Date of Certificate of Possession',
    key: 'certificateOfCompletion',
    type: 'date'
  },
  {
    section: 'Urban Planning Data',
    fieldName: 'Construction Status',
    key: 'constructionStatus',
    type: 'enum',
    values: ["Vacant", "Constructed"]
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Type of Allotment',
    key: 'typeOfAllotment',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Date of 1st Allotment',
    key: 'dateOfFirstAllotment',
    type: 'date'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Name of 1st Allottee',
    key: 'firstAllotteeName',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'CNIC of 1st Allottee',
    key: 'firstAllotteeCnic',
    type: 'cnic'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Date of Last Transfer',
    key: 'dateOfLastTransfer',
    type: 'date'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'CNIC of Present Owner',
    key: 'presentOwnerCnic',
    type: 'cnic',
    
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Name of Present Owner',
    key: 'presentOwnerName',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Mortgage Status',
    key: 'mortgageStatus',
    type: 'enum',
    values: ["Yes", "No"]
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Bank Name (if mortgaged)',
    key: 'bankName',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Status of Lease',
    key: 'leaseStatus',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Lease Ending Date',
    key: 'leaseEndingDate',
    type: 'date'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Position of Power of Attorney',
    key: 'powerOfAttorney',
    type: 'enum',
    values: ["Yes", "No"]
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Conveyance Deed Executed / Sale Deed',
    key: 'conveyanceDeedStatus',
    type: 'enum',
    values: ["Yes", "No"]
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Litigation (if any)',
    key: 'litigationStatus',
    type: 'string'
  },
  {
    section: 'Estate Management Data',
    fieldName: 'Any other Information',
    key: 'otherInformation',
    type: 'string'
  },
  {
    section: 'Building Control Section',
    fieldName: 'Date of Approval of Building Plan',
    key: 'buildingPlanApprovalDate',
    type: 'date'
  },
  {
    section: 'Building Control Section',
    fieldName: 'Approved Covered Area (sft)',
    key: 'approvedCoveredAreaSft',
    type: 'number'
  },
  {
    section: 'Building Control Section',
    fieldName: 'No. of Storeys',
    key: 'noOfStoreys',
    type: 'number'
  },
  {
    section: 'Building Control Section',
    fieldName: 'No. of Units in the Building',
    key: 'noOfUnits',
    type: 'number'
  },
  {
    section: 'Building Control Section',
    fieldName: 'Date of Completion Certificate',
    key: 'completionCertificateDate',
    type: 'date'
  },
  {
    section: 'Building Control Section',
    fieldName: 'Completion Covered Area (sft)',
    key: 'completionCoveredAreaSft',
    type: 'number'
  },
  {
    section: 'Building Control Section',
    fieldName: 'Condition of Building',
    key: 'conditionOfBuilding',
    type: 'string'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Property Tax (Annual Demand)',
    key: 'propertyTaxAnnual',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Property Tax (Paid Up to)',
    key: 'propertyTaxPaidUp',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Property Tax (Arrears)',
    key: 'propertyTaxArrears',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Water Charges (PKR)-A',
    key: 'waterCharges',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Conservancy Charges (PKR)-B',
    key: 'conservancyCharges',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Quarterly Demand (A+B)',
    key: 'quarterlyDemandAB',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Arrears (PKR) (A+B)',
    key: 'arrearsPKRA',
    type: 'number'
  },
  {
    section: 'Revenue Data (PKR)',
    fieldName: 'Payment Status (A+B)',
    key: 'paymentsAIB',
    type: 'number'
  },
];
export default surveyFormField;