import { Rule } from "antd/es/form";

interface AreaValidator {
  name: Rule[];
  subZone: Rule[]
}

export const areaValidator: AreaValidator = {
  name: [
    { required: true, message: "Please input area name" },
  ],
  subZone: [
    { required: true, message: "Please select sub zone" }
  ],
};
