import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  DatePicker,
  notification,
  Row,
  Col,
  InputNumber,
  Skeleton,
  Alert,
  Flex,
  Descriptions,
  DescriptionsProps,
  Modal,
  Image,
  Carousel,
} from "antd";
import {
  useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
} from "@services/api/index";
import { DownloadOutlined } from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { Task } from "types";
import { taskValidator } from "@validators/taskValidator";
import MapWithImages from "@components/googleMapWithImages";
import { API_BASE_URL } from "@constants/index";
import styles from "./viewTask.module.css";

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  path?: string;
  lat: any;
  long: any;
}

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string;
}

interface ViewTaskProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

const ViewTask: React.FC<ViewTaskProps> = ({ onClose, id, isLoaded }) => {
  const [editTask, { isLoading, error }] = useEditTaskMutation();
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | null>(null);

  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [surveyImages, setSurveyImages] = useState<Attachment[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const carouselRef = useRef<any>(null);

  const handleCancel = () => setPreviewOpen(false);
  const [previewImage, setPreviewImage] = useState("");
  // const handlePreview = (imageUrl: string) => {
  //   setPreviewImage(imageUrl);
  //   setPreviewOpen(true);
  // };

  const handlePreview = (index: number) => {
    setCurrentSlideIndex(index); // Set the index of the clicked image
    setPreviewOpen(true); // Open the modal
  };

  useEffect(() => {
    if (previewOpen && carouselRef.current) {
      // Jump to the current slide and force autoplay
      carouselRef.current.goTo(currentSlideIndex, true); // true forces the transition
    }
  }, [previewOpen, currentSlideIndex]);

  const {
    data: inspectors,
    error: inspectorsError,
    isLoading: inspectorsIsLoading,
  } = useGetInspectorsQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  const task = useMemo<Task | undefined>(
    () => taskDataById && taskDataById.data,
    [taskDataById]
  );

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      form.setFieldsValue({
        assignedToId: task.assignedToId,
        dueDate: moment(task.dueDate),
        propertyNo: task.propertyNo,
        propertyName: task.propertyName,
        streetName: task.streetName,
        ownerName: task.ownerName,
        phoneNo: task.phoneNo,
        plotLength: task.plotLength,
        plotWidth: task.plotWidth,
        buildingHeight: task.buildingHeight,
        floorAreaSft: task.floorAreaSft,
        floorAreaSqy: task.floorAreaSqy,
        noOfStories: task.noOfStories,
        landUseConversionAndRegularizationCharges:
          task.landUseConversionAndRegularizationCharges,
        inspectorRemarks: task.inspectorRemarks,
        tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
          task.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges,
      });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
      if (task.attachments) {
        setSurveyImages(task.attachments || []);
      }

      if (task.lat && task.long) {
        setMapCenter({
          lat: parseFloat(task.lat),
          lng: parseFloat(task.long),
        });
      }
    }
  }, [taskDataById, form]);

  const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
    setPolygonData(newPolygons ? newPolygons : polygonData);
  };

  const handleUploadChange = (info: any) => {
    setFileList(info.fileList);
  };

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onFinish = async (values: any) => {};

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const center = polygonData && polygonData.length > 0 ? polygonData[0] : {
  //   lat: 33.6938,
  //   lng: 73.0652,
  // };

  const center = {
    lat: 33.6938,
    lng: 73.0652,
  };

  // const items: DescriptionsProps['items'] = useMemo(() => taskDataById && Object.keys(taskDataById?.data).map((val, index) => ({
  //   key: index + 1,
  //   label: val.toString(),
  //   children: val == 'id' ? index + 1 : taskDataById && (taskDataById as any)?.data[val],
  // })).filter((val) => taskDataById && (taskDataById as any)?.data[val.label] && !val.label.includes('Id')), [taskDataById])

  function convertCamelCaseToLabel(text: string): string {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, " $1$2")
      .replace(/^./, (str) => str.toUpperCase());
  }

  const items: DescriptionsProps["items"] = useMemo(() => {
    if (!taskDataById) return [];

    const task = taskDataById.data as Task;

    return Object.keys(task)
      .filter(
        (key) =>
          key.slice(-2) !== "Id" &&
          key !== "attachments" &&
          key !== "violationIds" &&
          key !== "id" &&
          key !== "propertyTypeId" &&
          key !== "assignedToId" &&
          key !== "hierarchy" &&
          key !== "actualUsageOther" &&
          key !== "currenUsageOther" &&
          task[key as keyof Task] !== null
      )
      .map((key, index) => {
        let value = task[key as keyof Task];

        if (typeof value === "string" || value instanceof Date) {
          if (moment(value, moment.ISO_8601, true).isValid()) {
            value = moment(value).format("DD-MM-YYYY");
          }
        }

        const displayValue =
          typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value?.toString();

        return {
          key: index + 1,
          label: convertCamelCaseToLabel(key),
          children: key === "id" ? index + 1 : displayValue,
        };
      });
  }, [taskDataById]);

  const imageMarkers: ImageMarker[] = surveyImages.map((attachment) => ({
    coordinates: {
      lat: parseFloat(attachment.lat),
      lng: parseFloat(attachment.long),
    },
    images: attachment.path ? `${API_BASE_URL}${attachment.path}` : "", // Wrapping the path in an array
  }));

  return (
    <div className="h-full pb-20 gap-2 flex">
      <div className="w-[60%]">
        {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
        <MapWithImages
          center={mapCenter || center}
          imageMarkers={imageMarkers}
          isLoaded={true}
        />
        <div className="my-8 h-[35vh] flex">
          {surveyImages.map((attachment, index) => {
            // Ensure attachment.path is a string, or use an empty string if undefined
            const path: string = attachment.path ?? "";
            const fileName = path.split("/").pop(); // Get the file name from the path

            // Get the file extension
            const fileExtension = path.split(".").pop()?.toLowerCase();
            const isImage = ["png", "jpg", "jpeg", "gif"].includes(
              fileExtension || ""
            ); // Check against image extensions

            return isImage ? (
              // Render image if the file is an image
              <Image
                key={index}
                width={100}
                src={`${API_BASE_URL}${path}`}
                alt={`attachment-${index}`}
                onClick={() => handlePreview(index)}
                className="cursor-pointer p-2"
                preview={false}
              />
            ) : (
              // Render document file name and download button for non-image files
              <div
                key={index}
                className="flex flex-col items-center"
              >
                <p className="text-gray-600 mb-2">{fileName}</p>{" "}
                {/* Display file name */}
                <a
                  href={`${API_BASE_URL}${path}`}
                  download
                  className="cursor-pointer p-2 text-blue-500"
                >
                  <Button icon={<DownloadOutlined />}>Download File</Button>
                </a>
              </div>
            );
          })}

          <Modal
            open={previewOpen}
            onCancel={handleCancel}
            footer={null}
            width={800}
          >
            <Carousel
              ref={carouselRef}
              initialSlide={currentSlideIndex}
              autoplay={false} // Start the carousel automatically
              autoplaySpeed={3000} // Speed for autoplay transitions
              waitForAnimate={false} // Ensure it doesn't wait for manual click
              arrows={true} // Show left and right arrows
              className={styles.customCarousel} // Apply the custom CSS class here
              style={{ position: "relative" }} // Ensure the container has position relative
            >
              {surveyImages.map((attachment, index) => (
                <div key={index}>
                  <img
                    alt={`attachment-${index}`}
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "contain",
                    }}
                    src={`${API_BASE_URL}${attachment.path}`}
                  />
                </div>
              ))}
            </Carousel>
          </Modal>
        </div>
      </div>
      <div className="h-[125vh] w-[40%] overflow-y-auto">
        <AppFormWrapper
          onClose={onClose}
          error={taskDataError}
          isLoading={taskDataIsLoading}
        >
          <>
            {taskDataIsLoading ? (
              <Skeleton active />
            ) : taskDataError ? (
              <Alert
                message={
                  (taskDataError as any)?.data?.message || "An error occurred"
                }
                type="error"
                className="mb-4"
              />
            ) : (
              <>{task && <Descriptions bordered column={1} items={items} />}</>
            )}
          </>
        </AppFormWrapper>
      </div>
    </div>
  );
};

export default ViewTask;
