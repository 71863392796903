import React from 'react';
import { BrowserRouter as Router, createBrowserRouter, RouterProvider, createHashRouter } from 'react-router-dom';
import { Layout } from 'antd';
import routes from './routes';
import './App.css'


const { Header, Content } = Layout;

const App: React.FC = () => {

  const router = createHashRouter([
    {
      //element: <Layout />,
      //errorElement: <Page404 />,
      children: routes
    },
  ]);

  return (
    <Layout>  
      <Content>
        <RouterProvider router={router} />
      </Content>
    </Layout>
  );
};

export default App;
