import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Flex, Modal, Space, theme } from "antd";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "antd/es/layout/layout";
import authPaths from "@paths/authPaths";
import { clearCredentials } from "@redux/authSlice";
import { API_BASE_URL, IMG_BASE_URL } from "@constants/index";
import "./styles.css";
import defaultImg from "../../assets/img/defaultImage.jpeg";
import image1 from "../../assets/img/logo.svg";
import { useMediaQuery } from "react-responsive";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

interface NavbarProps {
  setIsModalVisible: (val: boolean) => void;
  user: any;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const handleMenuClick = (e: any) => {
    navigate(e.key);
    if (isTabletOrMobile) {
      setIsModalVisible(false); // Close the modal after clicking a menu item
    }
  };

  const { useToken } = theme;

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: (
    //     <a
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href=""
    //     >
    //       Edit Profile
    //     </a>
    //   ),
    // },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { user } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showLogoutModal = () => {
    setIsModalVisible(true);
  };

  const handleConfirmLogout = () => {
    dispatch(clearCredentials());
    navigate(authPaths.login());
    setIsModalVisible(false);
  };

  const handleCancelLogout = () => {
    setIsModalVisible(false);
  };

  // const handleLogout = () => {
  //   dispatch(clearCredentials());
  //   navigate(authPaths.login());
  // };

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Header className="sticky py-4 bg-[#F8F9FA] top-0 z-10 pl-3 pr-10 h-[85px] md:px-4">
      <Flex
        className="h-full my-auto py-0 md:py-8 justify-between xl:justify-end"
        align="center"
      >
        <div className={`${isTabletOrMobile ? 'hidden': 'block'}`}></div>
        <div
          className={`color-white gap-1 md:gap-4 xl:hidden text-2xl text-[#2D3748] font-bold flex flex-col md:flex-row text-center justify-center align-middle cursor-pointer ${
            isTabletOrMobile ? "block" : "hidden"
          }`}
        >
          <img
            src={image1}
            className="w-[75px] md:w-[100px] pt-6 pb-0 md:pb-3"
          />
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => props.setIsModalVisible(true)}
            style={{ color: "#2D796D", margin: "auto" }}
          />
        </div>
        <div className="mt-4">
          <Dropdown
            menu={{ items }}
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle,
                })}
                <Divider className="m-0" />
                <Space className="p-1 flex justify-center">
                  <Button
                    className="bg-transparent border-0 hover:!text-black hover:!bg-gray-100 "
                    onClick={showLogoutModal}
                  >
                    Logout
                  </Button>
                </Space>
              </div>
            )}
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="text-black font-semibold text-sm md:text-base hover:text-[#2D796D]"
            >
              <Space className="gap-0 md:gap-2">
                <img
                  src={
                    user?.picture !== ""
                      ? API_BASE_URL + user.picture
                      : defaultImg
                  }
                  alt="Image"
                  className="md:w-[60px] w-[40px] bg-transparent rounded-[50%] mr-1"
                />
                {user?.name}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </Flex>
      <Modal
        title="Confirm Logout"
        open={isModalVisible}
        onOk={handleConfirmLogout}
        onCancel={handleCancelLogout}
        okText="Logout"
        cancelText="Cancel"
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </Header>
  );
};

export default Navbar;
