import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import SubZoneList from "@components/subZones/subZoneList";
import ViewSubZone from "@components/subZones/viewSubZone";
const SubZoneManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_ZONE_MANAGEMENT) ? (
    <AppPageWrapper
      listComp={<SubZoneList />}
      viewComp={(id: string) => <ViewSubZone id={id} />}
      moduleName="Sub Zone"
      pagetTitle="Sub Zone Management"
    />
  ) : null;
};

export default SubZoneManagement;
