import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import cdalogo from "../../../assets/img/cdalogopng.png"; // Ensure the path to your logo is correct
import { API_BASE_URL } from "@constants/index";

// Define types for your task data
interface Fields {
  [key: string]: any;
}

interface MyDocumentProps {
  fields: Fields;
  printDate: string;
  printUser: string;
  attachments: any;
}

const styles = StyleSheet.create({
  page: { padding: 30 },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: "auto",
  },
  imageContainer: {
    alignItems: "flex-start", // Align items to the start
    marginTop: 20, // Adds some spacing from other content
  },
  image: {
    width: 300,
    height: "auto",
    marginTop: 5,
  },
  attachmentText: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5, // Adds spacing below the text
  },
  titleContainer: {
    alignItems: "flex-end",
    textAlign: "right",
  },
  reportTitle: {
    fontSize: 15,
    fontWeight: "bold",
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 3,
  },
  subtitle: {
    fontSize: 5,
    marginTop: 4,
  },
  textSection: {
    marginBottom: 10,
    marginTop: 10,
  },
  textDescription: {
    fontSize: 8,
    marginBottom: 3,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 15,
    borderCollapse: "collapse",
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
    paddingLeft: 3,
    width: "50%",
    margin: 0,
  },
  tableCellValue: {
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: "#000",
    paddingLeft: 3,
    width: "50%",
    margin: 0,
  },
  tableCellText: { fontSize: 8 },
  tableCellLabel: { fontWeight: "bold" },
  gridContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  gridItem: {
    width: "48%",
    marginBottom: 10,
  },
  attachmentContainer: {
    marginTop: 2, // Adds some spacing from other content
  },
  imageCenterContainer: {
    alignItems: "center", // Center the image horizontally
    marginTop: 10, // Adds spacing above the image
  },
});

const MyDocument: React.FC<MyDocumentProps> = ({
  fields,

  printDate,
  printUser,
  attachments,
}) => {
  // Function to convert data into rows for the table
  const convertToTableRows = (data: { [key: string]: any }) =>
    Object.entries(data).map(([key, value]) => ({
      label: value.label || "",
      value: value.value || "",
    }));

  // Function to render a table
  const renderTable = (data: { label: string; value: string }[]) => (
    <View style={styles.table}>
      {data.map((item, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={styles.tableCell}>
            <Text style={[styles.tableCellText, styles.tableCellLabel]}>
              {item.label}
            </Text>
          </View>
          <View style={styles.tableCellValue}>
            <Text style={styles.tableCellText}>{item.value}</Text>
          </View>
        </View>
      ))}
    </View>
  );

  // Prepare data arrays for tables
  const fieldsArray = convertToTableRows(fields);

  // Extract the first attachment's path if available and prepend the base URL
  const attachmentPath =
    attachments && `${API_BASE_URL}${attachments?.[0]?.path}`;

  return (
    <Document>
      {/* First Page with Report Content */}
      <Page style={styles.page}>
        <View style={styles.headerContainer}>
          <Image src={cdalogo} style={styles.logo} />
          <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>Survey Report</Text>
            <Text style={styles.subtitle}>{`Print Date: ${printDate}`}</Text>
            <Text style={styles.subtitle}>{`Printed By: ${printUser}`}</Text>
          </View>
        </View>
        <View style={styles.textSection}>
          <Text style={styles.textDescription}>
            This report provides details of the inspection conducted at the
            site. The inspector visited the site and the following violations
            were found. The report below outlines all the identified violations
            in detail.
          </Text>
        </View>

        {renderTable(fieldsArray)}

        <View style={styles.attachmentContainer}>
          <Text style={styles.attachmentText}>Attachments:</Text>
        </View>
        {attachmentPath && (
          <View style={styles.imageCenterContainer}>
            <Image src={attachmentPath} style={styles.image} />
          </View>
        )}
      </Page>
    </Document>
  );
};

export default MyDocument;
