import React, { ReactNode, useEffect, useState } from "react";
import {
  useGetSubZoneQuery,
  //   useGetZoneQuery,
  // useDeleteZoneMutation,
  // useChangeStatusZoneMutation,
} from "@services/api/index";
import styles from "./subzone.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
// import { Switch, Modal, notification } from "antd";
// import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface SubZone {
  id: string;
  name: string;
  status: string;
}

interface SubZoneListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
}

const SubZoneList: React.FC<SubZoneListProps> = ({ editComp, viewComp }) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  //   const { data: zones, error, isLoading } = useGetZoneQuery(pagination);
  const { data: subZones, error, isLoading } = useGetSubZoneQuery(pagination);
  // const [
  //   deleteZone,
  //   { isLoading: deleteZoneIsloading, error: deleteZoneError },
  // ] = useDeleteZoneMutation();
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [recordToDelete, setRecordToDelete] = useState<Zone | null>(null);
  // const [
  //   changeStatusZone,
  //   { isLoading: changeStatusZoneIsloading, error: changeStatusZoneError },
  // ] = useChangeStatusZoneMutation();

  // const handleToggleChange = async (checked: boolean, record: Zone) => {
  //   // Implement toggle functionality
  //   const result = await changeStatusZone(record.id).unwrap();
  //   console.log(`Toggled ${record.name} to ${checked ? "Active" : "Inactive"}`);
  // };

  // const showDeleteConfirm = (record: Zone) => {
  //   setRecordToDelete(record);
  //   setIsDeleteModalOpen(true); // Open the delete confirmation modal
  // };

  // const handleDelete = async () => {
  //   if (recordToDelete) {
  //     const result = await deleteZone(recordToDelete.id).unwrap();
  //     notification.success({
  //       message: "Zone Deleted successful:",
  //       placement: "topRight",
  //       duration: 3, // Duration in seconds
  //     });
  //     // Implement actual delete functionality here
  //   }
  //   setIsDeleteModalOpen(false);
  //   setRecordToDelete(null);
  // };

  // const handleCancel = () => {
  //   setIsDeleteModalOpen(false);
  //   setRecordToDelete(null);
  // };
  // useEffect(() => {
  //   if (changeStatusZoneError) {
  //     notification.error({
  //       message: "Role Updated failed:",
  //       placement: "topRight",
  //       duration: 3, // Duration in seconds
  //     });
  //   }
  // }, [changeStatusZoneError]);

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Sub Zone",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    // {
    //   title: "Zone Supervisor",
    //   dataIndex: "supervisor",
    //   key: "supervisor",
    //   className: styles.tableCell,
    // },
    // {
    //   title: "Zone Id",
    //   dataIndex: "zoneId",
    //   key: "zoneId",
    //   className: styles.tableCell,
    // },
    {
      title: "Sub Zone Area",
      dataIndex: "area",
      key: "area",
      className: styles.tableCell,
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text: any, record: Zone) => (
    //     <Switch
    //       disabled={changeStatusZoneIsloading}
    //       checked={record.status == "active"}
    //       onChange={async (checked) =>
    //         await handleToggleChange(checked, record)
    //       }
    //     />
    //   ),
    //   className: styles.tableCell,
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: SubZone) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_ZONE) &&
            viewComp &&
            viewComp(record.id)}
          {/* {hasPermission(permissions.EDIT_ZONE) &&
            editComp &&
            editComp(record.id)} */}
          {/* {hasPermission(permissions.DELETE_ZONE) && (
            <DeleteOutlined
              className={styles.icon}
              onClick={() => showDeleteConfirm(record)}
            />
          )} */}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={subZones}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />

      {/* Delete Confirmation Modal */}
      {/* <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal> */}
    </div>
  );
};

export default SubZoneList;
