import React from "react";
import GoogleMapComp from "@components/googleMap";
import Cards from "common/cards";

interface DashboardScreenProps {
    isLoaded?: boolean
}


const DashboardScreen: React.FC<DashboardScreenProps> = ({ isLoaded }) => {

    return (
        < >
            <Cards />
            <GoogleMapComp isLoaded={isLoaded} />
        </>
    );
};

export default DashboardScreen;
