import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import SurveyTypeList from "@components/Survey/surveyTypeList";
import ViewSurvey from "@components/Survey/viewSurvey";
import EditSurvey from "@components/Survey/editSurvey";


const SurveyManagment: React.FC = () => {
    const { hasPermission } = usePermissions();
    //   return (hasPermission(permissions.VIEW_SURVEY_MANAGEMENT) ?
    //     <AppPageWrapper
    //   listComp={<SurveyTypeList />}
    //   moduleName="Survey"
    //   pagetTitle="Survey Management"
    // /> : null
    //   )
    return (
        <AppPageWrapper
    pagetTitle="Survey Management" 
    moduleName="Survey"
    listComp={<SurveyTypeList />}
    viewComp={(id: string) => <ViewSurvey id={id} isLoaded={true} />} 
    // editComp={(id: string) => <EditSurvey id={id} isLoaded={true} />}
    proceedComp={(id: string) => <EditSurvey id={id} isLoaded={true} />}

/>

    )
};

export default SurveyManagment;
