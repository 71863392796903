import React from "react";
import { RouteObject } from "react-router-dom";
import subAreaManagementPaths from "@paths/subAreaManagementPaths";
import SubAreaManagement from "@pages/subAreaManagement"
import AddSubArea from "@components/subArea/addSubArea";

const subAreaManagementMapping: RouteObject[] = [
  {
    path: subAreaManagementPaths.root,
    element: <SubAreaManagement />,
  },
  {
    path: subAreaManagementPaths.AddSubArea,
    element: <AddSubArea />,
  },
];

export default subAreaManagementMapping;
