import React from "react";
import { RouteObject } from "react-router-dom";
import challanManagementPaths from "@paths/challanManagementPaths";
import ChallanManagement from "@pages/challanManagement";

const challanTypeManagementMappings: RouteObject[] = [
  {
    path: challanManagementPaths.root,
    element: <ChallanManagement />,
  },
];

export default challanTypeManagementMappings;
