import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  Zone,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const zoneApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getZone: builder.query<ResponseListPaginate<Zone>, PaginationPayload>({
    query: ({ page, limit }: any) => ({
      url: apiRoutes.getZone,
      params: { page, limit },
    }),
    providesTags: [apiTags.zone],
  }),
  createZone: builder.mutation<Response<Zone>, Zone>({
    query: (body) => ({
      url: apiRoutes.createZone,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.zone],
  }),
  getZoneDropdown: builder.query<ResponseList<Zone>, void>({
    query: () => apiRoutes.getZoneDropdown,
  }),
  editZone: builder.mutation<Response<Zone>, { id: string, body: Zone }>({
    query: ({ id, body }) => ({
      url: apiRoutes.editZone(id),
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.zone],
  }),
  deleteZone: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.deleteZone(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.zone],
  }),
  changeStatusZone: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.changeStatusZone(id),
      method: "POST",
    }),
    invalidatesTags: [apiTags.zone],
  }),
  getZoneById: builder.query<Response<Zone>, string>({
    query: (id: string) => apiRoutes.getZoneById(id),
  }),

  
});
