import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useRequestOTPMutation, useVerifyOTPMutation, useResetPasswordMutation } from "@services/api";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../assets/img/logo.svg";
import { forgotPasswordValidatorRules } from "@validators/forgotPasswordValidator";
import { SITE_KEY } from "@constants/index";
import { useMediaQuery } from "react-responsive";
import NotificationHandler from "@components/notificationHandler";

const FogotPassword: React.FC = () => {
  const [requestOTP, { isLoading: isRequestLoading }] = useRequestOTPMutation();
  const [verifyOTP, { isLoading: isVerifyLoading }] = useVerifyOTPMutation();
  const [resetPassword, { isLoading: isResetLoading }] = useResetPasswordMutation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { openNotificationWithIcon } = NotificationHandler();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [email, setEmail] = useState<string>("");

  const [otpExpirationTime, setOtpExpirationTime] = useState(300); // 5 minutes in seconds
  const [otpExpired, setOtpExpired] = useState(false);


  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Timer for OTP expiration
  useEffect(() => {
    if (step === 2 && otpExpirationTime > 0) {
      const timer = setInterval(() => {
        setOtpExpirationTime((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (otpExpirationTime === 0) {
      setOtpExpired(true);
    }
  }, [otpExpirationTime, step]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const onRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    form.setFieldsValue({ recaptcha: token });
    form.validateFields(["recaptcha"]);
  };

  const onFinish = async (values: any) => {
    try {
      if (step === 1) {
        const result = await requestOTP({ email: values.email }).unwrap();
        setEmail(values.email);
        openNotificationWithIcon("success", "OTP sent to your email!");
        setStep(2);
      } else if (step === 2) {
        await verifyOTP({ email, otpCode: values.otpCode }).unwrap();
        openNotificationWithIcon("success", "OTP verified successfully!");
        setStep(3);
      } else if (step === 3) {
        await resetPassword({ email: email, password: values.password }).unwrap();
        openNotificationWithIcon("success", "Password reset successfully!");
        navigate("/login");
      }
    } catch (err: any) {
      openNotificationWithIcon("error", err?.data?.message || "An error occurred");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="max-w-[100%] h-screen flex justify-center mx-auto bg-no-repeat bg-center bg-cover bg-[url('/src/assets/img/background.jpg')]">
      <div
        className="md:w-[640px] w-[300px] sm:w-[550px] h-[500px] sm:h-[500px] md:h-[650px] p-8 relative bg-white container border rounded-3xl m-auto text-center flex flex-col justify-center"
        style={{
          boxShadow: "0 4px 44px rgba(0, 0, 0, 0.55)",
        }}
      >
        <img
          src={logo}
          className="w-[100px] sm:w-[150px] md:w-[200px] mb-3 sm:mb-6 mx-auto"
          alt="CDA Logo"
        />
        <h2 className="w-full text-[#2D796D] font-bold hidden md:block md:text-4xl mb-6">
          {step === 1 && "Enter your Email to reset the password"}
          {step === 2 && "Enter the OTP sent to your email"}
          {step === 3 && "Enter your new password"}
        </h2>

        <Form
          form={form}
          name="resetPassword"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-full max-w-[550px] mx-auto"
        >
          {step === 1 && (
            <Form.Item
              label="Email"
              name="email"
              className="mb-5"
              rules={forgotPasswordValidatorRules.email}
            >
              <Input />
            </Form.Item>
          )}

          {step === 2 && (
            <>
              <Form.Item
                label="OTP Code"
                name="otpCode"
                className="mb-5"
                rules={forgotPasswordValidatorRules.otpCode}
              >
                <Input />
              </Form.Item>

              <div className="text-center">
                {otpExpired ? (
                  <span className="text-red-500">OTP Expired</span>
                ) : (
                  <span className="text-red-500">OTP Expires in: {formatTime(otpExpirationTime)}</span>
                )}
              </div>


            </>
          )}

          {step === 3 && (
            <>
              <Form.Item
                label="New Password"
                name="password"
                className="mb-5"
                rules={forgotPasswordValidatorRules.password}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                className="mb-5"
                dependencies={['password']}
                rules={[
                  ...forgotPasswordValidatorRules.password,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}

          {step === 1 && (
            <Form.Item
              name="recaptcha"
              className="mb-2 w-full sm:mb-5 flex justify-center"
              rules={forgotPasswordValidatorRules.recaptcha}
            >
              <div className="recaptcha-container">
                <ReCAPTCHA
                  size={isTabletOrMobile ? "compact" : "normal"}
                  sitekey={SITE_KEY}
                  onChange={onRecaptchaChange}
                />
              </div>
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              className="bg-[#2D796D] mt-0 sm:mt-4 py-5 hover:!bg-[#2D796D] hover:border-[#2D796D] border-[#2D796D] rounded-lg w-full p-3 text-base sm:text-lg font-semibold sm:font-bold"
              type="primary"
              htmlType="submit"
              loading={isRequestLoading || isVerifyLoading || isResetLoading}
            >
              {step === 1 && "Submit"}
              {step === 2 && "Verify OTP"}
              {step === 3 && "Reset Password"}
            </Button>
          </Form.Item>
        </Form>

        {step === 2 && (
          <div className="text-center mt-4">
            <Button
              type="link"
              onClick={() => {
                setStep(1)
                setOtpExpirationTime(300);
                setOtpExpired(false)
              }}
              disabled={isVerifyLoading || isResetLoading || !otpExpired}
            >
              Request new OTP
            </Button>
          </div>
        )}

        <Form.Item>
          <Link
            to="/login"
            className="block text-lg sm:text-base text-center text-[#1890ff] hover:text-[#40a9ff]"
          >
           Already have an account? Please Login
          </Link>
        </Form.Item>

        <div className="absolute bottom-5 left-5 right-10">
          <p className="text-center mx-auto text-[#A0AEC0] text-sm sm:text-base font-sans">
          All rights reserved by{" "}
          <span className="text-[#38B2AC] cursor-pointer">CDA</span></p>
        </div>
      </div>
    </div>
  );
};

export default FogotPassword;
