import React, { useState, ReactNode, useEffect } from "react";
import { useChangeStatusRoleMutation, useDeleteRoleMutation, useGetRoleQuery } from "@services/api/index";
import styles from "./roleList.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { Switch, Modal, Alert, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface Role {
  id: string;
  name: string;
  status: string;
}

interface RoleListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode
}


const RolesList: React.FC<RoleListProps> = ({ editComp, viewComp }) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const { data: roles, error, isLoading } = useGetRoleQuery(pagination);
  const [deleteRole, { isLoading: deleteRoleIsloading, error: deleteRoleError }] = useDeleteRoleMutation();
  const [changeStatusRole, { isLoading: changeStatusRoleIsloading, error: changeStatusRoleError }] = useChangeStatusRoleMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Role | null>(null);

  const handleToggleChange = async (checked: boolean, record: Role) => {
    // Implement toggle functionality
    const result = await changeStatusRole(record.id).unwrap();
    console.log(`Toggled ${record.name} to ${checked ? "Active" : "Inactive"}`);
  };

  useEffect(() => {
    if (changeStatusRoleError) {
      notification.error({
        message: "Role Updated failed:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
    }
  }, [changeStatusRoleError])

  const showDeleteConfirm = (record: Role) => {
    setRecordToDelete(record);
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const handleDelete = async () => {
    try {
      if (recordToDelete) {
        const result = await deleteRole(recordToDelete.id).unwrap();
        notification.success({
          message: "Role Deleted successful:",
          placement: "topRight",
          duration: 3, // Duration in seconds
        });
        // Implement actual delete functionality here
      }
      setIsDeleteModalOpen(false);
      setRecordToDelete(null);
    } catch (err) {

    }

  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text: any, record: Role) => (
    //     <Switch
    //       disabled={changeStatusRoleIsloading}
    //       checked={record.status == 'active'}
    //       onChange={async (checked) => await handleToggleChange(checked, record)}
    //     />
    //   ),
    //   className: styles.tableCell,
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Role) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_ROLE) && viewComp && viewComp(record.id)}
           {hasPermission(permissions.EDIT_ROLE) && editComp && editComp(record.id)}
          {/* {hasPermission(permissions.DELETE_ROLE) && <DeleteOutlined
            className={styles.icon}
            onClick={() => showDeleteConfirm(record)}
          />} */}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={roles}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        loading={deleteRoleIsloading}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
        {(deleteRoleError) && (
          <Alert
            message={(deleteRoleError as any)?.data?.message}
            type="error"
            className="mb-4"
          />
        )}
      </Modal>
    </div>
  );
};

export default RolesList;
