import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import apiRoutes from "../apiRoutes";
import { setCredentials } from "@redux/authSlice";

export const authApi = (builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, string, "api">) => ({
    login: builder.mutation({
        query: (credentials) => ({
            url: apiRoutes.login,
            method: 'POST',
            body: credentials,
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
                dispatch(setCredentials({ token: (data as any).data.accessToken, user: (data as any).data.user, refreshToken: (data as any).data.refreshToken }));
            } catch (err) {
                console.error('Login failed:', err);
            }
        },
    }),

    requestOTP: builder.mutation({
        query: (body) => ({
            url: apiRoutes.requestOtp,
            method: 'POST',
            body,
        }),
    }),
    verifyOTP: builder.mutation({
        query: (body) => ({
            url: apiRoutes.verifyOtp,
            method: 'POST',
            body,
        }),
    }),
    resetPassword: builder.mutation({
        query: (body) => ({
            url: apiRoutes.resetPassword,
            method: 'POST',
            body,
        }),
    }),


    signup: builder.mutation({
        query: (body) => ({
            url: apiRoutes.signup,
            method: 'POST',
            body,
        }),
    }),
})