import React, { useEffect } from "react";
import { Form, Input, Button, Alert, Select, Upload, notification, InputNumber } from "antd";
import {
  useGetDivisionQuery,
  useGetZoneDropdownQuery,
  useGetSupervisorQuery,
  useCreateUserMutation,
  useGetZoneByIdQuery,
  useGetPermissionsQuery,
  useEditZoneMutation,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import { zoneValidator } from "@validators/zoneValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface EditZoneProps {
  onClose?: () => void;
  id: string;
}

const EditZone: React.FC<EditZoneProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editZone, { isLoading, error }] = useEditZoneMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();
  const {
    data: division,
    error: divisionError,
    isLoading: divisionIsLoading,
  } = useGetDivisionQuery();
  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
  } = useGetZoneDropdownQuery();
  const {
    data: supervisor,
    error: supervisorError,
    isLoading: supervisorIsLoading,
  } = useGetSupervisorQuery({ hierarchy: "" });
  const {
    data: zoneById,
    error: zoneByIdError,
    isLoading: zoneByIdIsLoading,
  } = useGetZoneByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      console.log("values: ", values);
      delete values.confirmPassword;

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("zoneID", values.zoneID);
      formData.append("area", values.area);
      values?.supervisorId &&
        formData.append("supervisorId", values.supervisorId);
      const payload = {
        name: values.name,
        supervisorId: values.supervisorId,
        zoneID: values.zoneID,
        area: values.area,
        id,
      }
      const result = await editZone({id,body:payload}).unwrap();
      console.log("Zone Updated successful:", result);
      openNotificationWithIcon("success",result?.message || "Zone updated successfully!");
      onClose && onClose();
    } catch (err: any) {
      console.error("Zone update failed:", err);
      // openNotificationWithIcon("error", err?.data?.message);

    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (zoneById) {
      form.setFieldsValue({
        name: zoneById?.data.name,
        supervisorId: zoneById?.data.supervisorId,
        zoneID: zoneById?.data.zoneID,
        area: zoneById.data.area,
      });
    }

    return () => form.resetFields();
  }, [zoneById, form]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AppFormWrapper
      form={form}
      error={error}
      initialValues={{ remember: true }}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item
        label="Zone Name"
        name="name"
        rules={zoneValidator.name}
      >
        <Input className="rounded-md p-1" disabled={isLoading}/>
      </Form.Item>

      <Form.Item
        label="Zone Supervisor"
        name="supervisorId"
        rules={zoneValidator.supervisorId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your zone supervisor"
          loading={supervisorIsLoading}
          disabled={supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {supervisor?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Zone ID"
        name="zoneID"
        rules={zoneValidator.zoneID}
      >
        <InputNumber className="rounded-md p-1" disabled={isLoading}/>
      </Form.Item>
      <Form.Item
        label="Zone Area / Sector"
        name="area"
        rules={zoneValidator.area}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your division"
          loading={supervisorIsLoading}
          disabled={supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {division?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditZone;
