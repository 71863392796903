import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Skeleton, Alert } from "antd";
import { useGetPermissionsQuery, useGetRoleByIdQuery } from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";

interface ViewRoleProps {
  onClose?: () => void;
  id: string;
}

const ViewRole: React.FC<ViewRoleProps> = ({ onClose, id }) => {
  const {
    data: role,
    error: roleError,
    isLoading: roleIsLoading,
  } = useGetRoleByIdQuery(id);

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const [moduleWisePermission, setModuleWisePermissions] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (permissions?.data && role?.data.permissionSlugs) {
      const moduleWisePermission: any = {};
      permissions?.data.forEach((module) => {
        const selectedPermission: any = [];
        role?.data?.permissionSlugs?.forEach((slug) => {
          const foundP = module.permissions.find(
            (val: any) => val.slug == slug
          );
          if (foundP) selectedPermission.push(foundP.id);
        });
        moduleWisePermission[module.module] = selectedPermission;
      });

      if (role) {
        setModuleWisePermissions(moduleWisePermission);
      }
    }

    return () => form.resetFields();
  }, [role, form, permissions]);

  return (
    <div className="max-w-3xl -mx-4 px-4  bg-white rounded-lg overflow-y-auto lg:max-h-[calc(100vh-180px)]">
      {permissionsIsLoading || roleIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            isLoading={roleIsLoading || permissionsIsLoading}
            error={roleError || permissionsError}
          >
            <Input
              disabled
              value={role?.data.name}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <div className="flex flex-col space-y-2">
              {permissions?.data.map((val: any) => (
                <div>
                  <h1>{val.module.replace("Task", "Survey")}</h1>
                  {moduleWisePermission &&
                    val.permissions.map((val2: any) => (
                      <Checkbox
                        disabled
                        checked={(moduleWisePermission[val.module] as any).find(
                          (val3: any) => val2.id == val3
                        )}
                      >
                        {val2.name.replace("Task", "Survey")}
                      </Checkbox>
                    ))}
                </div>
              ))}
            </div>
          </AppFormWrapper>
        </>
      )}
    </div>
  );
};

export default ViewRole;
