import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Divider,
  Skeleton,
  notification,
  CheckboxProps,
} from "antd";
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetPermissionsQuery,
  useGetRoleByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { roleValidator } from "@validators/roleValidator";
import NotificationHandler from "@components/notificationHandler";

const CheckboxGroup = Checkbox.Group;

interface EditRoleProps {
  onClose?: () => void;
  id: string;
}

const EditRole: React.FC<EditRoleProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editRole, { isLoading, error }] = useEditRoleMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: role,
    error: roleError,
    isLoading: roleIsLoading,
  } = useGetRoleByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      let permissionIds: string[] = [];

      console.log("Role Created successful:", values);
      for (const value of Object.values(values)) {
        if (value && Array.isArray(value)) {
          permissionIds = permissionIds.concat(value);
        }
      }
      const payload = { name: values.name, permissionIds, id };
      const result = await editRole({ id, body: payload }).unwrap();
      openNotificationWithIcon(
        "success",
        result?.message || "Role updated successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      console.error("Role update failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [checkedList, setCheckedList] = useState<any[]>([
    permissions?.data[0].permissions[0].id,
  ]);

  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    setCheckedList(
      e.target.checked
        ? (permissions as any)?.data.map((val: any) => val.id)
        : []
    );
  };

  const [form] = Form.useForm();

  useEffect(() => {
    const moduleWisePermission: any = {};
    if (permissions?.data && role?.data.permissionSlugs) {
      permissions?.data.forEach((module) => {
        const selectedPermission: any = [];
        role?.data?.permissionSlugs?.forEach((slug) => {
          const foundP = module.permissions.find(
            (val: any) => val.slug == slug
          );
          if (foundP) selectedPermission.push(foundP.id);
        });
        moduleWisePermission[module.module] = selectedPermission;
      });
    }
    if (role) {
      form.setFieldsValue({
        name: role?.data.name,
        ...(moduleWisePermission ? moduleWisePermission : {}),
      });
    }

    return () => form.resetFields();
  }, [role, form, permissions]);

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || roleError}
      isLoading={isLoading || permissionsIsLoading || roleIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item label="Name" name="name" rules={roleValidator.name}>
        <Input
          maxLength={30}
          disabled={isLoading}
          className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
      </Form.Item>

      {permissionsIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Divider />
          <div className="flex flex-col space-y-2">
            {permissions?.data.map((val) => (
              <Form.Item
                name={val.module}
                label={val.module.replace("Task", "Survey")}
              >
                <CheckboxGroup
                  disabled={isLoading}
                  // options={permissions?.data.map((val) => val.name)}
                  options={(val.permissions as any)?.map((val: any) => ({
                    label: val.name.replace("Task", "Survey"),
                    value: val.id,
                  }))}
                  value={(val?.permissions as any)?.map((val: any) => val.id)}
                  onChange={onChange}
                />
              </Form.Item>
            ))}
          </div>
        </>
      )}
    </AppFormWrapper>
  );
};

export default EditRole;
