import React, { useState, ReactNode, useEffect } from "react";
import {
  useDeleteViolationMutation,
  useGetViolationQuery,
  useChangeStatusViolationMutation,
} from "@services/api/index";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { Switch, Modal, Alert, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface Violation {
  id: string;
  name: string;
  status: string;
}

interface ViolationTypeListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
}

const SurveyTypeList: React.FC<ViolationTypeListProps> = () => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const {
    data: violation,
    error,
    isLoading,
  } = useGetViolationQuery(pagination);
  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // className: styles.tableCell,
    },
    {
      title: "Fine",
      dataIndex: "fine",
      key: "fine",
      // className: styles.tableCell,
    },

  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={violation}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
    </div>
  );
};

export default SurveyTypeList;
