import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import SocietyViolationList from "@components/SocietyViolations/societyViolationList";
// import EditSocietyViolation from "@components/SocietyViolations/editSocietyViolation";
// import ViewSocietyViolation from "@components/SocietyViolations/viewSocietyViolation";

const SocietyViolationType: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_SOCIETY_VIOLATION_MANAGEMENT) ? (
    <AppPageWrapper
      listComp={<SocietyViolationList />}
      // editComp={(id: string) => <EditSocietyViolation id={id} />}
      // viewComp={(id: string) => <ViewSocietyViolation id={id} />}
      moduleName="SocietyViolation"
      pagetTitle="Society Violation Management"
    />
  ) : null;
};

export default SocietyViolationType;
