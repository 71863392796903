import { Rule } from "antd/es/form";

interface SubAreaValidator {
  name: Rule[];
  // zone: Rule[]
  area: Rule[]
}

export const subAreaValidator: SubAreaValidator = {
  name: [
    { required: true, message: "Please input sub Area Name" },
  ],
  // zone: [
  //   { required: true, message: "Please select zone" }
  // ],
  area: [
    { required: true, message: "Please select Area" }
  ],
};
