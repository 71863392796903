import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  notification,
  Row,
  Col,
  DatePicker,
  Divider,
  Upload,
  Button,
  Card,
  Modal,
} from "antd";
import {
  DownloadOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileOutlined,
} from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import {
  useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
  useGetViolationDropdownQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaDropdownQuery,
  useGetPropertyTypeUsageDropdownQuery,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { Task } from "types";
import surveyFormField from "./surveyFormFileds";
import { CollapseProps, Collapse } from "antd";
import MapWithImages from "@components/googleMapWithImages";
import { taskValidator } from "@validators/taskValidator";
import NumericInput from "@components/numaricInput";
import { API_BASE_URL } from "@constants/index";
import "./editTask.module.css";

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string;
}

interface Attachment {
  path?: string;
  lat: any;
  long: any;
}

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  file: string;
  lat: any;
  long: any;
}

interface EditTaskProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

const EditTask: React.FC<EditTaskProps> = ({ onClose, id, isLoaded }) => {
  const [editTask, { isLoading, error }] = useEditTaskMutation();
  //  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  // const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [surveyImages, setSurveyImages] = useState<Attachment[]>([]);
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | null>(null);

  const [attachmentsBase64, setAttachmentsBase64] = useState<string | null>(
    null
  );
  const [allottedUse, setAllottedUse] = useState<string | undefined>();
  const [currentUse, setCurrentUse] = useState<string | undefined>();
  const [selectedViolation, setSelectedViolation] = useState<
    string[] | undefined
  >([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<ExtendedUploadFile[]>([]);

  // type FileType = RcFile;
  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  interface ExtendedUploadFile extends UploadFile {
    base64?: string;
  }

  const {
    data: violations,
    error: violationsError,
    isLoading: violationsIsLoading,
  } = useGetViolationDropdownQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  const {
    data: areas,
    error: areasError,
    isLoading: areasIsLoading,
  } = useGetAreaDropdownQuery((taskDataById?.data as any)?.zoneId);

  const [area, setArea] = useState(null);

  const handleAreaChange = (value: any) => {
    setArea(value);
  };

  const {
    data: subAreas,
    error: subAreasError,
    isLoading: subAreasIsLoading,
  } = useGetSubAreaDropdownQuery(area ?? (taskDataById?.data as any)?.areaId);

  const {
    data: propertyTypeUsages,
    error: propertyTypeUsagesError,
    isLoading: propertyTypeUsagesIsLoading,
  } = useGetPropertyTypeUsageDropdownQuery(
    (taskDataById?.data as any)?.propertyTypeId
  );

  const {
    data: actualPropertyTypeUsages,
    error: actualPropertyTypeUsagesError,
    isLoading: actualPropertyTypeUsagesIsLoading,
  } = useGetPropertyTypeUsageDropdownQuery(
    (taskDataById?.data as any)?.allottedPropertyTypeId
  );
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Urban Planning Data",
      children: (
        <>
          {surveyFormField
            .filter((val: any) => val.section == "Urban Planning Data")
            .map((val: any) =>
              val.type == "date" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      type: "date",
                      message: `${val.fieldName} must be in number`,
                    },
                  ]}
                >
                  <DatePicker
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : val.type == "number" ? (
                // <Form.Item label={val.fieldName} name={val.key}>
                //   <NumericInput
                //     placeholder={`Please enter a number`}
                //     className="rounded-md p-1 w-full"
                //     disabled={isLoading}
                //   />
                // </Form.Item>
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              ) : val.type == "enum" ? (
                val.key == "areaId" ? (
                  <Form.Item label={val.fieldName} name={val.key}>
                    <Select
                      showSearch
                      placeholder={`Select ${val.fieldName}`}
                      optionFilterProp="children"
                      className="rounded-md p-1 h-10 w-full"
                      loading={areasIsLoading}
                      disabled={areasIsLoading || isLoading}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ?? false
                      }
                      onChange={handleAreaChange}
                      options={areas?.data.map((val) => ({
                        value: val.id,
                        label: val.name,
                      }))}
                    />
                  </Form.Item>
                ) : val.key == "subAreaId" ? (
                  <Form.Item label={val.fieldName} name={val.key}>
                    <Select
                      showSearch
                      placeholder={`Select ${val.fieldName}`}
                      optionFilterProp="children"
                      className="rounded-md p-1 h-10 w-full"
                      loading={subAreasIsLoading}
                      disabled={subAreasIsLoading || isLoading}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ?? false
                      }
                      options={subAreas?.data.map((val) => ({
                        value: val.id,
                        label: val.name,
                      }))}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item label={val.fieldName} name={val.key}>
                    <Select
                      placeholder={`Select ${val.fieldName}`}
                      className="rounded-md p-1 h-10 w-full"
                      options={val.values.map((val: string) => ({
                        value: val,
                        label: val,
                      }))}
                      disabled={isLoading}
                    />
                  </Form.Item>
                )
              ) : (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Input
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    maxLength={30}
                  />
                </Form.Item>
              )
            )}
        </>
      ),
    },
    {
      key: "2",
      label: "Estate Management Data",
      children: (
        <>
          {surveyFormField
            .filter((val: any) => val.section == "Estate Management Data")
            .map((val: any) =>
              val.type == "date" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      type: "date",
                      message: `${val.fieldName} must be in number`,
                    },
                  ]}
                >
                  <DatePicker
                    className="rounded-md p-1 w-full"
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : val.type == "number" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              ) : val.type == "enum" ? (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Select
                    placeholder={`Select ${val.fieldName}`}
                    className="rounded-md p-1 h-10 w-full"
                    options={val.values.map((val: string) => ({
                      value: val,
                      label: val,
                    }))}
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : val.type === "cnic" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      pattern: /^[0-9]{13}$/,
                      message: `${val.fieldName} must be in number`,
                    },
                  ]}
                >
                  <Input
                    placeholder={`Please enter a number`}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    maxLength={13}
                  />
                </Form.Item>
              ) : (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Input
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    maxLength={30}
                  />
                </Form.Item>
              )
            )}
        </>
      ),
    },
    {
      key: "3",
      label: "Building Control Section",
      children: (
        <>
          {surveyFormField
            .filter((val: any) => val.section == "Building Control Section")
            .map((val: any) =>
              val.type == "date" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      type: "date",
                      message: `${val.fieldName} must be in number`,
                    },
                  ]}
                >
                  <DatePicker
                    className="rounded-md p-1 w-full"
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : val.type == "number" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              ) : val.type == "enum" ? (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Select
                    placeholder={`Select ${val.fieldName}`}
                    className="rounded-md p-1 h-10 w-full"
                    options={val.values.map((val: string) => ({
                      value: val,
                      label: val,
                    }))}
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Input
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    maxLength={30}
                  />
                </Form.Item>
              )
            )}
        </>
      ),
    },
    {
      key: "4",
      label: "Revenue Data (PKR)",
      children: (
        <>
          {surveyFormField
            .filter((val: any) => val.section == "Revenue Data (PKR)")
            .map((val: any) =>
              val.type == "date" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      type: "date",
                      message: `${val.fieldName} must be in number`,
                    },
                  ]}
                >
                  <DatePicker
                    className="rounded-md p-1 w-full"
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : val.type == "number" ? (
                <Form.Item
                  label={val.fieldName}
                  name={val.key}
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              ) : val.type == "enum" ? (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Select
                    placeholder={`Select ${val.fieldName}`}
                    className="rounded-md p-1 h-10 w-full"
                    options={val.values.map((val: string) => ({
                      value: val,
                      label: val,
                    }))}
                    disabled={isLoading}
                  />
                </Form.Item>
              ) : (
                <Form.Item label={val.fieldName} name={val.key}>
                  <Input
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    maxLength={30}
                  />
                </Form.Item>
              )
            )}
        </>
      ),
    },
  ];
  const handleAllotedUseChange = (value: string) => {
    const filteredAllotteduse =
      actualPropertyTypeUsages &&
      actualPropertyTypeUsages?.data?.find((usage) => {
        return usage.id === value;
      });
    setAllottedUse(filteredAllotteduse?.name);
  };

  const handleCurrentUseChange = (value: string) => {
    const filteredCurrentuse =
      propertyTypeUsages &&
      propertyTypeUsages?.data?.find((usage) => {
        return usage.id === value;
      });
    setCurrentUse(filteredCurrentuse?.name);
  };

  const handleViolationChange = (value: string[]) => {
    setSelectedViolation(
      violations?.data
        .filter((val) => value.includes(val.id))
        .map((val) => val.name)
    );
  };

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      //form.setFieldsValue({ "violationIds": ["42171a14-2cff-4704-a58a-4744ea87353a"], "areaId": "0285cfb3-949a-4d6c-8cb1-9e368cd66d3a", "currentUsageId": "64f5e1b6-556d-11ef-8d8a-000c291c40db", "actualUsageId": "64f66af4-556d-11ef-8d8a-000c291c40db",  "inspectorRemarks": "21231", "fileNoEM": "qweqw", "plotNo": "qweqwe", "streetRoad": "qweqwe", "subSector": "qwewqe", "sectorScheme": "qweqwe", "cornerStatus": "Non-Corner", "classificationOfPlot": "qweqwe", "size": 3123, "dimension": "asdasd", "subDivision": "asdasd", "amalgamation": "asdasd", "certificateOfCompletion": "2024-08-19T19:00:00.000Z", "constructionStatus": "asdasd", "typeOfAllotment": "asdasd", "dateOfFirstAllotment": moment("2024-08-20T19:00:00.000Z"), "firstAllotteeName": "asdasd", "firstAllotteeCnic": "asdasd", "dateOfLastTransfer": moment("2024-08-27T19:00:00.000Z"), "presentOwnerCnic": "asdasd", "presentOwnerName": "asdasd", "mortgageStatus": "asdasd", "bankName": "asdasd", "leaseStatus": "asdasd", "leaseEndingDate": moment("2024-08-27T19:00:00.000Z"), "powerOfAttorney": "asdasd", "conveyanceDeedStatus": "asdasd", "litigationStatus": "asdasd", "otherInformation": "asdads", "buildingPlanApprovalDate": moment("2024-08-27T19:00:00.000Z"), "approvedCoveredAreaSft": 23432, "noOfStoreys": 12312, "noOfUnits": 123123, "completionCertificateDate": moment("2024-08-27T19:00:00.000Z"), "completionCoveredAreaSft": 23423, "conditionOfBuilding": "asdads", "propertyTaxAnnual": 13123, "propertyTaxPaidUp": 1231231, "propertyTaxArrears": 23423432, "waterCharges": 12312, "conservancyCharges": 12312, "quarterlyDemandAB": 123123, "arrearsPKRA": 123123, "paymentsAIB": "123123" ,dueDate: moment(task.dueDate)});

      form.setFieldsValue({
        ...task,
        ...((task as any)?.certificateOfCompletion
          ? {
              certificateOfCompletion: moment(
                (task as any)?.certificateOfCompletion
              ),
            }
          : {}),
        ...((task as any)?.dateOfFirstAllotment
          ? {
              dateOfFirstAllotment: moment((task as any)?.dateOfFirstAllotment),
            }
          : {}),
        ...((task as any)?.dateOfLastTransfer
          ? { dateOfLastTransfer: moment((task as any)?.dateOfLastTransfer) }
          : {}),
        ...((task as any)?.leaseEndingDate
          ? { leaseEndingDate: moment((task as any)?.leaseEndingDate) }
          : {}),
        ...((task as any)?.buildingPlanApprovalDate
          ? {
              buildingPlanApprovalDate: moment(
                (task as any)?.buildingPlanApprovalDate
              ),
            }
          : {}),
        ...((task as any)?.completionCertificateDate
          ? {
              completionCertificateDate: moment(
                (task as any)?.completionCertificateDate
              ),
            }
          : {}),

        dueDate: moment(task.dueDate),
      });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
    }
  }, [taskDataById, form]);

  useEffect(() => {
    if (violations && taskDataById)
      setSelectedViolation(
        violations?.data
          .filter((val) =>
            (taskDataById?.data as any)?.violationIds?.includes(val.id)
          )
          .map((val) => val.name)
      );
  }, [violations, taskDataById]);

  // const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
  //   setPolygonData(newPolygons ? newPolygons : polygonData);
  // };

  // const handleUploadChange = (info: any) => {
  //   setFileList(info.fileList);
  // };

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof File)) {
        return reject(new Error("Invalid file input"));
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        let base64String = reader.result as string;
        const sanitizedFileName = file.name
          .replace(/\s+/g, "_")
          .replace(/[^\w.-]/g, "");
        const fileExtension = sanitizedFileName.split(".").pop();
        const isSpecialDoc =
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/pdf";

        if (isSpecialDoc && fileExtension) {
          const base64Data = base64String.split(",")[1];
          base64String = `data:application/${fileExtension};base64,${base64Data}`;
        }

        resolve(base64String);
      };
      reader.onerror = (error) =>
        reject(new Error("File reading failed: " + error));
    });
  };

  const handleUploadChange = async ({ fileList }: any) => {
    console.log("fileList", fileList);
    // setFileList(fileList);
    // if (fileList.length > 0) {
    //     const file = fileList[0].originFileObj;
    //     const base64 = await getBase64(file);
    //     setAttachmentsBase64(base64);
    // } else {
    //   setAttachmentsBase64(null);
    // }
  };

  const handlePreview = async (file: ExtendedUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await convertFileToBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };
  const getFileIcon = (file: any) => {
    const isImage = /\.(png|jpe?g|gif)$/i.test(file.url || file.name);
    const isDocument = /\.(pdf|docx?|xlsx?)$/i.test(file.url || file.name);

    if (isImage) {
      return (
        <EyeOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => handlePreview(file)}
        />
      );
    } else if (isDocument) {
      return (
        <DownloadOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else {
      return <FileOutlined style={{ fontSize: "32px", color: "#1890ff" }} />;
    }
  };

  const customItemRender = (originNode: any, file: any) => {
    const isNewUpload = /\.(png|jpe?g|gif|pdf|docx?|xlsx?)$/i.test(file.name);

    return (
      <div>
        <div
          style={{
            width: "100px",
            height: "90px",
          }}
        >
          {originNode}
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            marginTop: "8px",
            paddingTop: "8px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {getFileIcon(file)}
          {isNewUpload && (
            <DeleteOutlined
              style={{ fontSize: "16px", color: "red" }}
              onClick={() => handleRemove(file)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderUploadItem1 = (
    originNode: React.ReactElement,
    file: UploadFile
  ) => {
    const isUploadedFile = !!file.url;
    const isDocument = /\.(pdf|doc|docx|xls|xlsx)$/i.test(file.name);

    return (
      <Card
        style={{ width: 100, margin: "0 8px 8px 0", display: "inline-block" }}
        cover={
          <img
            alt="example"
            src={file.url || (file.preview as string)}
            style={{ height: 100, objectFit: "cover" }}
          />
        }
        actions={[
          isUploadedFile && isDocument ? (
            <DownloadOutlined onClick={() => window.open(file.url, "_blank")} />
          ) : (
            <EyeOutlined onClick={() => window.open(file.thumbUrl, "_blank")} />
          ),
          isUploadedFile && (
            <DeleteOutlined onClick={() => handleRemove(file)} />
          ),
        ]}
      >
        <Card.Meta title={file.name} />
      </Card>
    );
  };

  const renderUploadItem = (
    originNode: React.ReactElement,
    file: UploadFile
  ) => {
    const showRemoveIcon = !file.url;
    const showDownloadIcon =
      !!file.url && /\.(pdf|doc|docx|xls|xlsx)$/i.test(file.url);

    return (
      <span>
        {originNode}
        {showRemoveIcon && <button>Remove</button>}
        {showDownloadIcon && (
          <DownloadOutlined
            style={{ fontSize: "16px", color: "#1890ff", marginLeft: 8 }}
          />
        )}
      </span>
    );
  };

  const onFinish = async (values: any) => {
    try {
      for (const key of Object.keys(values)) {
        if (!values[key] || key == "propertyType") values[key] = undefined;
      }

      const attachments = await Promise.all(
        fileList.map(async (file) => {
          if (!file.url) {
            const base64File = await convertFileToBase64(file.originFileObj);
            return {
              file: base64File,
              lat: undefined,
              long: undefined,
            };
          }
          return null;
        })
      );

      const filteredAttachments = attachments.filter(
        (attachment) => attachment !== null
      );

      await editTask({
        id,
        body: {
          ...values,
          allottedUsageId: undefined,
          actualUsageId: values.allottedUsageId,
          dueDate: values.dueDate.format("YYYY-MM-DD"),
          status: "proceeded",
          attachments: filteredAttachments ? filteredAttachments : undefined,
        },
      }).unwrap();

      notification.success({
        message: "Survey processed successfully",
        placement: "topRight",
        duration: 3,
      });

      onClose && onClose();
    } catch (err) {
      console.error("Survey processed failed:", err);
      // notification.error({
      //   message: "Task update failed",
      //   description: err.message,
      //   placement: "topRight",
      //   duration: 3,
      // });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const center =
  //   polygonData && polygonData.length > 0
  //     ? polygonData[0]
  //     : {
  //         lat: 33.618091430310066,
  //         lng: 73.16781374438858,
  //       };

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
      if (task && task.attachments) {
        setSurveyImages((task as any).attachments || []);

        if (task && task.attachments) {
          const initialFileList = task.attachments.map(
            (attachment: any, index: number) => ({
              uid: `-1-${index}`,
              name: attachment.name || `Attachment-${index + 1}`,
              // status: 'done',
              url: attachment.path ? `${API_BASE_URL}${attachment.path}` : "",
            })
          );
          setFileList(initialFileList);
        }
      }

      if (task.lat && task.long) {
        setMapCenter({
          lat: parseFloat(task.lat),
          lng: parseFloat(task.long),
        });
      }
    }
  }, [taskDataById, form]);

  const center = {
    lat: 33.6938,
    lng: 73.0652,
  };

  const imageMarkers: ImageMarker[] = surveyImages.map((attachment) => ({
    coordinates: {
      lat: parseFloat(attachment.lat),
      lng: parseFloat(attachment.long),
    },
    images: attachment.path ? `${API_BASE_URL}${attachment.path}` : "", // Wrapping the path in an array
  }));

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <AppFormWrapper
      error={
        error ||
        violationsError ||
        areasError ||
        subAreasError ||
        taskDataError ||
        propertyTypeUsagesError ||
        actualPropertyTypeUsagesError
      }
      initialValues={{ remember: true }}
      isLoading={isLoading || taskDataIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Proceed"
      form={form}
    >
      <Row gutter={[16, 16]} className="h-full">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
          <MapWithImages
            center={mapCenter || center}
            imageMarkers={imageMarkers}
            isLoaded={true}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Violations" name="violationIds">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select violations"
              optionFilterProp="children"
              className="rounded-md p-1 w-full"
              loading={violationsIsLoading}
              disabled={isLoading}
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }
              onChange={handleViolationChange}
            >
              {/* <Option value="setback">Setback Violation</Option>
              <Option value="far">FAR Violation</Option> */}
              {violations?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Alloted Use" name="allottedUsageId">
            <Select
              showSearch
              placeholder="Select Alloted Use"
              optionFilterProp="children"
              className="rounded-md p-1 h-10 w-full"
              loading={actualPropertyTypeUsagesIsLoading}
              disabled={actualPropertyTypeUsagesIsLoading || isLoading}
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }
              onChange={handleAllotedUseChange}
            >
              {actualPropertyTypeUsages?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
              {/* <Option key={"other"} value="other">
                Other
              </Option> */}
            </Select>
          </Form.Item>
          {allottedUse === "Other" ||
          (taskDataById?.data as any)?.allottedUsageOther ? (
            <Form.Item label="Other Allotted Use" name="allottedUsageOther">
              <Input
                disabled={isLoading}
                className="rounded-md p-2 w-full"
                placeholder="Enter Other Allotted Use"
                maxLength={30}
              />
            </Form.Item>
          ) : null}

          <Form.Item label="Current Use" name="currentUsageId">
            <Select
              showSearch
              placeholder="Select Current use"
              optionFilterProp="children"
              className="rounded-md p-1 h-10 w-full"
              loading={propertyTypeUsagesIsLoading}
              disabled={propertyTypeUsagesIsLoading || isLoading}
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }
              onChange={handleCurrentUseChange}
            >
              {propertyTypeUsages?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
              {/* <Option key={"other"} value="other">
                Other
              </Option> */}
            </Select>
          </Form.Item>

          {currentUse === "Other" ||
          (taskDataById?.data as any)?.allottedUsageOther ? (
            <Form.Item label="Other Current Use" name="currenUsageOther">
              <Input
                disabled={isLoading}
                className="rounded-md p-2 w-full"
                placeholder="Enter Other Current Use"
                maxLength={30}
              />
            </Form.Item>
          ) : null}

          {/* <Form.Item label="Due Date" name="dueDate">
            <DatePicker
              className="rounded-md p-1 h-10 w-full"
              placeholder="Select Due Date"
            />
          </Form.Item> */}
          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={taskValidator.dueDate}
          >
            <DatePicker
              className="rounded-md p-1 w-full"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              disabled={isLoading}
            />
          </Form.Item>

          <Form.Item label="Inspector Remarks" name="inspectorRemarks">
            <Input
              disabled={isLoading}
              className="rounded-md p-2 w-full"
              placeholder="Inspector Remarks"
              maxLength={30}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Property Type" name="propertyType">
            <Input
              className="rounded-md p-2 w-full"
              placeholder="Enter Other Allotted Use"
              disabled={isLoading}
              maxLength={30}
            />
          </Form.Item>
          {selectedViolation && selectedViolation.includes("Set Back") && (
            <Form.Item label="Setback Violation (Feet)" name="violationfeet" rules={[
              {
                pattern: /^[0-9]*$/,
                message: "Only numeric values are allowed.",
              },
            ]}
          >
            <Input
              maxLength={30}
              className="rounded-md p-1 w-full"
              disabled={isLoading}
              placeholder="Enter numeric input"
            />
            </Form.Item>
          )}

          {selectedViolation &&
            selectedViolation.includes("F.A.R. Violation") && (
              <>
                <Form.Item
                  label="Approved Covered Area"
                  name="violationApprovedCoveredArea"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Existing Covered Area"
                  name="violationExisitingCoveredArea"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item label="Access Area" name="violationAccessArea" rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Approved Number of Floors"
                  name="violationApprovedNumberOfFloor"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Built Number of Floors"
                  name="violationBuiltNumberOfFloors"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    disabled={isLoading}
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              </>
            )}
        </Col>
      </Row>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={() => false}
            showUploadList={{
              showPreviewIcon: false, // Disable default preview icon
              showDownloadIcon: false, // Disable default download icon
              showRemoveIcon: false,
            }}
            // itemRender={customItemRender}
            itemRender={(originNode, file) =>
              customItemRender(originNode, file)
            }
            accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            {fileList.length >= 200 ? null : uploadButton}
          </Upload>

          <Modal open={previewOpen} onCancel={handleCancel} footer={null}>
            <img
              alt="attachment"
              style={{ width: "100%" }}
              src={previewImage}
            />
          </Modal>
        </Row>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {items.map((item, index) => (
            <Col key={index} xs={24} sm={24} md={6}>
              <Collapse accordion items={[item]} />
            </Col>
          ))}
        </Row>
      </Col>
    </AppFormWrapper>
  );
};

export default EditTask;
