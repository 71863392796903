import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

const NotificationHandler = () => {
  const openNotificationWithIcon = (type: NotificationType, message: string, description?: string) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
      duration: 2,
    });
  };

  return { openNotificationWithIcon };
};

export default NotificationHandler;
