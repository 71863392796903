import { Rule } from "antd/es/form";

interface ViolationValidator {
    name: Rule[];
    fine: Rule[];
}

export const violationValidator: ViolationValidator = {
    name: [
    { required: true, message: "Please input violation name" },
  ],
  fine: [
    { required: true, message: "Please input violation fine" },
  ],
};
