import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { PaginationPayload, ResponseListPaginate, User, Response, ResponseList, SocietyUser } from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const userApi = (builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, string, "api">) => ({
    getUsers: builder.query<ResponseListPaginate<User>, PaginationPayload>({
        query: ({ page, limit, searchText }: any) => {
            const params: any = { page, limit };
            if (searchText) {
              params.searchText = searchText;
            }
            return {
              url: apiRoutes.getUsers,
              params,
            };
          },
          providesTags: [apiTags.user]
    }),
    getSocietyUsers: builder.query<ResponseListPaginate<SocietyUser>, PaginationPayload>({
        query: ({ page, limit, searchText }: any) => {
            const params: any = { page, limit };
            if (searchText) {
              params.searchText = searchText;
            }
            return {
              url: apiRoutes.getSocietyUsers,
              params,
            };
          },
          providesTags: [apiTags.user]
    }),
    createUser: builder.mutation<Response<User>, User>({
        query: (body) => ({
            url: apiRoutes.createUser,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        }),
        invalidatesTags: [apiTags.user]
    }),
    createSocietyUser: builder.mutation<Response<SocietyUser>, SocietyUser>({
        query: (body) => ({
            url: apiRoutes.createSocietyUser,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        }),
        invalidatesTags: [apiTags.user]
    }),
    getUserById: builder.query<Response<User>, string>({
        query: (id: string) => apiRoutes.getUserById(id),
        providesTags: [apiTags.user]
    }),
    getSocietyUserById: builder.query<Response<SocietyUser>, string>({
        query: (id: string) => apiRoutes.getSocietyUserById(id),
        providesTags: [apiTags.user]
    }),
    getUserDropdown: builder.query<ResponseList<User>, void>({
        query: () => apiRoutes.getUserDropdown,
    }),
    getSocietyUserDropdown: builder.query<ResponseList<SocietyUser>, void>({
        query: () => apiRoutes.getSocietyUserDropdown,
    }),
    editUser: builder.mutation<Response<User>, { id: string, body: User }>({
        query: ({ id, body }) => ({
            url: apiRoutes.editUser(id),
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        }),
        invalidatesTags: [apiTags.user]
    }),
    editSocietyUser: builder.mutation<Response<SocietyUser>, { id: string, body: SocietyUser }>({
        query: ({ id, body }) => ({
            url: apiRoutes.editSocietyUser(id),
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        }),
        invalidatesTags: [apiTags.user]
    }),
    changeStatusUser: builder.mutation({
        query: (id: string) => ({
            url: apiRoutes.changeStatusUser(id),
            method: "PATCH",
        }),
        invalidatesTags: [apiTags.user]
    }),
});