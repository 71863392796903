import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import defaultImg from "../../../assets/img/defaultImage.jpeg";
import { API_BASE_URL } from "../../../constants/index";
import {
  Input,
  Button,
  Alert,
  Select,
  Upload,
  notification,
  Form,
  Skeleton,
  Image,
} from "antd";
import {
  useGetDesignationsQuery,
  useGetDepartmentQuery,
  useGetDivisionQuery,
  useGetZoneDropdownQuery,
  useGetSupervisorQuery,
  useCreateUserMutation,
  useGetRoleDropdownQuery,
  useGetUserByIdQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaDropdownQuery,
  useGetSubZoneDropdownQuery,
} from "@services/api";
import AppFormWrapper from "@components/appFormWrapper";
const { Option } = Select;

interface ViewUserProps {
  onClose?: () => void;
  id: string;
}

const ViewUser: React.FC<ViewUserProps> = ({ onClose, id }) => {
  const {
    data: userDataById,
    error: userDataError,
    isLoading: userDataIsLoading,
  } = useGetUserByIdQuery(id);
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>(""); // State for selected zoneId
  const [selectedSubZoneId, setSelectedSubZoneId] = useState<string | null>(""); // State for selected subZoneId
  const [selectedAreaId, setSelectedAreaId] = useState<string | null>(""); // State for selected subAreasIds
  const [hierarchy, setHierarchy] = useState<string | undefined>("");

  useEffect(() => {
    if (userDataById) setHierarchy(userDataById?.data?.hierarchy);
  }, [userDataById]);

  const {
    data: designations,
    error: designationsError,
    isLoading: designationsIsLoading,
  } = useGetDesignationsQuery();
  const {
    data: department,
    error: departmentError,
    isLoading: departmentIsLoading,
  } = useGetDepartmentQuery();
  const {
    data: division,
    error: divisionError,
    isLoading: divisionIsLoading,
  } = useGetDivisionQuery();
  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
  } = useGetZoneDropdownQuery();

  const {
    data: subZone,
    error: subZoneError,
    isLoading: subZoneIsLoading,
    refetch: refetchSubZone,
  } = useGetSubZoneDropdownQuery({ zoneId: selectedZoneId as string });
  const {
    data: supervisor,
    error: supervisorError,
    isLoading: supervisorIsLoading,
  } = useGetSupervisorQuery({ hierarchy: hierarchy as string });
  const {
    data: role,
    error: roleError,
    isLoading: roleIsLoading,
  } = useGetRoleDropdownQuery({ hierarchy: hierarchy as string });
  const {
    data: area,
    isLoading: areaIsLoading,
    error: areaError,
    refetch: refetchAreas,
    isUninitialized,
  } = useGetAreaDropdownQuery({ subZoneId: selectedZoneId as string });
  const {
    data: subAreas,
    error: subAreasError,
    isLoading: subAreasIsLoading,
    refetch: refetchSubAreas,
  } = useGetSubAreaDropdownQuery({ areaId: selectedAreaId as string });
  // const onFinish = async (values: any) => {
  //   try {
  //     // Show success notification
  //     notification.success({
  //       message: "User Created successful:",
  //       placement: "topRight",
  //       duration: 3, // Duration in seconds
  //     });
  //     onClose && onClose();
  //   } catch (err) {
  //     console.error("Signup failed:", err);
  //   }
  // };

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log("Failed:", errorInfo);
  // };
  return (
    <div className="max-w-3xl -mx-4 px-4  bg-white rounded-lg overflow-y-auto lg:max-h-[calc(100vh-180px)]">
      {designationsIsLoading ||
      departmentIsLoading ||
      divisionIsLoading ||
      zoneIsLoading ||
      subZoneIsLoading ||
      supervisorIsLoading ||
      roleIsLoading ||
      areaIsLoading ||
      subAreasIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            isLoading={
              designationsIsLoading ||
              departmentIsLoading ||
              divisionIsLoading ||
              zoneIsLoading ||
              subZoneIsLoading ||
              supervisorIsLoading ||
              roleIsLoading ||
              areaIsLoading ||
              subAreasIsLoading
            }
            error={
              designationsError ||
              departmentError ||
              divisionError ||
              zoneError ||
              subZoneError ||
              supervisorError ||
              roleError ||
              areaError ||
              subAreasError
            }
          >
            <h1>Name</h1>
            <Input
              disabled
              value={userDataById?.data.name}
              placeholder="Enter Your Name" 
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Email</h1>
            <Input
              disabled
              value={userDataById?.data.email}
              placeholder="Enter Your Email" 
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>CNIC</h1>

            <Input
              disabled
              value={userDataById?.data.CNIC}
              placeholder="Enter Your CNIC in digits" 
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Phone</h1>
            <Input
              disabled
              value={userDataById?.data.phone}
              placeholder="Enter Your Phone number in digits" 
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Picture</h1>
            <div className="flex flex-col">
              <Image
                width={60}
                src={
                  userDataById?.data?.picture !== ""
                    ? API_BASE_URL + userDataById?.data?.picture
                    : defaultImg
                }
              />
              <Upload
                disabled
                // fileList={[{ uid: '-1', name: 'image.png', status: 'done', url: userDataById?.data?.picture !== "" ? API_BASE_URL + userDataById?.data?.picture : defaultImg }]}
                listType="picture"
              >
                {/* <Button
                  disabled
                  icon={<UploadOutlined />}
                  className="custom-upload-button p-1 mt-3"
                >
                  Click to Upload
                </Button> */}
              </Upload>
            </div>
            <h1>Designation</h1>
            <Select
              showSearch
              optionFilterProp="children"
              disabled
              value={userDataById?.data.designationId}
              loading={designationsIsLoading}
              placeholder="Select Department"
              className="rounded-md width-100"
            >
              {designations?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Role</h1>
            <Select
              showSearch
              optionFilterProp="children"
              disabled
              value={userDataById?.data.roleId}
              loading={roleIsLoading}
              placeholder="Select Department"
              className="rounded-md width-100"
            >
              {role?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Supervisor</h1>
            <Select
              showSearch
              optionFilterProp="children"
              disabled
              value={userDataById?.data.supervisorId}
              loading={supervisorIsLoading}
              placeholder="Select Department"
              className="rounded-md width-100"
            >
              {supervisor?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Directorate</h1>
            <Select
              showSearch
              optionFilterProp="children"
              disabled
              value={userDataById?.data.departmentId}
              loading={departmentIsLoading}
              placeholder="Select Directorate"
              className="rounded-md width-100"
            >
              {department?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            {/* <h1>Division</h1>
                <Select
                    showSearch
                    optionFilterProp="children"
                    disabled
                    value={userDataById?.data.divisionId}
                    loading={divisionIsLoading}
                    placeholder="Select Department"
                    className="rounded-md width-100"
                >
                    {division?.data?.map((value) => (
                        <Option key={value.id} value={value.id}>
                            {value.name}
                        </Option>
                    ))}
                </Select> */}
            <h1>Zone</h1>
            <Select
              showSearch
              optionFilterProp="children"
              disabled
              value={userDataById?.data.zoneId}
              loading={zoneIsLoading}
              placeholder="Select Department"
              className="rounded-md width-100"
            >
              {zone?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Sub Zone</h1>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Select your sub zone"
              loading={subZoneIsLoading}
              disabled
              value={userDataById?.data.subZoneId}
              className="rounded-md width-100"
            >
              {subZone?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Area</h1>
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="children"
              disabled
              value={userDataById?.data.areasIds}
              loading={areaIsLoading}
              placeholder="Select Area"
              className="rounded-md width-100"
            >
              {area?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Sub Area</h1>
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              allowClear
              value={userDataById?.data.subAreasIds}
              className="rounded-md width-100"
              placeholder="Please select sub area"
              loading={subAreasIsLoading}
              disabled
            >
              {subAreas?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </AppFormWrapper>
        </>
      )}
    </div>
  );
};

export default ViewUser;
