import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import SocietySurveyList from "@components/societySurvey/societySurveyList";
import ViewSocietySurvey from "@components/societySurvey/viewSocietySurvey";

const SocietySurvey: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_SOCIETY_SURVEY_MANAGEMENT) ? (
    <AppPageWrapper
      listComp={<SocietySurveyList />}
      viewComp={(id: string, isLoaded: boolean) => (
        <ViewSocietySurvey id={id} isLoaded={isLoaded} />
      )}
      moduleName="Survey"
      pagetTitle="Society Survey Management"
      width={"1600px"}
    />
  ) : null;
};

export default SocietySurvey;
