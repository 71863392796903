import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseListPaginate,
  Property,
  ResponseList,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const propertyTypeApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getProperty: builder.query<ResponseListPaginate<Property>, PaginationPayload>(
    {
      query: ({ page, limit }: any) => ({
        url: apiRoutes.getPropertyType,
        params: { page, limit },
      }),
      providesTags: [apiTags.property],
    }
  ),
  createProperty: builder.mutation<Response<Property>, Property>({
    query: (body) => ({
      url: apiRoutes.createPropertyType,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.property],
  }),

  editProperty: builder.mutation<
    Response<Property>,
    { id: string; body: Property }
  >({
    query: ({ id, body }) => ({
      url: apiRoutes.editPropertyType(id),
      method: "PATCH",
      body,
    }),
    invalidatesTags: [apiTags.property],
  }),
  deleteProperty: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.deletePropertyType(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.property],
  }),
  changeStatusProperty: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.changeStatusProperty(id),
      method: "PATCH",
    }),
    invalidatesTags: [apiTags.property],
  }),
  getPropertyTypeById: builder.query<Response<Property>, string>({
    query: (id: string) => apiRoutes.getPropertyTypeById(id),
    providesTags: [apiTags.property],
  }),
  getPropertyTypeUsageDropdown: builder.query<
    ResponseList<any>,
    { propertyTypeId: string }
  >({
    query: ({ propertyTypeId }) => ({
      url: apiRoutes.getPropertyTypeUsageDropdown(
        propertyTypeId ? `?propertyTypeId=${propertyTypeId}` : ""
      ),
      method: "GET",
    }),
  }),
});
