import React, { useMemo } from "react";
import { Alert, Spin, Table, Image, Flex, Button } from "antd";
import styles from "./List.module.css";
import { PaginationPayload, ResponseListPaginate } from "types";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import { LoadingOutlined } from "@ant-design/icons";

interface ListProps {
  data: ResponseListPaginate<any> | undefined;
  columns: any[];
  isLoading: boolean;
  error: any;
  setPagination: (val: PaginationPayload) => void;
  pagination: PaginationPayload;
  rowClassName?: any;
}

const List: React.FC<ListProps> = ({
  data,
  columns,
  isLoading,
  error,
  setPagination,
  pagination,
  rowClassName,
}: ListProps) => {
  const handlePageChange = (page: number, pageSize: number): void => {
    setPagination({ page, limit: pageSize });
  };

  const { hasPermission } = usePermissions();

  const columnsNew = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        className: styles.tableCell,
        render: (id: string, record: any, index: number) =>
          index + 1 + (pagination.page - 1) * pagination.limit,
      },
      //...columns.map((col) => ({ ...col, align: "center" })),
      ...columns
    ],
    [columns]
  );

  let render = (
    <div className={styles.card}>
      <Table
        dataSource={data?.data?.data || []}
        columns={
          // !hasPermission(permissions.VIEW_ZONE) &&
          //     !hasPermission(permissions.EDIT_ZONE) &&
          //     !hasPermission(permissions.DELETE_ZONE) ?
          //     columnsNew.filter((val) => val.title != 'Actions' && val.title != 'Status') :
          //     !hasPermission(permissions.EDIT_ZONE) ?
          //         columnsNew.filter((val) => val.title != 'Status') :
          columnsNew
        }
        rowKey={(record) => record.id.toString()}
        className="bg-[#fff] p-5 rounded-lg  shadow-[0 2px 8px rgba(0, 0, 0, 0.1)]"
        // rowClassName={() => styles.tableRow}
        rowClassName={rowClassName ? rowClassName : styles.tableRow}
        pagination={{
          current: data?.data?.page,
          pageSize: data?.data?.limit,
          total: data?.data.total,
          onChange: handlePageChange,
        }}
        scroll={{ x: 100 }} // Add scroll property to enable vertical and horizontal scrolling
      />
    </div>
  );

  const customIcon = (
    <LoadingOutlined
      style={{ fontSize: 40, color: "rgb(45, 117, 109)" }}
      spin
    />
  );

  if (isLoading) {
    render = (
      <Spin
        size="large"
        className="flex justify-center bg-white items-center h-[200px]"
        indicator={customIcon}
      />
    );
  }

  if (error) {
    render = (
      <Alert
        message="Error"
        description="Failed to fetch data"
        type="error"
        className="p-5 bg-[#fff] border border-solid border-[#ff4d4f] rounded-lg"
      />
    );
  }

  return render;
};

export default List;
