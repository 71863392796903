import { BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Response, DashboardStatsResponse, } from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const dashboardApi = (builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, string, "api">) => ({
    getDashboard: builder.query<Response<DashboardStatsResponse>, void>({
        query: () => apiRoutes.dashboard,
        providesTags: [apiTags.task],
    }),

});