import { create } from "domain";

const apiRoutes = {
  login: "auth/signin?platform=portal",
  signup: "auth/signup",
  resetPassword: "auth/resetPassword",
  verifyOtp: "OTP/verify",
  requestOtp: "OTP/create",
  refreshToken: "auth/refresh-token",

  //Society User
  getSocietyUsers: "users/society",
  createSocietyUser: "users/society",
  getSocietyUserById: (id: string) => `users/society/${id}`,
  editSocietyUser: (id: string) => `users/society/${id}`,
  getSocietyUserDropdown: "society/dropdown",

  // LOVs
  getDesignations: "designations",
  getDepartment: "departments",
  getDivision: "divisions",
  getPermission: "permissions",
  getInspectors: "users/subordinates",

  //get Supervisors route
  getSupervisor: (hierarchy: string = "") => `supervisors${hierarchy}`,

  //dashboard
  dashboard: "dashboard",

  //user api routes
  createUser: "users",
  getUsers: "users",
  getUserById: (id: string) => `users/${id}`,
  editUser: (id: string) => `users/${id}`,
  getInspectorLocation: "users/inspectorLocations",
  changeStatusUser: (id: string) => `users/change-status/${id}`,

  getUserDropdown: "users/all/dropdown",
  //role api routes
  getRoleDropdown: (hierarchy: string = "") => `roles/dropdown${hierarchy}`,
  getRole: "roles",
  createRole: "roles",
  getRoleById: (id: string) => `roles/${id}`,
  editRole: (id: string) => `roles/${id}`,
  deleteRole: (id: string) => `roles/${id}`,
  changeStatusRole: (id: string) => `roles/change-status/${id}`,

  //task api routes
  getTasks: "tasks",
  createTask: "tasks",
  getTaskById: (id: string) => `tasks/${id}`,
  editTaskById: (id: string) => `tasks/${id}`,
  deleteTask: (id: string) => `tasks/${id}`,
  printChallanTask: (id: string) => `tasks/print/${id}`,
  finalDisposalTask: (id: string) => `tasks/final-disposal/${id}`,

  //zone api routes
  getZone: "zones",
  getZoneDropdown: "zones/dropdown",
  createZone: "zones",
  editZone: (id: string) => `zones/${id}`,
  getZoneById: (id: string) => `zones/${id}`,
  deleteZone: (id: string) => `zones/${id}`,
  changeStatusZone: (id: string) => `/zones/change-status/${id}`,

  //subzone api routes
  getSubZone: "subZones",
  getSubZoneById: (id: string) => `subZones/${id}`,
  // createZone: "zones",
  // editZone: (id: string) => `zones/${id}`,
  // deleteZone: (id: string) => `zones/${id}`,
  // changeStatusZone: (id: string) => `/zones/change-status/${id}`,
  getSubZoneDropdown: (id: string) => `subZones/dropdown${id}`,

  //propertytype api routes
  getPropertyType: "propertyTypes",
  createPropertyType: "propertyTypes",
  editPropertyType: (id: string) => `propertyTypes/${id}`,
  getPropertyTypeById: (id: string) => `propertyTypes/${id}`,
  deletePropertyType: (id: string) => `propertyTypes/${id}`,
  changeStatusProperty: (id: string) => `/propertyTypes/change-status/${id}`,
  getPropertyTypeUsageDropdown: (id: string) =>
    `propertyTypeUsages/dropdown${id}`,

  //violationtype api routes
  getViolation: "violation",
  getSocietyViolation: "violation?isSociety=true",
  createViolation: "violation",
  editViolation: (id: string) => `violation/${id}`,
  getViolationById: (id: string) => `violation/${id}`,
  deleteViolation: (id: string) => `violation/${id}`,
  addViolation: "violation",
  changeStatusViolation: (id: string) => `violation/change-status/${id}`,
  getViolationDropdown: "violation/dropdown",

  //area managemnt api routes
  getArea: "area",
  createArea: "area",
  getAreaById: (id: string) => `area/${id}`,
  editArea: (id: string) => `area/${id}`,
  getAreaDropdown: (id: string) => `area/dropdown${id}`,
  getSubAreaDropdown: (id: string) => `subArea/dropdown${id}`,

  //challan managemnt api routes
  getChallan: "challans",
  changeStatusChallan: (id: number) => `challans/change-status/${id}`,
  assignToSupervisorChallan: (id: number) =>
    `challans/assign-to-supervisor/${id}`,
  changeApprovedStatusChallan: (id: number) =>
    `challans/change-approved-status/${id}`,
  getChallanById: (id: number) => `challans/${id}`,

  // Sub Area api routes
  getSubArea: "subArea",
  createSubArea: "subArea",
  getSubAreaById: (id: string) => `subArea/${id}`,
  editSubArea: (id: string) => `subArea/${id}`,

  // Society managementapi routes
  getSociety: "society",
  createSociety: "society",
  getSocietyById: (id: string) => `society/${id}`,
  editSociety: (id: string) => `society/${id}`,
  changeStatusSociety: (id: string) => `society/change-status/${id}`,
  deleteSociety: (id: string) => `society/${id}`,
  getSocietyDropdown: "society/dropdown",

  // Society managementapi routes
  getSocietySurvey: "societySurvey",
  getSocietySurveyById: (id: string) => `societySurvey/${id}`,
  // createSociety: "society",
  // editSociety: (id: string) => `society/${id}`,
  // changeStatusSociety: (id: string) => `society/change-status/${id}`,
  // deleteSociety: (id: string) => `society/${id}`,
  // getSocietyDropdown: "society/dropdown",
};

export default apiRoutes;
