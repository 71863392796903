import React, { useState, useEffect } from "react";
import { Form, Input, notification, Select } from "antd";
import {
  useCreateSubAreaMutation,
  useGetPermissionsQuery,
  useGetZoneDropdownQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { subAreaValidator } from "@validators/subAreaValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface Area {
  value?: string;
  label?: string;
}

interface addSubAreaProps {
  onClose?: () => void;
}

const AddSubArea: React.FC<addSubAreaProps> = ({ onClose }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [selectedZone, setSelectedZone] = useState("");
  const [createSubArea, { isLoading, error }] = useCreateSubAreaMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();
  const {
    data: zoneList,
    error: zoneListError,
    isLoading: zoneListIsLoading,
  } = useGetZoneDropdownQuery();
  const {
    data: area,
    error: areaError,
    isLoading: areaIsLoading,
    refetch: refetchAreas,
  } = useGetAreaDropdownQuery({});
  const {
    data: areas,
    error: areasError,
    isLoading: areasIsLoading,
  } = useGetAreaDropdownQuery({ subZoneId: selectedZone });

  useEffect(() => {
    if (selectedZone) {
      console.log("selectedZone", selectedZone);
    }
  }, [selectedZone]);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
        areaId: values.areaId,
      };
      const result = await createSubArea(payload).unwrap();
      console.log("Sub-Area Added successfully:", result);
      openNotificationWithIcon(
        "success",
        result?.message || "Sub-Area Created Successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      console.error("Sub-Area creation failed:", err);
      openNotificationWithIcon(
        "error",
        err?.data?.message || "Failed to create Sub-Area."
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleZoneChange = (value: string) => {
    setSelectedZone(value);
  };

  return (
    <AppFormWrapper
      error={error || permissionsError || areaError}
      initialValues={{ name: null, zone: null, area: null }}
      isLoading={isLoading || permissionsIsLoading || areaIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item label="Name" name="name" rules={subAreaValidator.name}>
        <Input
          maxLength={30}
          className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          placeholder="Enter name"
        />
      </Form.Item>
      <Form.Item
        label="Area"
        name="areaId"
        rules={subAreaValidator.area}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select Area"
          loading={areaIsLoading}
          disabled={areaIsLoading}
          className="rounded-md"
        >
          {area?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default AddSubArea;
