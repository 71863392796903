import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import authPaths from "@paths/authPaths";
import { RootState } from "@store/store";
import apiTags from "@services/apiTags";
import { userApi } from "@services/api/userApi";
import { roleApi } from "./roleApi";
import { authApi } from "./authApi";
import { designationApi } from "./designationApi";
import { departmentApi } from "./departmentApi";
import { zoneApi } from "./zoneApi";
import { divisionApi } from "./divisionApi";
import { supervisiorApi } from "./supervisorApi";
import { permissionApi } from "./permissionApi";
import { taskApi } from "./taskApi";
import { API_BASE_URL, CLIENT_BASE_URL } from "@constants/index";
import { inspectorApi } from "./inspectorApi";
import { propertyTypeApi } from "./propertyTypeApi";
import { violationApi } from "./violationApi";
import { areaApi } from "./areaApi";
import { dashboardApi } from "./dashboardApi";
import { challanApi } from "./challanApi";
import { subAreaApi } from "./subArea";
import { authSlice } from "@redux/authSlice";
import apiRoutes from "@services/apiRoutes";
import { subZoneApi } from "./subZoneApi";
import { societyApi } from "./societyApi";
import { societySurveyApi } from "./societySurveyApi";

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshToken =
      (api.getState() as RootState).auth.refreshToken ||
      localStorage.getItem("refresh-token") ||
      "";
    if (refreshToken) {
      // Attempt to refresh the token
      const refreshResult: any = await baseQuery(
        {
          url: apiRoutes.refreshToken,
          method: "POST",
          body: { refreshToken },
        },
        api,
        extraOptions
      );
      if (refreshResult.data) {
        // Store the new token
        api.dispatch(
          authSlice.actions.setCredentials({
            token: refreshResult.data.accessToken,
            user: JSON.parse(localStorage.getItem("user") || '0') || (api.getState() as RootState).auth.user,
            refreshToken,
          })
        );

        // Retry the original query with the new access token
        result = await baseQuery(args, api, extraOptions);
      } else {
        // Refresh failed, force logout or take appropriate action
        api.dispatch(authSlice.actions.clearCredentials());
        window.location.href = `${process.env.CLIENT_BASE_URL || CLIENT_BASE_URL
          }/#${authPaths.login()}`;
      }
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",

  baseQuery: baseQueryWithReauth,
  tagTypes: [apiTags.user, apiTags.role],
  endpoints: (builder) => ({
    ...userApi(builder),
    ...roleApi(builder),
    ...authApi(builder),
    ...designationApi(builder),
    ...departmentApi(builder),
    ...zoneApi(builder),
    ...subZoneApi(builder),
    ...areaApi(builder),
    ...subAreaApi(builder),
    ...propertyTypeApi(builder),
    ...divisionApi(builder),
    ...supervisiorApi(builder),
    ...permissionApi(builder),
    ...taskApi(builder),
    ...inspectorApi(builder),
    ...violationApi(builder),
    ...challanApi(builder),
    ...dashboardApi(builder),
    ...societyApi(builder),
    ...societySurveyApi(builder),
  }),
});

export const {
  useGetUsersQuery,
  useLoginMutation,
  useSignupMutation,
  useRequestOTPMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,

  // LOVs
  useGetDesignationsQuery,
  useGetDepartmentQuery,
  useGetDivisionQuery,
  useGetZoneQuery,
  useChangeStatusUserMutation,

  //propertytype
  useGetPropertyQuery,
  useCreatePropertyMutation,
  useGetPropertyTypeByIdQuery,
  useEditPropertyMutation,
  useDeletePropertyMutation,
  useChangeStatusPropertyMutation,
  useGetPropertyTypeUsageDropdownQuery,

  //violation
  useGetViolationQuery,
  useGetSocietyViolationQuery,
  useChangeStatusViolationMutation,
  useCreateViolationMutation,
  useEditViolationMutation,
  useDeleteViolationMutation,
  useGetViolationByIdQuery,
  useGetViolationDropdownQuery,

  //zones
  useGetZoneDropdownQuery,
  useGetZoneByIdQuery,
  useCreateZoneMutation,
  useEditZoneMutation,
  useChangeStatusZoneMutation,
  useDeleteZoneMutation,

  //subzones
  useGetSubZoneQuery,
  useGetSubZoneByIdQuery,

  //area
  useGetAreaQuery,
  useCreateAreaMutation,
  useEditAreaMutation,
  useGetAreaByIdQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaDropdownQuery,
  useGetSubZoneDropdownQuery,

  // sub Area

  useGetSubAreaQuery,
  useCreateSubAreaMutation,
  useEditSubAreaMutation,
  useGetSubAreaByIdQuery,

  //challan
  useGetChallanQuery,
  useChangeApprovedStatusChallanMutation,
  useChangeStatusChallanMutation,
  useAssignToSupervisorChallanMutation,
  useGetChallanByIdQuery,

  //Society User
  useGetSocietyUsersQuery,
  useCreateSocietyUserMutation,
  useEditSocietyUserMutation,
  useGetSocietyUserByIdQuery,
  useGetSocietyUserDropdownQuery,

  //task
  useGetTasksQuery,
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useEditTaskMutation,
  useDeleteTaskMutation,

  //society management
  useGetSocietyQuery,
  useCreateSocietyMutation,
  useGetSocietyByIdQuery,
  useEditSocietyMutation,
  useDeleteSocietyMutation,
  useGetSocietyDropdownQuery,

  //society management
  useGetSocietySurveyQuery,
  useGetSocietySurveyByIdQuery,
  // useCreateSocietyMutation,
  // useEditSocietyMutation,
  // useDeleteSocietyMutation,
  // useGetSocietyDropdownQuery,

  useGetSupervisorQuery,
  useGetRoleDropdownQuery,
  useGetRoleQuery,
  useGetUserByIdQuery,
  useGetRoleByIdQuery,
  useCreateUserMutation,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetPermissionsQuery,
  useChangeStatusRoleMutation,
  useGetInspectorsQuery,
  useEditUserMutation,
  useGetInspectorsLocationQuery,
  useGetDashboardQuery,
  useGetUserDropdownQuery,

  useFinalDisposalMutation,
  usePrintChallanMutation,
} = api;
