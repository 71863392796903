import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  //   Input,
  //   Button,
  //   Select,
  //   Upload,
  //   DatePicker,
  //   notification,
  Row,
  Col,
  //   InputNumber,
  Skeleton,
  Alert,
  //   Flex,
  Descriptions,
  DescriptionsProps,
} from "antd";
import {
  //   useGetInspectorsQuery,
  //   useGetTaskByIdQuery,
  //   useEditTaskMutation,
  useGetSocietySurveyByIdQuery,
} from "@services/api/index";

// import { UploadOutlined } from "@ant-design/icons";
// import AppFormWrapper from "@components/appFormWrapper";
// import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { SocietySurvey, Task } from "types";
// import { taskValidator } from "@validators/taskValidator";
import MapWithImages from "@components/googleMapWithImages";
import { API_BASE_URL } from "@constants/index";
import AppFormWrapper from "@components/appFormWrapper";

// const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  path?: string;
  lat: any;
  long: any;
}

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string;
}

interface ViewSocietySurveyProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

const ViewSocietySurvey: React.FC<ViewSocietySurveyProps> = ({
  onClose,
  id,
  isLoaded,
}) => {
  //   const [editTask, { isLoading, error }] = useEditTaskMutation();
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | null>(null);

  //   const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [surveyImages, setSurveyImages] = useState<Attachment[]>([]);

  //   const {
  //     data: inspectors,
  //     error: inspectorsError,
  //     isLoading: inspectorsIsLoading,
  //   } = useGetInspectorsQuery();

  //   const {
  //     data: societySurveyById,
  //     error: taskDataError,
  //     isLoading: taskDataIsLoading,
  //   } = useGetTaskByIdQuery(id);
  const {
    data: societySurveyById,
    error: societySurveyError,
    isLoading: societySurveyIsLoading,
  } = useGetSocietySurveyByIdQuery(id);

  const task = useMemo<SocietySurvey | undefined>(
    () => societySurveyById && societySurveyById.data,
    [societySurveyById]
  );

  useEffect(() => {
    if (societySurveyById) {
      const surveyData: SocietySurvey = societySurveyById.data;

      form.setFieldsValue({
        society: surveyData.society,
        status: surveyData.status,
        createdBy: moment(surveyData.createdBy),
        violations: surveyData.violations,
        // streetName: surveyData.streetName,
        // ownerName: surveyData.ownerName,
        // phoneNo: surveyData.phoneNo,
        // plotLength: surveyData.plotLength,
        // plotWidth: surveyData.plotWidth,
        // buildingHeight: surveyData.buildingHeight,
        // floorAreaSft: surveyData.floorAreaSft,
        // floorAreaSqy: surveyData.floorAreaSqy,
        // noOfStories: surveyData.noOfStories,
        // landUseConversionAndRegularizationCharges:
        //   surveyData.landUseConversionAndRegularizationCharges,
        // inspectorRemarks: surveyData.inspectorRemarks,
        // tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
        //   surveyData.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges,
      });

      if (surveyData.buildingCoordinates) {
        setPolygonData(surveyData.buildingCoordinates);
      }
      if (surveyData.attachments) {
        setSurveyImages(surveyData.attachments || []);
      }

      if (surveyData.lat && surveyData.long) {
        setMapCenter({
          lat: parseFloat(surveyData.lat),
          lng: parseFloat(surveyData.long),
        });
      }
    }
  }, [societySurveyById, form]);

  //   const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
  //     setPolygonData(newPolygons ? newPolygons : polygonData);
  //   };

  //   const handleUploadChange = (info: any) => {
  //     setFileList(info.fileList);
  //   };

  //   const convertFileToBase64 = (file: any): Promise<string> => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result as string);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   };

  //   const onFinish = async (values: any) => {};

  //   const onFinishFailed = (errorInfo: any) => {
  //     console.log("Failed:", errorInfo);
  //   };

  // const center = polygonData && polygonData.length > 0 ? polygonData[0] : {
  //   lat: 33.6938,
  //   lng: 73.0652,
  // };

  const center = {
    lat: 33.6938,
    lng: 73.0652,
  };

  // const items: DescriptionsProps['items'] = useMemo(() => societySurveyById && Object.keys(societySurveyById?.data).map((val, index) => ({
  //   key: index + 1,
  //   label: val.toString(),
  //   children: val == 'id' ? index + 1 : societySurveyById && (societySurveyById as any)?.data[val],
  // })).filter((val) => societySurveyById && (societySurveyById as any)?.data[val.label] && !val.label.includes('Id')), [societySurveyById])

  function convertCamelCaseToLabel(text: string): string {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, " $1$2")
      .replace(/^./, (str) => str.toUpperCase());
  }

  const items: DescriptionsProps["items"] = useMemo(() => {
    if (!societySurveyById) return [];

    const task = societySurveyById.data as SocietySurvey;

    return Object.keys(task)
      .filter(
        (key) =>
          key.slice(-2) !== "Id" &&
          key !== "attachments" &&
          key !== "violationIds" &&
          key !== "id" &&
          key !== "propertyTypeId" &&
          key !== "assignedToId" &&
          key !== "hierarchy" &&
          task[key as keyof SocietySurvey] !== null
      )
      .map((key, index) => {
        let value = task[key as keyof SocietySurvey];

        if (typeof value === "string" || value instanceof Date) {
          if (moment(value, moment.ISO_8601, true).isValid()) {
            value = moment(value).format("DD-MM-YYYY");
          }
        }

        const displayValue =
          typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value?.toString();

        return {
          key: index + 1,
          label: convertCamelCaseToLabel(key),
          children: key === "id" ? index + 1 : displayValue,
        };
      });
  }, [societySurveyById]);

  const imageMarkers: ImageMarker[] = surveyImages.map((attachment) => ({
    coordinates: {
      lat: parseFloat(attachment.lat),
      lng: parseFloat(attachment.long),
    },
    images: attachment.path ? `${API_BASE_URL}${attachment?.path}` : "", // Wrapping the path in an array
  }));

  return (
    <Row gutter={[16, 16]} className="h-full">
      <Col xs={24} sm={24} md={18} lg={16} xl={16}>
        {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
        <MapWithImages
          center={mapCenter || center}
          imageMarkers={imageMarkers}
          isLoaded={true}
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={8}
        xl={8}
        // style={{ overflowY: "scroll", height: "100vh" }}
      >
        <AppFormWrapper
          onClose={onClose}
          error={societySurveyError}
          isLoading={societySurveyIsLoading}
        >
          <>
            {societySurveyIsLoading ? (
              <Skeleton active />
            ) : societySurveyError ? (
              <Alert
                message={
                  (societySurveyError as any)?.data?.message ||
                  "An error occurred"
                }
                type="error"
                className="mb-4"
              />
            ) : (
              <>{task && <Descriptions bordered column={1} items={items} />}</>
            )}
          </>
        </AppFormWrapper>
      </Col>
    </Row>
  );
};

export default ViewSocietySurvey;
