import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import AddSubArea from "@components/subArea/addSubArea";
import EditSubArea from "@components/subArea/editSubArea";
import ViewSubArea from "@components/subArea/viewSubArea";
import SubAreaList from "@components/subArea/subAreaList";

const SubAreaManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_AREA_MANAGEMENT) ? (
    <AppPageWrapper
      addComp={() => hasPermission(permissions.ADD_AREA) ? <AddSubArea /> : null}
      listComp={<SubAreaList />}
      editComp={(id: string) => <EditSubArea id={id} />}
      viewComp={(id: string) => <ViewSubArea id={id} />}
      moduleName="Sub-Area"
      pagetTitle="Sub-Area Management"
      width={"600px"}
    />
  ) : null;
};

export default SubAreaManagement;