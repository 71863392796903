// src/index.tsx
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import "./index.css"
import './App.css'

import { createRoot } from 'react-dom/client';
const container =  document.getElementById('root');
const root = createRoot(container as any); // createRoot(container!) if you use TypeScript
root.render( <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</React.StrictMode>);