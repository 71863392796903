import React, { useState, useEffect, ReactNode } from "react";
import { useAssignToSupervisorChallanMutation, useChangeApprovedStatusChallanMutation, useGetChallanQuery } from "@services/api/index";
import styles from "./challanList.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { Button, Dropdown, MenuProps, Space, notification } from "antd";
import { Challan } from "types";
import { DownOutlined } from '@ant-design/icons';
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import useUser from "@hooks/userHook";

enum ChallenApprovedStatus {
  APPROVED = "approved",
  UNAPPROVED = "unapproved",
  PENDING = 'pending'
}

interface ChallanListProps {
  viewComp?: (id: number) => ReactNode;
}

const ChallanList: React.FC<ChallanListProps> = ({ viewComp }) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const { data: area, error, isLoading } = useGetChallanQuery(pagination);
  const [changeApprovedStatusChallan, { isLoading: changeApprovedStatusChallanIsloading, error: changeApprovedStatusChallanError }] = useChangeApprovedStatusChallanMutation();
  const [assignToSupervisorChallan, { isLoading: assignToSupervisorChallanIsloading, error: assignToSupervisorChallanError }] = useAssignToSupervisorChallanMutation();

  const handleToggleChange = async (status: string, record: Challan) => {
    await changeApprovedStatusChallan({ id: record.id, status }).unwrap();
  };

  const handleAssignToSupervisor = async (id: number) => {
    await assignToSupervisorChallan(id).unwrap();
  };

  const { hasPermission } = usePermissions();
  const user = useUser();

  useEffect(() => {
    if (changeApprovedStatusChallanError || assignToSupervisorChallanError) {
      notification.error({
        message: "Challan Update failed:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
    }
  }, [changeApprovedStatusChallanError, assignToSupervisorChallanError])

  const items: MenuProps['items'] = [
    {
      label: 'Approve',
      key: 'approved',
    },
    {
      label: 'Reject',
      key: 'unapproved',
    },
  ];

  const menuProps = (record: Challan) => ({
    items,
    onClick: (e: any) => handleToggleChange(e.key, record),
  });

  const columns = [
    {
      title: "Assigned To",
      dataIndex: "assignedToName",
      key: "assignedToName",
      className: styles.tableCell,
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      className: styles.tableCell,
    },
    {
      title: "Fine",
      dataIndex: "violationFine",
      key: "violationFine",
      className: styles.tableCell,
    },
    {
      title: "Violation",
      dataIndex: "violationName",
      key: "violationName",
      className: styles.tableCell,
    },
    {
      title: "Approved By",
      dataIndex: "approvedByName",
      key: "approvedByName",
      className: styles.tableCell,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: styles.tableCell,
    },
    {
      title: "Approved Status",
      dataIndex: "approvedStatus",
      key: "approvedStatus",
      render: (text: any, record: Challan) => (
        <Dropdown menu={menuProps(record)} disabled={record.approvedStatus == ChallenApprovedStatus.APPROVED || record.approvedStatus == ChallenApprovedStatus.UNAPPROVED}>
          <Button loading={changeApprovedStatusChallanIsloading}>
            <Space>
              {record.approvedStatus == ChallenApprovedStatus.PENDING ? 'Pending'
                : record.approvedStatus == ChallenApprovedStatus.APPROVED ? 'Approved'
                  : 'Rejected'}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

      ),
      className: styles.tableCell,
    },
    {
      title: "Actions",
      render: (text: any, record: Challan) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_CHALLAN_MANAGEMENT) && viewComp && viewComp(record.id)}
          {(user?.supervisorId && !(record.approvedStatus == ChallenApprovedStatus.APPROVED || record.approvedStatus == ChallenApprovedStatus.UNAPPROVED)) &&
            <Button loading={assignToSupervisorChallanIsloading} type="primary" onClick={() => handleAssignToSupervisor(record.id)}>
              Assign to supervisor
            </Button>}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={area}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
    </div>
  );
};

export default ChallanList;
