import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  Society,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const societyApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getSociety: builder.query<ResponseListPaginate<Society>, PaginationPayload>({
    query: ({ page, limit, searchText }: PaginationPayload) => {
      const params: PaginationPayload = { page, limit };

      if (searchText) {
        params.searchText = searchText;
      }
      return {
        url: apiRoutes.getSociety,
        params,
      };
    },
    providesTags: [apiTags.society],
  }),
  createSociety: builder.mutation<Response<Society>, Society>({
    query: (body) => ({
      url: apiRoutes.createSociety,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.society],
  }),
  getSocietyDropdown: builder.query<ResponseList<Society>, void>({
    query: () => apiRoutes.getSocietyDropdown,
  }),

  editSociety: builder.mutation<
    Response<Society>,
    { id: string; body: Society }
  >({
    query: ({ id, body }) => ({
      url: apiRoutes.editSociety(id),
      method: "PATCH",
      body,
    }),
    invalidatesTags: [apiTags.society],
  }),
  deleteSociety: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.deleteSociety(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.society],
  }),

  getSocietyById: builder.query<Response<Society>, string>({
    query: (id: string) => apiRoutes.getSocietyById(id),
    providesTags: [apiTags.society],
  }),
});
