import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import buildingViolationIcon from 'assets/img/buildingViolationIcon.png';
import { Image } from 'antd';

const containerStyle = {
  width: '100%',
  height: '85vh',
};

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string; // Single image string per marker
}

interface MapWithImagesProps {
  center?: LatLngLiteral;
  imageMarkers: ImageMarker[];
  isLoaded: boolean;
}

const MapWithImages: React.FC<MapWithImagesProps> = ({ center, imageMarkers, isLoaded }) => {
  const [mapCenter, setMapCenter] = useState(center);
  const [selectedMarker, setSelectedMarker] = useState<ImageMarker | null>(null);
  const [mapFullyLoaded, setMapFullyLoaded] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    setTimeout(() => {
      setMapFullyLoaded(true);
    }, 500);
  }, []);

  const handleMarkerClick = (marker: ImageMarker) => {
    setSelectedMarker(marker);
  };

  const handleCloseClick = () => {
    setSelectedMarker(null);
  };

  // useEffect(() => {
  //   console.log('imageMarkers:', imageMarkers);
  //   console.log('imageMarkers?.coordinates:', imageMarkers[0]?.coordinates);
  //   console.log("mapCenter", mapCenter);
  // }, [imageMarkers]);

  useEffect(() => {
    setMapCenter(center);
  }, [center]);

  const allImages = imageMarkers.map(marker => marker.images);

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={12}
        onLoad={onLoad}
      >
        {mapFullyLoaded && imageMarkers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.coordinates}
            visible={true}
            clickable={true}
            onClick={() => handleMarkerClick(marker)}
            icon={{
              url: buildingViolationIcon,
              scaledSize: new window.google.maps.Size(30, 30) 
            }}
          />
        ))}

        {selectedMarker && mapFullyLoaded && (
          <InfoWindow
            position={selectedMarker.coordinates}
            onCloseClick={handleCloseClick}
          >
            <div style={{ maxWidth: '90vw', maxHeight: '90vh', overflowX: 'auto', whiteSpace: 'nowrap', padding: '5px' }}>

              <Image.PreviewGroup>
                {allImages.map((image, idx) => (
                  <Image
                    key={idx}
                    width={'10vw'}
                    height={'15vw'}
                    src={image} 
                    alt={`Violation Image ${idx + 1}`}
                    style={{ display: 'inline-block', marginRight: '20px', cursor: 'pointer', padding: '4px' }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default MapWithImages;