import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  DatePicker,
  notification,
  Row,
  Col,
  InputNumber,
} from "antd";
import {
  useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
} from "@services/api/index";
import { UploadOutlined } from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
// import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { Task } from "types";
import { taskValidator } from "@validators/taskValidator";
// import MapWithImages from "@components/googleMapWithImages";

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  file: string;
  lat: any;
  long: any;
}

interface ViewSurvayProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

// const imageMarkers = [
//   {
//     coordinates: { lat: 33.6938, lng: 73.0652 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAQ0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAABW6+5IpH1djoWBgcGoYBAiBiJiQZGBgYmfgYBAEEDK1MfYfOBQAAAAASUVORK5CYII=" // Red Dot
//     ]
//   },
//   {
//     coordinates: { lat: 33.7443, lng: 73.0551 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAP0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAH3q9ShE3GIwQxQ4MAIoUGKEhiYGGoNAAEgbhlM7VVpAAAAAElFTkSuQmCC", // Green Dot
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAPUlEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAHFu9ShE3GIwQxQ4MAIn2NKEIgiYEBoKwAICrBGF0s2FGAAAAAElFTkSuQmCC"  // Blue Dot
//     ]
//   },
//   {
//     coordinates: { lat: 33.7294, lng: 73.0379 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAQ0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAABW6+5IpH1djoWBgcGoYBAiBiJiQZGBgYmfgYBAEEDK1MfYfOBQAAAAASUVORK5CYII=", // Red Dot
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAP0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAH3q9ShE3GIwQxQ4MAIoUGKEhiYGGoNAAEgbhlM7VVpAAAAAElFTkSuQmCC"  // Green Dot
//     ]
//   }
// ];

const ViewSurvey: React.FC<ViewSurvayProps> = ({ onClose, id, isLoaded }) => {
  const [editTask, { isLoading, error }] = useEditTaskMutation();
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const {
    data: inspectors,
    error: inspectorsError,
    isLoading: inspectorsIsLoading,
  } = useGetInspectorsQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      form.setFieldsValue({
        assignedToId: task.assignedToId,
        dueDate: moment(task.dueDate),
        propertyNo: task.propertyNo,
        propertyName: task.propertyName,
        streetName: task.streetName,
        ownerName: task.ownerName,
        phoneNo: task.phoneNo,
        plotLength: task.plotLength,
        plotWidth: task.plotWidth,
        buildingHeight: task.buildingHeight,
        floorAreaSft: task.floorAreaSft,
        floorAreaSqy: task.floorAreaSqy,
        noOfStories: task.noOfStories,
        landUseConversionAndRegularizationCharges:
          task.landUseConversionAndRegularizationCharges,
        inspectorRemarks: task.inspectorRemarks,
        tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
          task.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges,
      });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
    }
  }, [taskDataById, form]);

  const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
    setPolygonData(newPolygons ? newPolygons : polygonData);
  };

  const handleUploadChange = (info: any) => {
    setFileList(info.fileList);
  };

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onFinish = async (values: any) => {
    try {
      const attachments: Attachment[] = await Promise.all(
        fileList.map(async (file) => {
          const base64File = await convertFileToBase64(file.originFileObj);
          return {
            file: base64File,
            lat: undefined,
            long: undefined,
          };
        })
      );

      const payLoad = {
        assignedToId: values.assignedToId,
        dueDate: values.dueDate.format("YYYY-MM-DD"),
        buildingCoordinates: polygonData || [],
        centerOfPolygon:
          polygonData && polygonData.length > 0
            ? polygonData[0]
            : { lat: 0, lng: 0 },
        propertyNo: values.propertyNo || undefined,
        propertyName: values.propertyName || undefined,
        streetName: values.streetName || undefined,
        ownerName: values.ownerName || undefined,
        phoneNo: values.phoneNo || undefined,
        plotLength: values.plotLength
          ? values.plotLength.toString()
          : undefined,
        plotWidth: values.plotWidth ? values.plotWidth.toString() : undefined,
        buildingHeight: values.buildingHeight
          ? values.buildingHeight.toString()
          : undefined,
        floorAreaSft: values.floorAreaSft
          ? values.floorAreaSft.toString()
          : undefined,
        floorAreaSqy: values.floorAreaSqy
          ? values.floorAreaSqy.toString()
          : undefined,
        noOfStories: values.noOfStories
          ? values.noOfStories.toString()
          : undefined,
        landUseConversionAndRegularizationCharges:
          values.landUseConversionAndRegularizationCharges || undefined,
        inspectorRemarks: values.inspectorRemarks || undefined,
        tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
          values.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges ||
          undefined,
        attachments: attachments ? attachments : undefined,

        // New keys
        actualUsageId: values.actualUsageId || undefined,
        currentUsageId: values.currentUsageId || undefined,
        areaId: values.areaId || undefined,
        violationfeet: values.violationfeet || undefined,
        violationApprovedCoveredArea:
          values.violationApprovedCoveredArea || undefined,
        violationExisitingCoveredArea:
          values.violationExisitingCoveredArea || undefined,
        violationAccessArea: values.violationAccessArea || undefined,
        violationApprovedNumberOfFloor:
          values.violationApprovedNumberOfFloor || undefined,
        violationBuiltNumberOfFloors:
          values.violationBuiltNumberOfFloors || undefined,
        fileNoEM: values.fileNoEM || undefined,
        plotNo: values.plotNo || undefined,
        streetRoad: values.streetRoad || undefined,
        subSector: values.subSector || undefined,
        sectorScheme: values.sectorScheme || undefined,
        cornerStatus: values.cornerStatus || undefined,
        classificationOfPlot: values.classificationOfPlot || undefined,
        size: values.size || undefined,
        dimension: values.dimension || undefined,
        subDivision: values.subDivision || undefined,
        amalgamation: values.amalgamation || undefined,
        certificateOfCompletion: values.certificateOfCompletion || undefined,
        constructionStatus: values.constructionStatus || undefined,
        typeOfAllotment: values.typeOfAllotment || undefined,
        dateOfFirstAllotment: values.dateOfFirstAllotment || undefined,
        firstAllotteeName: values.firstAllotteeName || undefined,
        firstAllotteeCnic: values.firstAllotteeCnic || undefined,
        dateOfLastTransfer: values.dateOfLastTransfer || undefined,
        presentOwnerCnic: values.presentOwnerCnic || undefined,
        presentOwnerName: values.presentOwnerName || undefined,
        mortgageStatus: values.mortgageStatus || undefined,
        bankName: values.bankName || undefined,
        leaseStatus: values.leaseStatus || undefined,
        leaseEndingDate: values.leaseEndingDate || undefined,
        powerOfAttorney: values.powerOfAttorney || undefined,
        conveyanceDeedStatus: values.conveyanceDeedStatus || undefined,
        saleDeedStatus: values.saleDeedStatus || undefined,
        litigationStatus: values.litigationStatus || undefined,
        otherInformation: values.otherInformation || undefined,
        buildingPlanApprovalDate: values.buildingPlanApprovalDate || undefined,
        approvedCoveredAreaSft: values.approvedCoveredAreaSft || undefined,
        noOfStoreys: values.noOfStoreys || undefined,
        noOfUnits: values.noOfUnits || undefined,
        completionCertificateDate:
          values.completionCertificateDate || undefined,
        completionCoveredAreaSft: values.completionCoveredAreaSft || undefined,
        conditionOfBuilding: values.conditionOfBuilding || undefined,
        propertyTaxAnnual: values.propertyTaxAnnual || undefined,
        propertyTaxPaidUp: values.propertyTaxPaidUp || undefined,
        waterCharges: values.waterCharges || undefined,
        conservancyCharges: values.conservancyCharges || undefined,
        quarterlyDemandAB: values.quarterlyDemandAB || undefined,
        arrearsPKRA: values.arrearsPKRA || undefined,
        paymentsAIB: values.paymentsAIB || undefined,
        propertyTypeId: values.propertyTypeId || undefined,
        violationId: values.violationId || undefined,
        status: values.status || undefined,
        lat: values.lat || undefined,
        long: values.long || undefined,
      };

      console.log(payLoad);

      await editTask({ id, body: payLoad }).unwrap();
      notification.success({
        message: "Task updated successfully",
        placement: "topRight",
        duration: 3,
      });

      onClose && onClose();
    } catch (err) {
      console.error("Task update failed:", err);
      // notification.error({
      //   message: "Task update failed",
      //   description: err.message,
      //   placement: "topRight",
      //   duration: 3,
      // });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const center = polygonData && polygonData.length > 0 ? polygonData[0] : {
  //   lat: 33.6938,
  //   lng: 73.0652,
  // };

  //   const center = {
  //     lat: 33.6938,
  //     lng: 73.0652,
  //   };

  return (
    <Row gutter={[16, 16]} className="h-full">
      <Col xs={24} sm={24} md={18} lg={16} xl={16}>
        {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
        {/* <MapWithImages
  center={center} 
  imageMarkers={imageMarkers}
  isLoaded={true} 
/> */}
      </Col>
      <Col xs={24} sm={24} md={6} lg={8} xl={8}>
        <AppFormWrapper
          error={error}
          initialValues={{ remember: true }}
          isLoading={isLoading}
          onClose={onClose}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          type="Edit"
          form={form}
        >
          {/* Existing Form Fields */}
          <Form.Item label="Property No." name="propertyNo">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Property Type" name="propertyTypeId">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>
          <Form.Item label="Actual Usage" name="actualUsageId">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>
          <Form.Item label="Current Type" name="currentUsageId">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Property Name" name="propertyName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Street Name" name="streetName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Owner Name" name="ownerName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Phone Number" name="phoneNo">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Plot Length" name="plotLength">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Plot Width" name="plotWidth">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Building Height" name="buildingHeight">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Floor Area (SFT)" name="floorAreaSft">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Floor Area (SQY)" name="floorAreaSqy">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="No. of Stories" name="noOfStories">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Land Use Conversion And Regularization Charges"
            name="landUseConversionAndRegularizationCharges"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Tentative Amount on Account of Construction Without Approval Area Charges"
            name="tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Plot No." name="plotNo">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Street/Road" name="streetRoad">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Sub-Sector" name="subSector">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Sector/Scheme" name="sectorScheme">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Corner Status" name="cornerStatus">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Classification of Plot" name="classificationOfPlot">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Size (Sq. yd.)" name="size">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Dimension" name="dimension">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Sub-Division" name="subDivision">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Amalgamation" name="amalgamation">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Date of Certificate of Possession"
            name="dateOfCertificateOfPossession"
          >
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Construction Status" name="constructionStatus">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Estate Management Data" name="estateManagementData">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Type of Allotment" name="typeOfAllotment">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Date of 1st Allotment" name="dateOfFirstAllotment">
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Name of 1st Allottee" name="firstAllotteeName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="CNIC of 1st Allottee" name="firstAllotteeCnic">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Date of Last Transfer" name="dateOfLastTransfer">
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="CNIC Present Owner" name="presentOwnerCnic">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Name of Present Owner" name="presentOwnerName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Mortgage Status" name="mortgageStatus">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Bank Name" name="bankName">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Status of Lease" name="leaseStatus">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Lease Ending Date" name="leaseEndingDate">
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Position of Power of Attorney"
            name="powerOfAttorney"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Conveyance deed executed/ Sale deed"
            name="conveyanceDeedStatus"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Litigation (if any)" name="litigationStatus">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Any other Information" name="otherInformation">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          {/* Building Control Section */}
          <Form.Item
            label="Date of Approval of Building Plan"
            name="buildingPlanApprovalDate"
          >
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Approved Covered Area (sft)"
            name="approvedCoveredAreaSft"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="No. of Storeys" name="noOfStoreys">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Approved Setback Front (ft)"
            name="approvedSetbackFrontFt"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Approved Setback Rear (ft)"
            name="approvedSetbackRearFt"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Approved Setback Side (ft)"
            name="approvedSetbackSideFt"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Condition of Building" name="conditionOfBuilding">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Property Tax Annual" name="propertyTaxAnnual">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Property Tax Paid Up" name="propertyTaxPaidUp">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Transfer Fee (including TMA share)"
            name="transferFee"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Registration Fee of Sale Deed"
            name="registrationFee"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Stamp Duty Payable on Sale Deed" name="stampDuty">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Capital Value Tax Payable" name="capitalValueTax">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Advance Tax" name="advanceTax">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Completion Certificate Date"
            name="completionCertificateDate"
          >
            <DatePicker className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Demolition / Penalty Charges Paid"
            name="demolitionPenaltyChargesPaid"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Ground Rent Payable (at present)"
            name="groundRentPayable"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Ground Rent Paid up to (Year)"
            name="groundRentPaidUp"
          >
            <DatePicker className="rounded-md p-1 w-full" picker="year" />
          </Form.Item>

          <Form.Item
            label="Sewerage / Water Charges Payable"
            name="sewerageWaterCharges"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Electricity Dues Payable at Present"
            name="electricityDues"
          >
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Gas Dues Payable at Present" name="gasDues">
            <InputNumber className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item label="Occupation Type" name="occupationType">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <Form.Item
            label="Property under litigation"
            name="propertyUnderLitigation"
          >
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>
        </AppFormWrapper>
      </Col>
    </Row>
  );
};

export default ViewSurvey;
