import React from "react";
import { RouteObject } from "react-router-dom";
import AreaManagement from "@pages/areaManagement";
import AddArea from "@components/Area/addArea";
import areaManagementPaths from "@paths/areaManagementPaths";

const areaTypeManagementMappings: RouteObject[] = [
  {
    path: areaManagementPaths.root,
    element: <AreaManagement />,
  },
  {
    path: areaManagementPaths.area,
    element: <AreaManagement />,
  },
  {
    path: areaManagementPaths.AddArea,
    element: <AddArea />,
  },
];

export default areaTypeManagementMappings;
