import React from "react";
import { RouteObject } from "react-router-dom";
import UserManagement from "@pages/userManagement";
import userManagementPaths from "@paths/userManagementPaths";
import AddUser from "@components/Users/addUser";

const userManagementMappings: RouteObject[] = [
  {
    path: userManagementPaths.root,
    element: <UserManagement />,
  },
  {
    path: userManagementPaths.addUser,
    element: <AddUser />,
  },
];

export default userManagementMappings;
