import React, { useState, ReactNode } from "react";
import {
  useFinalDisposalMutation,
  useGetTaskByIdQuery,
  useGetTasksQuery,
  usePrintChallanMutation,
} from "@services/api/index";
import styles from "./task.module.css";
import List from "@components/list";
import { Select, Modal, Button, notification } from "antd";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  // API_BASE_URL,
  DEFAULT_PAGE,
  // IMG_BASE_URL,
  PAGE_LIMIT,
} from "@constants/index";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";
import moment from "moment";

import surveyFormField from "../editTask/surveyFormFileds";
import MyDocument from "./mydocument";
import { pdf } from "@react-pdf/renderer";
import useUser from "@hooks/userHook";

const { Option } = Select;

interface Task {
  id: string;
  name: string;
  active: boolean;
  status: string;
  picture: string;
  description: string;
  date: string;
  location: string;
  zone: string;
  supervisor: string;
  finalDisposal: number;
}

interface TaskListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  proceedComp?: (id: string, isDisable: number) => ReactNode;
  searchValue?: string;
}

const TaskList: React.FC<TaskListProps> = ({
  editComp,
  viewComp,
  proceedComp,
  searchValue,
}) => {
  // const task: any = {
  //   name: "Sample Task",
  //   date: moment().format(),
  //   // Add more fields as needed
  // };
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  // const [searchText, setSearchText] = useState("");
  const {
    data: tasks,
    error,
    isLoading,
  } = useGetTasksQuery({ ...pagination, searchText: searchValue });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isDisposalModalOpen, setIsDisposalModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<any>(null);
  const [recordToPrint, setRecordToPrint] = useState<any>(null);
  const [recordToDispose, setRecordToDispose] = useState<string | null>(null);
  const user = useUser();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(recordToPrint);

  // const [
  //   changeStatusChallan,
  //   {
  //     isLoading: changeStatusChallanIsloading,
  //     error: changeStatusChallanError,
  //   },
  // ] = usePrintChallanMutation();

  const [
    finalDisposal,
    { isLoading: finalDisposalIsloading, error: finalDisposalError },
  ] = useFinalDisposalMutation();

  const { hasPermission } = usePermissions();

  // const showDeleteConfirm = (record: Task) => {
  //   setRecordToDelete(record);
  //   setIsDeleteModalOpen(true);
  // };

  const handleDelete = () => {
    if (recordToDelete) {
      console.log("Delete record:", recordToDelete);
      // Implement actual delete functionality here
    }
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const handlePrint = (id: string) => {
    setRecordToPrint(id);
    setIsPrintModalOpen(true);
  };

  function convertCamelCaseToLabel(text: string): string {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, " $1$2")
      .replace(/^./, (str) => str.toUpperCase());
  }

  const handleConfirmPrint = async () => {
    const task: any = taskDataById?.data;
    const fields: any = [];
    const urbanPlanningFields: any = [];
    const estateManagementFields: any = [];
    const buildingControlFields: any = [];
    const revenueDataFields: any = [];
    const attachments: any = taskDataById?.data?.attachments;

    Object.keys(task)
      .filter(
        (key) =>
          key.slice(-2) !== "Id" &&
          key !== "attachments" &&
          key !== "violationIds" &&
          key !== "id" &&
          key !== "propertyTypeId" &&
          key !== "assignedToId" &&
          key !== "hierarchy" &&
          key !== "lat" &&
          key !== "long" &&
          key !== "dueDate" &&
          key !== "status"
        // task[key] !== null
      )
      .forEach((key, index) => {
        let value = task[key];

        if (typeof value === "string" || value instanceof Date) {
          if (moment(value, moment.ISO_8601, true).isValid()) {
            value = moment(value).format("DD-MM-YYYY");
          }
        }

        const displayValue =
          value === null || value === undefined
            ? ""
            : typeof value === "object"
            ? JSON.stringify(value)
            : value.toString() || "";

        const survyField = surveyFormField.find((field) => field.key == key);
        if (survyField) {
          if (survyField.section === "Urban Planning Data") {
            urbanPlanningFields.push({
              label: convertCamelCaseToLabel(key),
              value: key === "id" ? index + 1 : displayValue || "",
            });
          } else if (survyField.section === "Estate Management Data") {
            estateManagementFields.push({
              label: convertCamelCaseToLabel(key),
              value: key === "id" ? index + 1 : displayValue,
            });
          } else if (survyField.section === "Building Control Section") {
            buildingControlFields.push({
              label: convertCamelCaseToLabel(key),
              value: key === "id" ? index + 1 : displayValue,
            });
          } else if (survyField.section === "Revenue Data (PKR)") {
            revenueDataFields.push({
              label: convertCamelCaseToLabel(key),
              value: key === "id" ? index + 1 : displayValue,
            });
          }
        } else {
          fields.push({
            label: convertCamelCaseToLabel(key),
            value: key === "id" ? index + 1 : displayValue,
          });
        }
      });

    const printDate = moment().format("MM/DD/YYYY"); // Get the current date

    // Generate the PDF and open print dialog
    const doc = (
      <MyDocument
        fields={fields}
        urbanPlanningFields={urbanPlanningFields}
        estateManagementFields={estateManagementFields}
        buildingControlFields={buildingControlFields}
        revenueDataFields={revenueDataFields}
        printUser={user?.name}
        printDate={printDate}
        attachments={attachments}
      />
    );
    const blob = await pdf(doc).toBlob();
    const url = URL.createObjectURL(blob);

    // Create an iframe to hold the document and open the print dialog
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();

    setIsPrintModalOpen(false);
    setRecordToPrint(null);
  };

  const handleCancelPrint = () => {
    setIsPrintModalOpen(false);
    setRecordToPrint(null);
  };

  const handleFinalDisposal = (id: string) => {
    console.log("asdasdsadsadsadsa", id);
    setRecordToDispose(id);
    setIsDisposalModalOpen(true);
  };

  const handleConfirmDisposal = async () => {
    if (recordToDispose) {
      console.log("asldmadsas", recordToDispose);
      const result = await finalDisposal(recordToDispose).unwrap();
      notification.success({
        message: "Final Disposal successful:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
      // Implement final disposal logic here
      console.log("Final Disposal action for record with ID:", recordToDispose);
    }
    setIsDisposalModalOpen(false);
    setRecordToDispose(null);
  };

  const handleCancelDisposal = () => {
    setIsDisposalModalOpen(false);
    setRecordToDispose(null);
  };

  // const handleChange = (value: string, record: Task) => {
  //   console.log(`Status changed to ${value} for record with id ${record.id}`);
  // };

  const columns = [
    {
      title: "Property No.",
      dataIndex: "propertyNo",  
      key: "propertyNo",
      className: styles.tableCell,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",   
      className: styles.tableCell,
    },
    {
      title: "Sub Zone",
      dataIndex: "subZone",  
      key: "subZone",
      className: styles.tableCell,
    },
    {
      title: "Area",
      dataIndex: "area",  
      key: "area",
      className: styles.tableCell,
    }, 
    {
      title: "Sub Area",
      dataIndex: "subArea",
      key: "subArea",   
      className: styles.tableCell,
    }, 
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",   
      className: styles.tableCell,
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",  
      className: styles.tableCell,
      render: (dueDate: string) => moment(dueDate).format("DD-MM-YYYY"),
    },
    // Uncommented status column
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text: any, record: Task) => (
    //     <Select
    //       defaultValue={record.status} // assuming status is part of the Task
    //       onChange={(value) => handleChange(value, record)}
    //       className={`${styles.customSelect} w-[70%]`} // Apply the custom class here
    //     >
    //       <Option value="new">New</Option>
    //       <Option value="inProgress">In Progress</Option>
    //       <Option value="pending">Pending</Option>
    //       <Option value="done">Done</Option>
    //     </Select>
    //   ),
    // },
    {
      title: "Actions",
      align: 'center',
      key: "actions",
      render: (text: any, record: Task) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_TASK) &&
            viewComp &&
            viewComp(record.id)}
          {hasPermission(permissions.EDIT_TASK) &&
            proceedComp &&
            proceedComp(record.id, record.finalDisposal)}

          {/* Print Button */}
          <Button
            icon={<PrinterOutlined />}
            onClick={() => handlePrint(record.id)}
            className="flex text-xs md:text-xs lg:text-base items-center"
          >
            Print
          </Button>

          {/* Final Disposal Button */}
          <Button
            type="primary"
            onClick={() =>  handleFinalDisposal(record.id)}
            disabled={record.finalDisposal === 1 ? true : false }
            className="flex text-xs md:text-xs lg:text-base items-center"
          >
            Disposal
          </Button>
        </div>
      ),
      className: `${styles.tableCell}`,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={tasks}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
        rowClassName={(record: Task) =>
          record.finalDisposal === 1 && styles.grayRow 
        }
        
      />

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>

      {/* Print Confirmation Modal */}
      <Modal
        title="Confirm Print"
        open={isPrintModalOpen}
        onOk={handleConfirmPrint}
        onCancel={handleCancelPrint}
        okText="Print"
        cancelText="Cancel"
      >
        <p>Are you sure you want to print this record?</p>
      </Modal>

      {/* Disposal Confirmation Modal */}
      <Modal
        title="Confirm Disposal"
        open={isDisposalModalOpen}
        onOk={handleConfirmDisposal}
        onCancel={handleCancelDisposal}
        okText="Dispose"
        cancelText="Cancel"
      >
        <p>Are you sure you want to dispose of this record?</p>
      </Modal>
    </div>
  );
};

export default TaskList;
