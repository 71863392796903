import React, { useEffect } from "react";
import { Form, Input, notification, Select } from "antd";
import {
  useEditAreaMutation,
  useGetAreaByIdQuery,
  useGetPermissionsQuery,
  useGetSubZoneDropdownQuery,
  useGetZoneDropdownQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { areaValidator } from "@validators/areaValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface EditAreaProps {
  onClose?: () => void;
  id: string;
}

const EditArea: React.FC<EditAreaProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editArea, { isLoading, error }] = useEditAreaMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: subZoneList,
    error: subZoneError,
    isLoading: subZoneIsLoading,
} = useGetSubZoneDropdownQuery({ });
  const {
    data: areaById,
    error: areaByIdError,
    isLoading: areaByIdIsLoading,
  } = useGetAreaByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
        subZoneId: values.subZoneId,
        id,
      }
      const result = await editArea({ id, body: payload }).unwrap();
      onClose && onClose();
      openNotificationWithIcon("success", result?.message || "Area updated successfully!");
    } catch (err: any) {
      console.error("Area update failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (areaById) {
      form.setFieldsValue({
        name: areaById?.data.name,
        subZoneId: areaById?.data.subZoneId,
      });
    }

    return () => form.resetFields();
  }, [areaById, form, permissions]);

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || areaByIdError}
      isLoading={isLoading || permissionsIsLoading || areaByIdIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={areaValidator.name}
      >
        <Input maxLength={30} className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" disabled={isLoading}/>
      </Form.Item>
      <Form.Item
        label="Sub Zone"
        name="subZoneId"
        rules={areaValidator.subZone}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select zone "
          loading={subZoneIsLoading}
          disabled={subZoneIsLoading || isLoading}
          className="rounded-md"
        >
          {subZoneList?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditArea;