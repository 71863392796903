import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  DatePicker,
  notification,
  Row,
  Col,
  InputNumber,
} from "antd";
import {
  useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
} from "@services/api/index";
import { UploadOutlined } from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
// import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { Task } from "types";
import { taskValidator } from "@validators/taskValidator";
import NotificationHandler from "@components/notificationHandler";
// import MapWithImages from "@components/googleMapWithImages";
// import surveyFormField from "../../Tasks/editTask/surveyFormFileds";

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  file: string;
  lat: any;
  long: any;
}

interface ViewSurvayProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

// const imageMarkers = [
//   {
//     coordinates: { lat: 33.6938, lng: 73.0652 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAQ0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAABW6+5IpH1djoWBgcGoYBAiBiJiQZGBgYmfgYBAEEDK1MfYfOBQAAAAASUVORK5CYII=" // Red Dot
//     ]
//   },
//   {
//     coordinates: { lat: 33.7443, lng: 73.0551 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAP0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAH3q9ShE3GIwQxQ4MAIoUGKEhiYGGoNAAEgbhlM7VVpAAAAAElFTkSuQmCC", // Green Dot
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAPUlEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAHFu9ShE3GIwQxQ4MAIn2NKEIgiYEBoKwAICrBGF0s2FGAAAAAElFTkSuQmCC"  // Blue Dot
//     ]
//   },
//   {
//     coordinates: { lat: 33.7294, lng: 73.0379 },
//     images: [
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAQ0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAABW6+5IpH1djoWBgcGoYBAiBiJiQZGBgYmfgYBAEEDK1MfYfOBQAAAAASUVORK5CYII=", // Red Dot
//       "iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAIAAAD7z3z0AAAAP0lEQVR42mJgYPjfwX0AgnEwMzExwx0Cefz5+cODPzS5GCEIIOgUAAH3q9ShE3GIwQxQ4MAIoUGKEhiYGGoNAAEgbhlM7VVpAAAAAElFTkSuQmCC"  // Green Dot
//     ]
//   }
// ];

const EditSurvey: React.FC<ViewSurvayProps> = ({ onClose, id, isLoaded }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editTask, { isLoading, error }] = useEditTaskMutation();
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const {
    data: inspectors,
    error: inspectorsError,
    isLoading: inspectorsIsLoading,
  } = useGetInspectorsQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      form.setFieldsValue({
        assignedToId: task.assignedToId,
        dueDate: moment(task.dueDate),
        propertyNo: task.propertyNo,
        propertyName: task.propertyName,
        streetName: task.streetName,
        ownerName: task.ownerName,
        phoneNo: task.phoneNo,
        plotLength: task.plotLength,
        plotWidth: task.plotWidth,
        buildingHeight: task.buildingHeight,
        floorAreaSft: task.floorAreaSft,
        floorAreaSqy: task.floorAreaSqy,
        noOfStories: task.noOfStories,
        landUseConversionAndRegularizationCharges:
          task.landUseConversionAndRegularizationCharges,
        inspectorRemarks: task.inspectorRemarks,
        tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
          task.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges,
      });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
    }
  }, [taskDataById, form]);

  const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
    setPolygonData(newPolygons ? newPolygons : polygonData);
  };

  const handleUploadChange = (info: any) => {
    setFileList(info.fileList);
  };

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onFinish = async (values: any) => {
    try {
      const attachments: Attachment[] = await Promise.all(
        fileList.map(async (file) => {
          const base64File = await convertFileToBase64(file.originFileObj);
          return {
            file: base64File,
            lat: undefined,
            long: undefined,
          };
        })
      );

      const payLoad = {
        assignedToId: values.assignedToId,
        dueDate: values.dueDate.format("YYYY-MM-DD"),
        buildingCoordinates: polygonData || [],
        centerOfPolygon:
          polygonData && polygonData.length > 0
            ? polygonData[0]
            : { lat: 0, lng: 0 },
        propertyNo: values.propertyNo || undefined,
        propertyName: values.propertyName || undefined,
        streetName: values.streetName || undefined,
        ownerName: values.ownerName || undefined,
        phoneNo: values.phoneNo || undefined,
        plotLength: values.plotLength
          ? values.plotLength.toString()
          : undefined,
        plotWidth: values.plotWidth ? values.plotWidth.toString() : undefined,
        buildingHeight: values.buildingHeight
          ? values.buildingHeight.toString()
          : undefined,
        floorAreaSft: values.floorAreaSft
          ? values.floorAreaSft.toString()
          : undefined,
        floorAreaSqy: values.floorAreaSqy
          ? values.floorAreaSqy.toString()
          : undefined,
        noOfStories: values.noOfStories
          ? values.noOfStories.toString()
          : undefined,
        landUseConversionAndRegularizationCharges:
          values.landUseConversionAndRegularizationCharges || undefined,
        inspectorRemarks: values.inspectorRemarks || undefined,
        tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
          values.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges ||
          undefined,
        attachments: attachments ? attachments : undefined,

        // New keys
        actualUsageId: values.actualUsageId || undefined,
        currentUsageId: values.currentUsageId || undefined,
        areaId: values.areaId || undefined,
        violationfeet: values.violationfeet || undefined,
        violationApprovedCoveredArea:
          values.violationApprovedCoveredArea || undefined,
        violationExisitingCoveredArea:
          values.violationExisitingCoveredArea || undefined,
        violationAccessArea: values.violationAccessArea || undefined,
        violationApprovedNumberOfFloor:
          values.violationApprovedNumberOfFloor || undefined,
        violationBuiltNumberOfFloors:
          values.violationBuiltNumberOfFloors || undefined,
        fileNoEM: values.fileNoEM || undefined,
        plotNo: values.plotNo || undefined,
        streetRoad: values.streetRoad || undefined,
        subSector: values.subSector || undefined,
        sectorScheme: values.sectorScheme || undefined,
        cornerStatus: values.cornerStatus || undefined,
        classificationOfPlot: values.classificationOfPlot || undefined,
        size: values.size || undefined,
        dimension: values.dimension || undefined,
        subDivision: values.subDivision || undefined,
        amalgamation: values.amalgamation || undefined,
        certificateOfCompletion: values.certificateOfCompletion || undefined,
        constructionStatus: values.constructionStatus || undefined,
        typeOfAllotment: values.typeOfAllotment || undefined,
        dateOfFirstAllotment: values.dateOfFirstAllotment || undefined,
        firstAllotteeName: values.firstAllotteeName || undefined,
        firstAllotteeCnic: values.firstAllotteeCnic || undefined,
        dateOfLastTransfer: values.dateOfLastTransfer || undefined,
        presentOwnerCnic: values.presentOwnerCnic || undefined,
        presentOwnerName: values.presentOwnerName || undefined,
        mortgageStatus: values.mortgageStatus || undefined,
        bankName: values.bankName || undefined,
        leaseStatus: values.leaseStatus || undefined,
        leaseEndingDate: values.leaseEndingDate || undefined,
        powerOfAttorney: values.powerOfAttorney || undefined,
        conveyanceDeedStatus: values.conveyanceDeedStatus || undefined,
        saleDeedStatus: values.saleDeedStatus || undefined,
        litigationStatus: values.litigationStatus || undefined,
        otherInformation: values.otherInformation || undefined,
        buildingPlanApprovalDate: values.buildingPlanApprovalDate || undefined,
        approvedCoveredAreaSft: values.approvedCoveredAreaSft || undefined,
        noOfStoreys: values.noOfStoreys || undefined,
        noOfUnits: values.noOfUnits || undefined,
        completionCertificateDate:
          values.completionCertificateDate || undefined,
        completionCoveredAreaSft: values.completionCoveredAreaSft || undefined,
        conditionOfBuilding: values.conditionOfBuilding || undefined,
        propertyTaxAnnual: values.propertyTaxAnnual || undefined,
        propertyTaxPaidUp: values.propertyTaxPaidUp || undefined,
        waterCharges: values.waterCharges || undefined,
        conservancyCharges: values.conservancyCharges || undefined,
        quarterlyDemandAB: values.quarterlyDemandAB || undefined,
        arrearsPKRA: values.arrearsPKRA || undefined,
        paymentsAIB: values.paymentsAIB || undefined,
        propertyTypeId: values.propertyTypeId || undefined,
        violationId: values.violationId || undefined,
        status: values.status || undefined,
        lat: values.lat || undefined,
        long: values.long || undefined,
      };


      await editTask({ id, body: payLoad }).unwrap();
      openNotificationWithIcon("success", "Survey updated successfully!");

      onClose && onClose();
    } catch (err:any) {
      console.error("Survey update failed:", err);
        // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  // const center = polygonData && polygonData.length > 0 ? polygonData[0] : {
  //   lat: 33.6938,
  //   lng: 73.0652,
  // };

  //   const center = {
  //     lat: 33.6938,
  //     lng: 73.0652,
  //   };

  return (
    <Row gutter={[16, 16]} className="h-full">
      <Col xs={24} sm={24} md={18} lg={16} xl={16}>
        {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
        {/* <MapWithImages
  center={center} 
  imageMarkers={imageMarkers}
  isLoaded={true} 
/> */}
      </Col>
      <Col xs={24} sm={24} md={6} lg={8} xl={8}>
        <AppFormWrapper
          error={error}
          initialValues={{ remember: true }}
          isLoading={isLoading}
          onClose={onClose}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          type="Proceed"
          form={form}
        >
          {/* Existing Form Fields */}
          <Form.Item label="Property No." name="propertyNo">
            <Input className="rounded-md p-1 w-full" />
          </Form.Item>

          <h1>{"Urban Planning Data"}</h1>
          {/* {surveyFormField
            .filter((val) => val.section == "Urban Planning Data")
            .map((val) => (
              <Form.Item label={val.fieldName} name={val.key}>
                <Input className="rounded-md p-1 w-full" />
              </Form.Item>
            ))} */}
        </AppFormWrapper>
      </Col>
    </Row>
  );
};

export default EditSurvey;
