import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import ChallanList from "@components/Challan/challanList";
import ViewChallan from "@components/Challan/viewChallan";

const ChallanManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_CHALLAN_MANAGEMENT) ? (
    <AppPageWrapper
      listComp={<ChallanList />}
      viewComp={(id: string) => <ViewChallan id={id} />}
      moduleName="Challan"
      pagetTitle="Challan Management"
      width={"600px"}
    />
  ) : null;
};

export default ChallanManagement;
