import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  SubArea,
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const subAreaApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getSubArea: builder.query<ResponseListPaginate<SubArea>, PaginationPayload>({
    query: ({ page, limit, searchText }: any) => {
      const params: any = { page, limit };
      if (searchText) {
        params.searchText = searchText;
      }
      return {
        url: apiRoutes.getSubArea,
        params,
      };
    },
    providesTags: [apiTags.subArea],
  }),
  createSubArea: builder.mutation<Response<SubArea>, SubArea>({
    query: (body) => ({
      url: apiRoutes.createSubArea,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.subArea],
  }),
  editSubArea: builder.mutation<
    Response<SubArea>,
    { id: string; body: SubArea }
  >({
    query: ({ id, body }) => ({
      url: apiRoutes.editSubArea(id),
      method: "PUT",
      body,
    }),
    invalidatesTags: [apiTags.subArea],
  }),

  getSubAreaById: builder.query<Response<SubArea>, string>({
    query: (id: string) => apiRoutes.getSubAreaById(id),
    providesTags: [apiTags.subArea],
  }),
});
