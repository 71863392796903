import {
    BaseQueryFn,
    EndpointBuilder,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
    PaginationPayload,
    ResponseListPaginate,
    Challan,
    ChallanStatusBody,
    Response,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const challanApi = (
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
        string,
        "api"
    >
) => ({
    getChallan: builder.query<ResponseListPaginate<Challan>, PaginationPayload>({
        query: ({ page, limit }: any) => ({
            url: apiRoutes.getChallan,
            params: { page, limit },
        }),
        providesTags: [apiTags.challan],
    }),
    getChallanById: builder.query<Response<Challan>, number>({
        query: (id: number) => apiRoutes.getChallanById(id),
        providesTags: [apiTags.challan],
    }),
    changeStatusChallan: builder.mutation({
        query: (body: ChallanStatusBody) => ({
            url: apiRoutes.changeStatusChallan(body.id),
            method: "PATCH",
        }),
        invalidatesTags: [apiTags.challan],
    }),
    changeApprovedStatusChallan: builder.mutation({
        query: (body: ChallanStatusBody) => ({
            url: apiRoutes.changeApprovedStatusChallan(body.id),
            method: "PATCH",
            body
        }),
        invalidatesTags: [apiTags.challan],
    }),
    assignToSupervisorChallan: builder.mutation({
        query: (id: number) => ({
            url: apiRoutes.assignToSupervisorChallan(id),
            method: "PATCH",
        }),
        invalidatesTags: [apiTags.challan],
    }),
});
