
import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '@constants/index';
import { useGetDashboardQuery } from '@services/api';
import inspectorIcon from "../../assets/img/inspector-icon-3.png"
import styles from "./googleMap.module.css"

interface GoogleMapCompProps {
  isLoaded?: boolean
}

const GoogleMapComp: React.FC<GoogleMapCompProps> = ({ isLoaded }) => {
  const { data } = useGetDashboardQuery();
  const islamabadCenter = { lat: 33.693849, lng: 73.065142, };
  const [center, setCenter] = useState(islamabadCenter);
  const mapRef = useRef<google.maps.Map | null>(null);
  const addMarkers = (map: google.maps.Map) => {
    if (data && data.data) {
      if (data?.data?.inspectorLocationsSociety && data?.data?.inspectorLocations) {
        ([...data?.data?.inspectorLocationsSociety, ...data?.data?.inspectorLocations]).map((inspector: any) => {

          if (inspector?.lat && inspector?.long) {
            const latLng = new google.maps.LatLng(parseFloat(inspector.lat), parseFloat(inspector.long));

            const marker = new google.maps.Marker({
              position: latLng,
              map: map,
              title: inspector.name,
              icon: {
                url: inspectorIcon,
                scaledSize: new google.maps.Size(40, 40),
              },
            });
            //><br>Latitude: ${inspector.lat}<br>Longitude: ${inspector.long}
            const infoWindow = new google.maps.InfoWindow({
              content: `<div><strong>Name: ${inspector.name}</strong</div>`
            });

            marker.addListener('mouseover', () => {
              infoWindow.open(map, marker);
            });
            marker.addListener('mouseout', () => {
              infoWindow.close();
            });
          }

        })
      } else
        (data?.data?.inspectorLocationsSociety || data?.data?.inspectorLocations).map((inspector: any) => {

          if (inspector?.lat && inspector?.long) {
            const latLng = new google.maps.LatLng(parseFloat(inspector.lat), parseFloat(inspector.long));

            const marker = new google.maps.Marker({
              position: latLng,
              map: map,
              title: inspector.name,
              icon: {
                url: inspectorIcon,
                scaledSize: new google.maps.Size(40, 40),
              },
            });
            //><br>Latitude: ${inspector.lat}<br>Longitude: ${inspector.long}
            const infoWindow = new google.maps.InfoWindow({
              content: `<div><strong>Name: ${inspector.name}</strong</div>`
            });

            marker.addListener('mouseover', () => {
              infoWindow.open(map, marker);
            });
            marker.addListener('mouseout', () => {
              infoWindow.close();
            });
          }

        })




    }
  };
  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
      if (data) {
        addMarkers(map);
      }
    },
    [addMarkers, data]
  );

  useEffect(() => {
    if (mapRef.current && data) {
      addMarkers(mapRef.current);
    }
  }, [data, addMarkers]);

  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current = null;
      }
    };
  }, []);

  return isLoaded ? (
    <div className="w-full h-[350px] md:h-[85vh]">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}

        zoom={11.5}
        onLoad={onMapLoad}
      >
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default GoogleMapComp;
