import React, { ReactNode, useEffect } from "react";
import { Button, Alert, Form, FormInstance, notification } from "antd";
import NotificationHandler from "@components/notificationHandler";

interface AppFormWrapperProps {
  onClose?: () => void;
  error: any;
  isLoading: boolean;
  children?: ReactNode;
  onFinish?: (val: any) => void;
  onFinishFailed?: (val: any) => void;
  initialValues?: object;
  type?: string;
  form?: FormInstance<any>;
}

const AppFormWrapper: React.FC<AppFormWrapperProps> = ({
  onClose,
  error,
  isLoading,
  children,
  onFinish,
  onFinishFailed,
  initialValues,
  type,
  form,
}) => {
  const { openNotificationWithIcon } = NotificationHandler();
  useEffect(() => {
    if (error?.data?.message && error) {
      openNotificationWithIcon("error", error?.data?.message)
    }
  }, [error])

  return (
    <div className="max-w-full -mx-4 px-4 mb-20 bg-white rounded-lg overflow-y-auto ">
      <Form
        form={form}
        name="add role"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="space-y-4"
      >
        {children}
        {error?.data?.message && error && (
          <Alert message={error?.data?.message} type="error" className="mb-4" />
        )}
        <div className="absolute bottom-0 left-0 w-full border-t border-gray-400 flex justify-end gap-6 pt-6 pr-4 pb-4 bg-white">
          {type &&  (
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="md:py-4 py-2 px-8 text-sm lg:text-base bg-[#2D796D] font-semibold rounded-md"
            >
              {type}
            </Button>
          )}
          <Button
            onClick={onClose}
            className="py-4 px-8 text-sm lg:text-base font-semibold rounded-md border border-gray-300 hover:bg-gray-100"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AppFormWrapper;
