import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import SocietyManagementList from "@components/Society/societyList";
import AddSociety from "@components/Society/addSociety";
import EditSociety from "@components/Society/editSociety";
import ViewSociety from "@components/Society/viewSociety";

const SocietyManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_SOCIETY_MANAGEMENT) ? (
    <AppPageWrapper
      addComp={() =>
        hasPermission(permissions.ADD_SOCIETY) ? <AddSociety /> : null
      }
      listComp={<SocietyManagementList />}
      editComp={(id: string) =>
        hasPermission(permissions.EDIT_SOCIETY) ? <EditSociety id={id} /> : null
      }
      viewComp={(id: string) =>
        hasPermission(permissions.VIEW_SOCIETY) ? <ViewSociety id={id} /> : null
      }
      moduleName="Society"
      pagetTitle="Society Management"
    />
  ) : null;
};

export default SocietyManagement;
