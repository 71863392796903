import React from "react";
import { RouteObject } from "react-router-dom";
import societySurveyPaths from "@paths/societySurveyPaths";
import SocietySurvey from "@pages/societySurvey";

const societySurveyMapping: RouteObject[] = [
  {
    path: societySurveyPaths.root,
    element: <SocietySurvey />,
  },
];

export default societySurveyMapping;
