import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  SubZone,
  Zone,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const subZoneApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getSubZone: builder.query<ResponseListPaginate<SubZone>, PaginationPayload>({
    query: ({ page, limit }: any) => ({
      url: apiRoutes.getSubZone,
      params: { page, limit },
    }),
    providesTags: [apiTags.zone],
  }),
  //   createZone: builder.mutation<Response<Zone>, Zone>({
  //     query: (body) => ({
  //       url: apiRoutes.createZone,
  //       method: "POST",
  //       body,
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  getSubZoneDropdown: builder.query<ResponseList<Zone>, { zoneId?: string }>({
    query: ({ zoneId }) => ({
      url: apiRoutes.getSubZoneDropdown(zoneId ? `?zoneId=${zoneId}` : ''),
      method: "GET",
    }),
  }),
  //   editZone: builder.mutation<Response<Zone>, { id: string; body: Zone }>({
  //     query: ({ id, body }) => ({
  //       url: apiRoutes.editZone(id),
  //       method: "POST",
  //       body,
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  //   deleteZone: builder.mutation({
  //     query: (id: string) => ({
  //       url: apiRoutes.deleteZone(id),
  //       method: "DELETE",
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  //   changeStatusZone: builder.mutation({
  //     query: (id: string) => ({
  //       url: apiRoutes.changeStatusZone(id),
  //       method: "POST",
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  getSubZoneById: builder.query<Response<SubZone>, string>({
    query: (id: string) => apiRoutes.getSubZoneById(id),
  }),
});
