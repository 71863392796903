import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import AddArea from "@components/Area/addArea";
import EditArea from "@components/Area/editArea";
import ViewArea from "@components/Area/viewArea";
import AreaList from "@components/Area/areaList";

const AreaManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_AREA_MANAGEMENT) ? (
    <AppPageWrapper
      addComp={() => hasPermission(permissions.ADD_AREA) ? <AddArea /> : null}
      listComp={<AreaList />}
      editComp={(id: string) => <EditArea id={id} />}
      viewComp={(id: string) => <ViewArea id={id} />}
      moduleName="Area"
      pagetTitle="Area Management"
      width={"600px"}
    />
  ) : null;
};

export default AreaManagement;
