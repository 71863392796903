import React from "react";
import { RouteObject } from "react-router-dom";
import TaskManagement from "@pages/taskManagement";
import taskManagementPaths from "@paths/taskManagement";

const taskManagementMappings: RouteObject[] = [
  {
    path: taskManagementPaths.root,
    element: <TaskManagement />,
  },
];

export default taskManagementMappings;
