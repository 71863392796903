import React from 'react';
import { RouteObject } from 'react-router-dom';
import Login from '@pages/login';
import authPaths from '@paths/authPaths';
import ForgotPassword from '@pages/forgotPassword';
import ChangePassword from '@pages/changePassword';

const authMappings: RouteObject[] = [
    {
        path: authPaths.login(),
        element: <Login />,
    },
    {
        path: authPaths.forgotPassword(),
        element: <ForgotPassword />,
    },
    {
        path: authPaths.changePassword(),
        element: <ChangePassword />,
    },
];

export default authMappings;
