import React, { useEffect } from "react";
import { Form, Input } from "antd";
import {
  useGetSocietyByIdQuery,
  useEditSocietyMutation,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import { societyValidator } from "@validators/societyValidator";
import NotificationHandler from "@components/notificationHandler";

interface EditSocietyProps {
  onClose?: () => void;
  id: string;
}

const EditSociety: React.FC<EditSocietyProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editSociety, { isLoading, error }] = useEditSocietyMutation();

  const {
    data: societyById,
    error: societyByIdError,
    isLoading: societyByIdIsLoading,
  } = useGetSocietyByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
      };
      const result = await editSociety({ id, body: payload }).unwrap();
      openNotificationWithIcon(
        "success",
        result?.message || "Society updated successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      console.error("Society update failed:", err);
    }
  };

  const [form] = Form.useForm();
  useEffect(() => {
    if (societyById) {
      form.setFieldsValue({
        name: societyById?.data.name,
      });
    }

    return () => form.resetFields();
  }, [societyById, form]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AppFormWrapper
      form={form}
      error={error}
      initialValues={{ remember: true }}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item label="Society Name" name="name" rules={societyValidator.name}>
        <Input maxLength={30} className="rounded-md p-1" disabled={isLoading} />
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditSociety;
