import React from "react";
import propertyTypeManagementPaths from "@paths/propertyType";
import PropertyType from "@pages/propertyTypeManagement";
import { RouteObject } from "react-router-dom";
import AddProperty from "@components/Property/addProperty";

const propertyTypeManagementMappings: RouteObject[] = [
  {
    path: propertyTypeManagementPaths.root,
    element: <PropertyType />,
  },
  {
    path: propertyTypeManagementPaths.addProperty,
    element: <AddProperty />,
  },
];

export default propertyTypeManagementMappings;
