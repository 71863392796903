// src/components/PrivateRoute.tsx
import { setCredentials } from '../../redux/authSlice'; // Make sure the path is correct
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  component: React.ElementType;
  [key: string]: any; // Allow for additional props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem('token') || ''; // Provide a default value
  const user = localStorage.getItem('user') || '0'; // Provide a default value
  const refreshToken = localStorage.getItem('refresh-token') || '';

  dispatch(setCredentials({ token, user: JSON.parse(user), refreshToken }));

  return token ? <Component {...rest} /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
