import React from "react";
import { Form, Input, Select } from "antd";
import { useCreateSocietyMutation } from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import { societyValidator } from "@validators/societyValidator";
import NotificationHandler from "@components/notificationHandler";

interface AddSocietyProps {
  onClose?: () => void;
}

const AddSociety: React.FC<AddSocietyProps> = ({ onClose }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [createSociety, { isLoading, error }] = useCreateSocietyMutation();

  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
      };
      const result = await createSociety(payload).unwrap();
      openNotificationWithIcon(
        "success",
        result?.message || "Society created successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      console.error("Society creation failed:", err);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AppFormWrapper
      error={error}
      initialValues={{ remember: true }}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item label="Society Name" name="name" rules={societyValidator.name}>
        <Input
          maxLength={30}
          className="rounded-md p-1"
          placeholder="Enter name"
          disabled={isLoading}
        />
      </Form.Item>
    </AppFormWrapper>
  );
};

export default AddSociety;
