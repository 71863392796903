import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import PropertyTypeList from "@components/Property/propertyTypeList";
import EditPropertyType from "@components/Property/editPropertyType";
import ViewPropertyType from "@components/Property/viewPropertyType";
import { permissions } from "permissions";
// import AddProperty from "@components/Property/addProperty";

const PropertyType: React.FC = () => {
    const { hasPermission } = usePermissions();
    return hasPermission(permissions.VIEW_PROPERTY_MANAGEMENT) ? (
        <AppPageWrapper
            // addComp={
            //     () => hasPermission(permissions.ADD_PROPERTY_TYPE) ? <AddProperty /> : null
            // }
            listComp={<PropertyTypeList />}
            editComp={(id: string) => <EditPropertyType id={id} />}
            viewComp={(id: string) => <ViewPropertyType id={id} />}
            moduleName="PropertyType"
            pagetTitle="Property Type Management"
            width={"600px"}
        />
    ) : null;
};

export default PropertyType;
