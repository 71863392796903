import { Rule } from "antd/es/form";

interface SocietyValidator {
    name: Rule[];
}

export const societyValidator: SocietyValidator = {
    name: [
    { required: true, message: "Please input Society name" },
  ],
};
