import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
  Role,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";
import { AllByRole } from "@testing-library/react";

export const roleApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getRole: builder.query<ResponseListPaginate<Role>, PaginationPayload>({
    query: ({ page, limit }: any) => ({
      url: apiRoutes.getRole,
      params: { page, limit },
    }),
    providesTags: [apiTags.role],
  }),
  createRole: builder.mutation<Response<Role>, Role>({
    query: (body) => ({
      url: apiRoutes.createRole,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.role],
  }),
  getRoleDropdown: builder.query<ResponseList<Role>, { hierarchy?: string }>({
    query: ({ hierarchy }) => ({
      url: apiRoutes.getRoleDropdown(
        hierarchy ? `?hierarchy=${hierarchy}` : ""
      ),
      method: "GET",
    }),
  }),
  getRoleById: builder.query<Response<Role>, string>({
    query: (id: string) => apiRoutes.getRoleById(id),
    providesTags: [apiTags.role],
  }),
  editRole: builder.mutation<Response<Role>, { id: string; body: Role }>({
    query: ({ id, body }) => ({
      url: apiRoutes.editRole(id),
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.role],
  }),
  deleteRole: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.deleteRole(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.role],
  }),
  changeStatusRole: builder.mutation({
    query: (id: string) => ({
      url: apiRoutes.changeStatusRole(id),
      method: "POST",
    }),
    invalidatesTags: [apiTags.role],
  }),
});
