import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  Area,
  PaginationPayload,
  Response,
  ResponseList,
  ResponseListPaginate,
} from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const areaApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  // getArea: builder.query<ResponseListPaginate<Area>, PaginationPayload>({
  //   query: ({ page, limit,searchText }: any) => ({
  //     url: apiRoutes.getArea,
  //     params: { page, limit ,searchText},
  //   }),
  //   providesTags: [apiTags.area,apiTags.subArea],
  // }),
  getArea: builder.query<ResponseListPaginate<Area>, PaginationPayload>({
    query: ({ page, limit, searchText }: any) => {
      const params: any = { page, limit };
      if (searchText) {
        params.searchText = searchText;
      }
      return {
        url: apiRoutes.getArea,
        params,
      };
    },
    providesTags: [apiTags.area, apiTags.subArea],
  }),
  createArea: builder.mutation<Response<Area>, Area>({
    query: (body) => ({
      url: apiRoutes.createArea,
      method: "POST",
      body,
    }),
    invalidatesTags: [apiTags.area],
  }),
  editArea: builder.mutation<Response<Area>, { id: string; body: Area }>({
    query: ({ id, body }) => ({
      url: apiRoutes.editArea(id),
      method: "PUT",
      body,
    }),
    invalidatesTags: [apiTags.area],
  }),
  //   deleteZone: builder.mutation({
  //     query: (id: string) => ({
  //       url: apiRoutes.deleteZone(id),
  //       method: "DELETE",
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  //   changeStatusZone: builder.mutation({
  //     query: (id: string) => ({
  //       url: apiRoutes.changeStatusZone(id),
  //       method: "POST",
  //     }),
  //     invalidatesTags: [apiTags.zone],
  //   }),
  getAreaById: builder.query<Response<Area>, string>({
    query: (id: string) => apiRoutes.getAreaById(id),
    providesTags: [apiTags.area, apiTags.subArea],
  }),
  getAreaDropdown: builder.query<ResponseList<Area>, { subZoneId?: string }>({
    query: ({ subZoneId }) => ({
      url: apiRoutes.getAreaDropdown(
        subZoneId ? `?subZoneId=${subZoneId}` : ""
      ),
      method: "GET",
    }),
    providesTags: [apiTags.area],
  }),

  getSubAreaDropdown: builder.query<ResponseList<Area>, { areaId?: string }>({
    query: ({ areaId }) => ({
      url: apiRoutes.getSubAreaDropdown(areaId ? `?areaIds=${areaId}` : ""),
      method: "GET",
    }),
    providesTags: [apiTags.area],
  }),
});
