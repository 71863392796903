import React from "react";
import { Input, Skeleton } from "antd";
import { useGetSocietyByIdQuery } from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
interface ViewSocietyProps {
  onClose?: () => void;
  id: string;
}

const ViewSociety: React.FC<ViewSocietyProps> = ({ onClose, id }) => {
  const {
    data: societyById,
    error: societyByIdError,
    isLoading: societyByIdIsLoading,
  } = useGetSocietyByIdQuery(id);

  return (
    <>
      {societyByIdIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            isLoading={societyByIdIsLoading}
            error={societyByIdError}
          >
            <h1>Society Name</h1>
            <Input
              value={societyById?.data.name}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </AppFormWrapper>
        </>
      )}
    </>
  );
};

export default ViewSociety;
