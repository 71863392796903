import React, { useEffect } from "react";
import { Form, Input, notification, Select } from "antd";
import {
  useEditAreaMutation,
  useEditSubAreaMutation,
  useGetAreaDropdownQuery,
  useGetPermissionsQuery,
  useGetSubAreaByIdQuery,
  useGetZoneDropdownQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { subAreaValidator } from "@validators/subAreaValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface EditSubAreaProps {
  onClose?: () => void;
  id: string;
}

const EditSubArea: React.FC<EditSubAreaProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editSubArea, { isLoading, error }] = useEditSubAreaMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: zoneList,
    error: zoneListError,
    isLoading: zoneListIsLoading,
  } = useGetZoneDropdownQuery();
  const {
    data: area,
    error: areaError,
    isLoading: areaIsLoading,
    refetch: refetchAreas,
  } = useGetAreaDropdownQuery({});
  const {
    data: subAreasById,
    error: subAreasByIdError,
    isLoading: subAreasByIdIsLoading,
    refetch: refetchSubAreas,
  } = useGetSubAreaByIdQuery(id);
  console.log(subAreasById, "area")
  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
        areaId: values.areaId,
        id,
      }
      const result = await editSubArea({ id, body: payload }).unwrap();
      onClose && onClose();
      openNotificationWithIcon("success", result?.message || "Area updated successfully!");
    } catch (err: any) {
      console.error("Area update failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
console.log(subAreasById?.data?.areaId)
    if (subAreasById) {
      form.setFieldsValue({
        name: subAreasById?.data.name,
        areaId: subAreasById?.data.areaId,
      });
    }

    return () => form.resetFields();
  }, [subAreasById, form, permissions]);

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || areaError}
      isLoading={isLoading || permissionsIsLoading || areaIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={subAreaValidator.name}
      >
        <Input maxLength={30} className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" />
      </Form.Item>
      <Form.Item
        label="Area"
        name="areaId"
        rules={subAreaValidator.area}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select Area"
          loading={areaIsLoading}
          disabled={areaIsLoading}
          className="rounded-md"
        >
          {area?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditSubArea;