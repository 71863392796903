import React from "react";
import AddZone from "@components/Zones/addZone";
import AppPageWrapper from "@components/appPageWrapper";
import ZoneList from "@components/Zones/zoneList/index";
import EditZone from "@components/Zones/editZone";

import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import ViewZone from "@components/Zones/viewZone";
const ZoneManagement: React.FC = () => {
  const { hasPermission } = usePermissions();
  return hasPermission(permissions.VIEW_ZONE_MANAGEMENT) ? (
    <AppPageWrapper
      // addComp={hasPermission(permissions.ADD_ZONE) ? <AddZone /> : null}
      listComp={<ZoneList />}
      editComp={(id: string) => <EditZone id={id} />}
      viewComp={(id: string) => <ViewZone id={id} />}
      moduleName="Zone"
      pagetTitle="Zone Management"
    />
  ) : null;
};

export default ZoneManagement;
