import React, { useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Divider,
  Skeleton,
  notification,
  CheckboxProps,
} from "antd";
import {
  useCreatePropertyMutation,
  useCreateRoleMutation,
  useGetPermissionsQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { propertyValidator } from "@validators/propertyValidator";
import NotificationHandler from "@components/notificationHandler";

const CheckboxGroup = Checkbox.Group;

interface AddPropertyProps {
  onClose?: () => void;
}

const AddProperty: React.FC<AddPropertyProps> = ({ onClose }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [createPropertyType, { isLoading, error }] =
    useCreatePropertyMutation();
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();
  const onFinish = async (values: any) => {
    try {
      let permissionIds: string[] = [];

      for (const value of Object.values(values)) {
        if (value && Array.isArray(value)) {
          permissionIds = permissionIds.concat(value);
        }
      }
      const payload = {
        name: values.name,
        // permissionIds,
      }
      const result = await createPropertyType(payload).unwrap();
      console.log("Property Type created successfully", result);
      openNotificationWithIcon("success",result?.message || "Property Type created successfully!");
      onClose && onClose();
    } catch (err: any) {
      console.error("Property type creation failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [checkedList, setCheckedList] = useState<any[]>([]);

  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  return (
    <AppFormWrapper
      error={error || permissionsError}
      initialValues={{ name: null, permissions: null }}
      isLoading={isLoading || permissionsIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={propertyValidator.name}
      >
        <Input maxLength={30} className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        placeholder="Enter name"
        disabled={isLoading}/>
      </Form.Item>

      {permissionsIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          {/* <Checkbox
                        indeterminate={false}
                        // onChange={onCheckAllChange}
                        defaultChecked={false}
                        className="mb-4"
                    >
                        Check all
                    </Checkbox> */}
          <Divider />
          {/* <div className="flex flex-col space-y-2">
                        {
                            permissions?.data.map((val) => (

                                <Form.Item name={val.module} label={val.module}>
                                    <CheckboxGroup
                                        // options={permissions?.data.map((val) => val.name)}
                                        options={(val.permissions as any)?.map((val: any) => ({
                                            label: val.name,
                                            value: val.id,
                                        }))}
                                        value={(val?.permissions as any)?.map((val: any) => val.id)}
                                        onChange={onChange}
                                    />
                                </Form.Item>

                            ))
                        }

                    </div> */}
        </>
      )}
    </AppFormWrapper>
  );
};

export default AddProperty;
