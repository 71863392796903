import React from "react";
import AppPageWrapper from "@components/appPageWrapper";
import usePermissions from "@hooks/usePermissions";
import ViolationTypeList from "@components/Violation/violationTypeList";
import EditViolationType from "@components/Violation/editViolationType";
import ViewViolationType from "@components/Violation/viewViolationType";
import { permissions } from "permissions";
import AddViolation from "@components/Violation/addViolation";

const ViolationType: React.FC = () => {
  const { hasPermission } = usePermissions();
  return (hasPermission(permissions.VIEW_VIOLATION_MANAGEMENT) ?
    <AppPageWrapper
      // addComp={() => hasPermission(permissions.ADD_VIOLATION_MANAGEMENT) ? <AddViolation /> : null}
      listComp={<ViolationTypeList />}
      editComp={(id: string) => <EditViolationType id={id} />}
      viewComp={(id: string) => <ViewViolationType id={id} />}
      moduleName="ViolationType"
      pagetTitle="Violation Type Management"
    /> : null
  )

};

export default ViolationType;
